.vehicleAddDamageForm_row {
  margin-bottom: 20px;
}

.vehicleAddDamage_button {
  width: 100%;
}

.vehicleAddDamageForm_boxedSelect {
  width: 100%;
}

.vehicleAddDamageForm_form {
  margin-bottom: 20px;
}

.addDamageForm_Picture {
  width: 80%;
  height: 80px;
  display: inline-block;
  text-align: right;
  float: right;
}

.addDamageForm_Picture img {
  max-height: 100%;
  max-width: 100%;
}
