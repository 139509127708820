.mapbox-complete {
  $color-border: $color-primary2;
  $color-focus-svg: $color-primary-middle2;
  $color-svg: $color-primary2-alt2;
  $color-cursor: $color-primary-light2;
  $color-info: $color-primary;
  $color-shadow: $color-border-shadow9;
  $text-color: $color-text-dark;
  $color-bg: $color-white;

  $closeIconMarginRight: 10px;
  $closeIconSize: 10px;
  $closeIconInnerMargin: 5px;

  $input-left-padding: 16px;
  $input-right-padding: $closeIconInnerMargin * 2 + $closeIconSize + $closeIconMarginRight;

  .mapbox-main {
    width: 100%;
  }

  .mc-suggestions {
    overflow-y: auto;
    max-height: 200px;
  }

  .mc-dataset:not(:last-child) {
    margin-bottom: 0.5em;
  }

  .mc-input {
    padding-left: $input-left-padding;
    padding-right: $input-right-padding;
  }

  .close-icon {
    visibility: hidden;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-right: $closeIconMarginRight;
    align-self: center;
  }

  .close-icon.visible {
    visibility: visible;
  }

  /* Fix ReactSVG second wrapper padding bug */
  .close-icon > div {
    display: flex;
  }

  .close-icon svg {
    width: $closeIconSize;
    margin: $closeIconInnerMargin;
  }

  .mc-suggestion-icon {
    height: 100%;
    width: 1.1em;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;

    svg {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .mc-fixture-icon {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 100%;
  }

  .mc-fixture-icon svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.625em;
    vertical-align: middle;
    fill: $color-svg;
  }

  .mc-suggestion:hover,
  .mc-cursor {
    .mc-fixture-icon svg {
      transform: scale(1.1);
      fill: $color-focus-svg;
    }
  }

  .mc-dropdown-menu {
    width: 100%;
    color: $text-color;
    background-color: $color-bg;
    border: 1px solid $color-border;
    border-top: 0;
    border-radius: 5px;
    padding: 0.5em;
    box-shadow: 1px 5px 30px -10px $color-shadow;
  }

  .mc-dropdown-menu .mc-suggestion {
    text-align: left;
    cursor: pointer;
    vertical-align: middle;
  }

  .mc-dropdown-menu .mc-suggestion img {
    height: 2em;
    margin-top: 0.5em;
    margin-right: 10px;
    float: left;
  }

  .mc-dropdown-menu .mc-suggestion small {
    font-size: 0.8em;
    color: $color-info;
  }

  .mc-header {
    font-weight: bold;
    margin-bottom: 0.5em;
    border-bottom: 1px solid $color-border;
    padding-left: $input-left-padding;
    padding-right: $input-right-padding;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
