.addVoucherGroupForm {
  &_container {
    width: 100%;
    height: 100%;
    padding: 25px;
    max-width: 1000px;

    .fieldErrorMsg {
      margin-top: 5px;
    }

    .boxedSelectWrapper,
    .boxedSelectMultipleWrapper {
      width: 100%;
    }

    .formRow_item {
      margin-bottom: 10px;
    }

    .ekButton {
      text-transform: uppercase;
    }
  }

  &_wrap {
    display: flex;
    background: white;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.07), 0 2px 3px 0 rgba(0, 0, 0, 0.11), 0 0 7px 0 rgba(0, 0, 0, 0.05);
    max-width: 1200px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &_col1 {
    width: 50%;
    padding: 20px;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  &_col2 {
    width: 50%;
    padding: 20px;
    background: #fafafa;
    border-left: 1px solid #e5e5e5;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  &_colTitle {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 19px;
  }

  &_col2Title {
    margin-bottom: 37px !important;
  }

  &_toggleRow {
    padding: 0 15px 8px 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  &_toggleRowOn {
    padding: 15px;
    margin-bottom: 10px;
    background: white;
    border: 1px solid #e5e5e5;

    .boxedInputWrapper {
      padding: 12px;
    }
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 15px;

    .ekButton {
      margin-left: 15px;
      text-transform: uppercase;
    }
  }
}

.vouchers-confirm-revoke-container {
  display: flex;
  flex-direction: column;
  color: $color-primary-darkest;

  .confirm-message {
    margin-bottom: 15px;
  }

  .code-container {
    text-align: center;
    margin-bottom: 15px;
    background-color: $color-bg-light-clear;

    .voucher-code {
      display: inline-block;
      padding: 10px;
      font-weight: bold;
      color: $color-primary-dark;
    }
  }

  .confirm-buttons {
    display: flex;
    justify-content: space-around;

    .confirm-button {
      width: 50%;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

.voucherGroupDetail {
  $page-padding: 10px;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: $page-padding;

  > .container {
    height: 100%;
    min-height: 0%; // firefox fix | keep percentage: 0%
    display: flex;
    flex-direction: column;
    margin-top: $page-padding;

    > .row {
      flex-shrink: 0;

      &:last-child {
        height: 100%;
        min-height: 0%; // firefox fix | keep percentage: 0%
        flex-shrink: 1;
        display: flex;
      }

      > div {
        margin-bottom: 30px;
      }
    }
  }

  button.tab[disabled] {
    text-decoration: line-through !important;
    background-color: lightgrey !important;
    color: grey !important;
  }

  $block-height: 238px;
  $info-block-padding: 7px;

  .detail-info-container,
  .voucher-group-rules {
    padding-top: $info-block-padding;
    padding-bottom: $info-block-padding;
  }

  @media (min-width: 990px) {
    .detail-info-container,
    .voucher-group-rules {
      .listTitle,
      .listValue {
        margin: $info-block-padding auto;
      }
    }
  }

  .related-bookings {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: $advanced-list-container-shadow;
    background-color: $color-white;

    .numbered-container {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 990px) {
      height: $block-height;
      padding: 0 15px;

      .numbered-loader {
        .advancedList_loader {
          height: auto;
        }
      }
    }

    @media (max-width: 990px) {
      flex-direction: row;
      padding: 10px;
      height: 120px;

      .numbered-loader {
        width: 50%;
      }

      .numbered-container {
        flex-direction: row;
        width: 100%;
      }

      .numbered-block {
        width: 50%;
      }

      a {
        width: 100%;
      }
    }

    .numbered-block {
      align-self: center;
      display: flex;

      @media (min-width: 990px) {
        width: 100%;
      }

      &:first-child {
        padding-bottom: 10px;
      }

      &:last-child {
        padding-top: 10px;
      }

      $icon-size: 3em;
      $icon-padding: 15px;

      a {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        transition: background-color 0.3s ease;
        text-decoration: none;

        .number {
          font-size: $icon-size;
          color: $color-main;
          margin-right: $icon-padding;
          vertical-align: baseline;
          display: inline-block;
        }

        span {
          color: $color-grey-light4;
          vertical-align: super;
          display: flex;
          align-items: center;
        }

        @media (min-width: 990px) {
          width: 100%;
        }

        &:hover {
          background: $color-bg-light-clear;
        }
      }

      @media (max-width: 990px) {
        &:first-child {
          padding-bottom: 0;
          padding-right: 5px;
        }

        &:last-child {
          padding-top: 0;
          padding-left: 5px;
        }
      }

      div {
        display: inline-block;
      }

      .icon {
        margin-right: $icon-padding;
        color: $color-main;
        height: $icon-size;
        width: $icon-size;

        $svg-size: calc(#{$icon-size} * 0.9);

        &-ready {
          margin-right: $icon-padding + 5px;
          width: $svg-size;
          height: $svg-size;
        }

        .frame {
          fill: $color-grey-light4;
        }

        .content {
          fill: currentColor !important;
          color: inherit !important;
        }
      }
    }
  }

  .voucher-detail {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      .advancedList_chipsWrap {
        margin: 0 0 15px;
        max-width: none;
      }
    }

    &-actions {
      display: flex;
      max-width: none;
      width: 100%;
      flex-shrink: 0;
      margin-bottom: 20px;
    }

    &-buttons {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        color: $color-main !important;

        svg {
          fill: currentColor !important;
          color: inherit !important;
        }
      }

      button:disabled {
        color: $color-primary-dark !important;
      }
    }
  }

  .advancedList_row {
    min-height: 45px;
  }

  .advancedList_wrap {
    margin: 0;
    max-width: none;
  }

  &_rulesValueLabel {
    width: 50px;
    display: flex;
  }

  &_displayFlex {
    display: flex;
  }

  &_actions {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;

    .advancedList_actions {
      margin: 0;
      width: auto;
    }
  }

  &_wrap {
    background: white;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;

    @media (min-width: 990px) {
      min-height: $block-height;
    }
  }

  &_row {
    display: flex;
  }

  &_col1 {
    min-width: 150px;
    height: 150px;
    background: pink;
    display: none;
  }

  &_col2 {
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);

    h3 {
      margin-top: 0;
    }
  }
}

.createVoucherCodeForm {
  &_codeError {
    font-size: 12px;
    color: red;
  }

  &_codeWrap {
    padding-right: 50px;
    position: relative;

    .boxedInputWrapper {
      margin-bottom: 5px;
    }
  }

  &_addIcon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .ekRadio_container {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin-bottom: 15px;
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: -5px;

    .ekButton {
      margin-left: 15px;
      text-transform: uppercase;
    }
  }
}

.noBorder {
  border: 0;
}

.marginRight10 {
  margin-right: 10px;

  &.inline {
    display: inline-flex;
  }
}

.listValue {
  color: rgba(0, 0, 0, 0.84);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;

  &.list-restrictions {
    display: flex;
    flex-direction: column;
  }
}

.listTitle {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom: 10px;
}

.nonClickable {
  cursor: default;
}

// Voucher List

.voucherList {
  @include advanced-list-page-padding;
  display: flex;
  flex-direction: column;
  height: 100%;

  .advancedList_actions {
    width: 100%;
  }

  &_header {
    width: 100%;
    display: flex;
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}

.voucher-restrict {
  $parkings-border: 1px solid $color-primary3;

  &-modal-inner {
    display: flex;
    flex-direction: column;

    > .button-container {
      margin-top: 10px;
      align-self: flex-end;
    }

    .list-select-component {
      .list-select {
        min-height: 200px;
        max-height: 500px;
        height: 100%;
      }
    }
  }

  &-parking {
    &-container {
      border: $parkings-border;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 10px;
      word-break: break-word;
    }

    &-number {
      display: flex;
      margin: 10px;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      &-prefix {
        border-right: $parkings-border;
        max-width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        flex-grow: 3;
      }
    }
  }
}
