.companiesPage {
  &_content {
    @include advanced-list-page-padding;
    height: 100%;
    font-family: $main-font;
  }

  &_management {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &_title {
    @include pageTitle();
  }

  &_managementHeader {
    padding-bottom: 25px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }
}

.companies_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
