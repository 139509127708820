@import 'styles/iphone';

section.list-details.mobile-home,
section.list-details.mobile-home > * {
  padding: 0;
}

.hs-container {
  display: flex;

  div#items,
  div#preview-tiles {
    display: flex;
    width: 50%;
    flex-basis: 50%;
  }

  div#preview-tiles {
    justify-content: center;
  }

  .hs-preview-wrapper {
    background-color: #fff !important;
    display: flex;
    width: 100%;
    padding: 10px 10px 10px 0;
    flex-wrap: wrap;
    align-content: flex-start;

    .preview-label {
      position: sticky;
      align-content: center;
    }

    .iphone-x {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      padding: 0 20px;

      .logo {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #f4f4f4;

        > span > span {
          color: black;
          position: relative;
        }

        img {
          width: 100%;
        }
      }

      .plus.service {
        margin-left: 0;

        &.default {
          width: calc(50% - 5px);
          height: 100px;
        }

        &.primary {
          width: 100%;
          height: 200px;
        }

        &.secondary {
          width: 100%;
        }

        &.service {
          border: 0;
          border-radius: 5px;

          .d {
            display: flex;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            align-items: flex-end;
            flex-wrap: nowrap;
            color: white;
            padding-bottom: 10px;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .hs-wrapper,
  .hs-preview-wrapper {
    background-color: #f4f4f4;
    min-height: 70px;
    display: flex;
    width: 100%;
    padding: 10px 10px 10px 0;
    flex-wrap: wrap;
    align-content: flex-start;

    .plus {
      background-color: gray;
      display: flex;
      width: calc(50% - 10px);
      align-items: center;
      height: 100px;
      justify-content: center;
      margin-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &.default {
        width: calc(50% - 10px);
        height: 100px;
      }

      &.primary {
        width: 100%;
        height: 200px;
      }

      &.is-not-draggable {
        order: 100;
        background-color: #e9e9e9;

        &.help {
          width: 100%;
          order: 101;
          cursor: text;
          background-color: #f4f4f4;
        }
      }

      &.secondary {
        width: 100%;
      }

      &.service:not(.ghost) {
        background-color: #e9e9e9 !important;
        border: 1px solid #505050;
        border-radius: 3px;

        .d {
          display: flex;
          width: 100%;
          height: 100%;
          align-content: center;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          cursor: pointer;
        }

        button.delete-handler:hover {
          div svg {
            fill: #8b8b8b !important;
          }
        }
      }

      &.ghost {
        background-color: lightskyblue !important;
        border: 1px dashed #6ca5c8;
        pointer-events: none;
      }

      &.chosen.sortable-drag {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .hs-form-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    background-color: #fff;
    padding: 10px;

    form {
      width: 100%;

      &.HomepageSettings-form {
        > div {
          margin-bottom: 20px;
        }

        .hs-name-action {
          display: flex;
          align-items: center;

          .col {
            flex-basis: 100%;
            margin-right: 10px;
          }

          .actions-wrapper {
            flex-basis: 100%;
            align-self: flex-end;

            button,
            button div div,
            > * {
              line-height: 60px !important;
              height: 60px !important;
            }
          }
        }

        .hs-actions {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .prefill {
          display: flex;
          align-items: flex-end;

          .boxedSelectBlock {
            flex-basis: 50%;

            .boxedSelectBlockInner {
              &.half-row {
                .boxedSelectWrapper {
                  width: 100%;
                }
              }
            }
          }
        }

        .boxedSelectBlockInner {
          &.full-row {
            .boxedSelectWrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.sc-child-wrap {
  form {
    &.HomepageSettings-form-action {
      margin: 10px;
      padding: 20px;

      .translations {
        width: 80%;
        margin-bottom: 20px;

        .boxedInputWrapper {
          margin-bottom: inherit;
        }
      }

      > div,
      div.hs-filters > div {
        display: flex;
        flex-wrap: wrap;

        div.col {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          min-height: 120px;

          .ss-main {
            width: 80% !important;
          }

          .mapbox-complete {
            display: flex;

            .mapbox-main {
              flex-basis: 80%;
            }
          }
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
      }

      .boxedInputWrapper {
        width: 80%;
        margin-bottom: 20px;
      }

      .boxedSelectWrapper {
        width: 80%;
      }
    }
  }
}

.hovereffect {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  border-color: transparent;
  cursor: default;

  .overlay {
    @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }

  &.secondary {
    &:hover {
      h2 {
        @include transform(translateY(-40px));
      }

      div.help-tile {
        @include box-opacity(1);
        @include transform(scale(1));
        margin-top: 5px;
      }
    }
  }

  &.default {
    h2 {
      padding: 5px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      min-width: 90%;
      width: 100%;
    }

    &:hover {
      h2 {
        @include transform(translateY(-45px));
        padding: 5px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        min-width: 90%;
        width: 100%;
      }

      div.help-tile {
        @include box-opacity(1);
        @include transform(scale(1));
        margin-top: 3px;
        padding: 1px;
        font-size: 11px;

        div p {
          margin: 0;
          color: #000;
        }
      }
    }
  }

  h2 {
    @include transform(translateY(-50%));
    @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
    position: relative;
    top: 50%;
    color: #000;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }

  div.help-tile {
    @include box-opacity(0);
    @include transform(scale(0));
    @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    color: #000;
    background-color: transparent;
    font-weight: normal;
    margin: 10px 0 0;

    div:first-child {
      flex-basis: 90%;
    }

    div:last-child {
      flex-basis: 10%;
    }

    div p {
      margin: 0;
      color: #000;
    }
  }

  &:hover {
    h2 {
      @include transform(translateY(-80px));
      background: rgba(0, 0, 0, 0.6);
      padding: 10px;
      color: #fff;
    }

    div.help-tile {
      @include box-opacity(1);
      @include transform(scale(1));
      margin-top: 15px;
    }
  }
}
