.createInvoiceForm {
  @include clearfix;
}

.createInvoiceForm_row {
  @include remIt(margin-bottom, 25);
}

.ekButton.createInvoiceForm_actionsButton {
  @include remIt(min-width, 218);
  @include remIt(margin-top, 20);
  @include remIt(margin-left, 10);
  float: right;
}

.fieldErrorMsg--createInvoiceForm {
  @include remIt(margin-top, 10);
}
