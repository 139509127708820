.booking-tile {
  @include vehicleTile('no');

  .actions {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    .trigger {
      display: inline-flex !important;
      button {
        height: auto !important;
        line-height: auto !important;
        div div {
          height: auto !important;
          line-height: normal !important;

          span {
            min-height: 38px;
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
    & > div,
    & span > div {
      margin: 5px;
      display: inline-flex;

      span {
        text-transform: none;
      }
    }
  }

  .vehicle-tile-children {
    margin-top: 10px;

    & .row-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-end;

      .label {
        color: grey;
        font-size: 12px;
      }

      .value {
        color: black;
        font-weight: 500;
        text-decoration: underline;

        a {
          color: black;
          font-weight: normal;
        }

        .link {
          cursor: pointer;
        }
      }
    }
  }
}
