.parkingDetailsPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }
}

.parking_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
