$focus-color: $color-secondary;
$text-color: $color-primary-darkest;
$input-height: 40px;
$input-border: 1px solid #ddd;
$input-padding: 10px;
$border-radius: 2px;

@mixin readOnlyColors($-important: false) {
  background-color: $color-most-light;

  @if $-important {
    color: $color-text-dark4 !important;
    -webkit-text-fill-color: currentColor !important;
  } @else {
    color: $color-text-dark4;
    -webkit-text-fill-color: currentColor;
  }
}

.geo-disabled {
  input {
    @include readOnlyColors;
  }

  input[disabled] {
    cursor: not-allowed;
  }
}

.boxedInput {
  &[disabled] {
    @include readOnlyColors;
    cursor: not-allowed;
  }
}

.boxedInputWrapper {
  position: relative;

  &--label .boxedInput_label:before {
    @include transform(translateY(5%));
  }

  .info-content {
    position: relative;
    display: flex;
    align-items: center;

    .icon-container {
      position: absolute;
      right: 10px;
      cursor: context-menu;
    }
  }

  $passwordSvgSize: 25px;
  $passwordSvgPadding: 10px;
  $passwordInputPadding: $passwordSvgSize + ($passwordSvgPadding * 2);

  .boxedInput_password {
    display: flex;
    align-items: center;
    justify-content: end;

    input {
      padding-right: $passwordInputPadding;
    }
  }

  .sc-password-svg {
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    cursor: pointer;
    width: $passwordSvgSize;
    height: $passwordSvgSize;
    background: transparent;
    border: 0;
    padding: 0;

    > div {
      pointer-events: none;
    }

    &:not(.sc-show) .sc-open,
    &.sc-show .sc-closed {
      display: none;
    }

    svg {
      color: #555;
      width: 100%;
      height: 100%;
    }
  }
}

.boxedInputWrapper--email .boxedInput_label:before {
  content: $icon-email;
}

.boxedInputWrapper--password .boxedInput_label:before {
  content: $icon-password;
}

.boxedInputWrapper--search .boxedInput_label:before {
  content: $icon-search;
}

.boxedInputWrapper--phoneNumber {
  @include flex(1);
}

.boxedInput {
  @include remIt(font-size, 12);
  @include remIt(line-height, 15);
  width: 100%;
  padding: $input-padding;
  color: $text-color;
  border: $input-border;
  height: $input-height;
  outline: none;
  border-radius: $border-radius;
  background-color: #fff;
  transition: 0.2s ease-out;

  @include placeholder() {
    color: $text-color;
    opacity: 0.5;
  }

  &:focus:not(:disabled) {
    border-color: $focus-color;
  }

  &:disabled {
    color: rgba($text-color, 0.8);
    cursor: not-allowed;
  }

  &::-ms-reveal {
    display: none;
  }

  &--password::-ms-clear {
    display: none;
  }

  &__toolTip {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    border-radius: 20px;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    color: $color-white;
    background-color: $color-text-dark;
    cursor: pointer;
    position: absolute;
    top: -5px;
  }

  &--color-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .color-picker {
      margin-top: 10px;
      z-index: 2500;
      position: absolute;
    }

    .picker-cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .color-label {
      padding-right: $input-padding;
    }

    .picker-container {
      position: relative;

      .readOnly {
        cursor: not-allowed;
      }
    }

    .picker-button {
      margin-right: 10px;
      cursor: pointer;
      border-radius: $border-radius;
      flex-shrink: 0;
      border: $input-border;
      width: $input-height;
      height: $input-height;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(.readOnly):hover {
        background-color: $color-primary-lightest;
      }
    }

    .open-picker {
      cursor: default;
    }

    $picker-size: 20px;

    .picker-color {
      border: $input-border;
      height: $picker-size;
      width: $picker-size;
    }
  }
}

.boxedInputWrapper_disabled {
  .boxedInput--date {
    @include readOnlyColors;
    background-image: none;

    input {
      @include readOnlyColors(!important);
      cursor: not-allowed !important;
    }

    &:before {
      content: '';
    }
  }
}

.boxedInput_button {
  @include remIt(font-size, 11);
  @include remIt(line-height, 13);
  color: $text-color;
  background: none;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 45px;
  text-align: left;
  opacity: 0.6;
  text-transform: uppercase;
}

.boxedInput_label {
  display: block;
  position: relative;

  &:before {
    @include remIt(font-size, 14);
    @include transform(translateY(-50%));
    font-family: $icons-font;
    color: $color-primary;
    position: absolute;
    top: 50%;
    left: 15px;
    z-index: 1;
  }
}

.boxedInput_labelText {
  @include remIt(font-size, 11);
  display: block;
  color: $text-color;
  padding-bottom: 5px;
  text-transform: uppercase;
  position: relative;

  &._is_hidden {
    @include remIt(font-size, 0);
    @include remIt(line-height, 19);
    visibility: hidden;
  }
}

.boxedInput_inputTextArea {
  height: 150px;
}

.boxedInput_inputSmallTextArea {
  height: 80px;
}

// fix for material-ui default look&feel
// TODO: we have to resort to !important to override inline-styles, improve when lib allows it
.boxedInput--date {
  @include remIt(font-size, 12, $after: ' !important');
  @include remIt(line-height, 15, $after: ' !important');
  @include remIt(background-size, 15 15);
  background-position: right #{(pxToRem(10) + 'rem')} center;
  background-repeat: no-repeat;
  background-image: url('#{$img-path}datepicker.svg');
  padding: 0;
  height: 40px !important;
  width: 100% !important;

  input {
    padding: 0 0 0 15px !important;
  }

  hr {
    display: none;
  }

  // placeholder
  > div:first-child {
    bottom: 5px !important;
  }
}

.boxedInput--time {
  @include remIt(font-size, 12, $after: ' !important');
  @include remIt(line-height, 15, $after: ' !important');
  height: 40px !important;
  width: 100% !important;
  background-color: $color-white !important;

  hr {
    display: none;
  }

  &:before {
    @include remIt(font-size, 26);
    @include transform(translateY(-50%));
    content: $icon-time;
    color: $text-color;
    font-family: $icons-font;
    position: absolute;
    top: 65%;
    right: 15px;
  }
}

.boxedInput_inputText {
  position: relative;
  display: block;
}
