.dashboard-update-tooltip {
  padding: 5px 10px;
  font-size: 0.8rem;
}

.dashboard-page {
  $plusColor: #dd3c3c;
  $minusColor: #62c34a;
  $infoColor: #637788;
  $boxShadow: 0 0 0.9375em 0 rgba($color-primary3, 0.5);

  .sc-main {
    padding: 16px;

    .sc-title {
      font-size: 1.8em;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .sc-update-wrap button {
      box-shadow: $boxShadow;
      display: inline-flex;
      border: 0;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      background: white;

      svg {
        margin: 5px;
      }

      > div {
        display: inline-flex !important;
      }
    }

    .sc-content {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  .dashboard-item {
    box-shadow: $boxShadow;
    background-color: $color-white;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    padding: 1.25em;
    display: flex;

    .sc-info {
      color: $infoColor;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;

      .sc-count {
        font-size: 2.425em;
      }

      .sc-details {
        font-size: 1em;

        &:not(:last-child) {
          margin-bottom: 0.5em;
        }
      }

      .sc-updates {
        font-size: 1.3em;
        display: flex;
        align-items: center;

        .sc-symbol {
          margin-right: 0.5em;
          width: 0;
          height: 0;
        }

        .sc-num {
          color: $infoColor;
          margin-left: 0.5em;
          font-size: 0.8em;
        }

        &.sc-plus {
          color: $plusColor;

          &.sc-invert {
            color: $minusColor;
          }

          .sc-symbol {
            border-left: 0.4em solid transparent;
            border-right: 0.4em solid transparent;
            border-bottom: 0.4em solid currentColor;
          }
        }

        &.sc-minus {
          color: $minusColor;

          &.sc-invert {
            color: $plusColor;
          }

          .sc-symbol {
            border-left: 0.4em solid transparent;
            border-right: 0.4em solid transparent;
            border-top: 0.4em solid currentColor;
          }
        }
      }
    }

    .sc-icon-wrap {
      width: 3em;
      height: 3em;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 0.9375em;
      border-radius: 50%;

      svg {
        height: 1.5875em;
        width: auto;
      }

      > div {
        display: inline-flex;
      }
    }
  }
}
