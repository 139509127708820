.subCompanyForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 30px;
  }

  &_title {
    @include pageTitle();
  }

  &_row {
    margin-bottom: 25px;
  }

  &_boxedSelectWrapper {
    margin-right: 10px;
  }

  &_fullWidthBoxedSelectWrapper {
    width: 100%;
  }

  &_actions {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    cursor: pointer;
    margin-right: 20px;
  }

  &_subscriptionLabel {
    @include remIt(font-size, 11);
    display: block;
    color: $color-primary-darkest;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  &_subscriptionUrl {
    @include remIt(font-size, 12);
    @include remIt(line-height, 15);
    color: $color-primary-darkest;
  }

  &_boxedInput--half {
    width: 49%;
  }
}

.fieldErrorMsg--subCompanyForm {
  margin-top: 10px;
}
