@media (max-width: 767px) {
  ._member-files {
    .header {
      display: none;
    }

    .pageContainer {
      padding-top: 0;
    }
  }
}

.edit-member-files {
  box-shadow: $color-border-shadow10 0 0 20px 0;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    padding: 30px;
  }

  .sc-hide {
    visibility: hidden;
    position: absolute;
    z-index: -100;
  }

  .sc-sections {
    overflow: auto;
  }

  .sc-section:not(:first-child) {
    margin-top: 25px;
  }

  .sc-main {
    height: 100%;
    width: 100%;
    position: relative;
    background: white;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;

    > * {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .sc-title {
    font-size: larger;
    margin-bottom: 25px;
  }

  .sc-error {
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    height: 100%;
    width: 100%;
  }

  .sc-sub-title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: large;
  }

  .sc-actions {
    display: flex;
    margin-top: auto;
    padding-top: 20px;
    justify-content: flex-end;

    .ekButton {
      min-width: 200px !important;

      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
  }

  .sc-content {
    display: flex;

    @media (min-width: 768px) {
      flex-wrap: wrap;

      > *:not(:only-child) {
        width: calc(50% - 20px);
        height: 100%;
        flex-grow: 1;

        &:nth-child(even) {
          margin-left: 20px;
        }
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}
