.scheduled-export-form {
  $containerPadding: 20px;
  $filtersMargin: 19px;
  $itemMinWidth: 250px;
  $buttonsWidth: 200px;
  $itemGap: 20px;
  $mobileQuerySize: ($itemMinWidth * 2) + ($containerPadding * 2) + $itemGap;
  $tabletQuerySize: $mobileQuerySize + $itemGap + $buttonsWidth;

  min-width: $itemMinWidth + 100px;
  container-type: inline-size;
  container-name: scheduled-export-form;

  .fieldErrorMsg {
    margin-top: 5px;
  }

  .sc-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: $itemGap;
    row-gap: $itemGap - $filtersMargin;
  }

  textarea {
    min-height: 55px;
    max-width: calc(100cqw - #{$containerPadding * 2});
  }

  .sc-details-info {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: $itemGap;
  }

  .sc-details-fields {
    display: flex;
    flex-wrap: wrap;
    gap: $itemGap;

    > * {
      flex-basis: calc(50% - #{$itemGap / 2});
      min-width: $itemMinWidth;
    }
  }

  .sc-export-filters {
    display: flex;
    justify-content: end;
    margin-top: $filtersMargin;
    flex-shrink: 0;
  }

  .sc-settings,
  .sc-settings-fields {
    display: flex;
    flex-direction: column;
    gap: $itemGap;
  }

  .sc-destination-fields {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    gap: $itemGap;
  }

  .sc-security-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $itemGap;
  }

  .sc-file-format .sc-title {
    margin-bottom: $itemGap;
    font-size: 1.1em;
  }

  .sc-file-format .sc-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $itemGap;
  }

  .sc-actions {
    display: flex;
    justify-content: end;
    gap: 20px;
  }

  .sc-details-desc {
    min-width: calc(50% - #{$itemGap / 2});
  }

  .sc-actions,
  .sc-export-filters {
    > button {
      min-width: $buttonsWidth !important;
    }
  }

  .sc-s3-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @container scheduled-export-form (width > #{$tabletQuerySize}) {
    &.sc-view .sc-details-desc {
      max-width: $itemMinWidth;
    }

    &:not(.sc-view) {
      .sc-details-fields > *,
      .sc-export-filters {
        flex-grow: 1;
      }
    }

    .sc-security-fields.sc-file-security > *:last-child {
      grid-column: span 2;
    }
  }

  @container scheduled-export-form (width > #{$mobileQuerySize}) {
    &.sc-view .sc-details-desc {
      max-width: $itemMinWidth;
    }
  }

  @container scheduled-export-form (width < #{$tabletQuerySize}) {
    .sc-sftp-fields .sc-destination-fields {
      grid-template-columns: 1fr 1fr;
    }

    .sc-export-filters {
      flex-grow: 1;
    }

    .sc-file-format .sc-fields {
      grid-template-columns: 1fr 1fr;
    }

    .sc-sftp-fields {
      .sc-destination-fields,
      .sc-security-fields {
        grid-template-columns: 1fr 1fr;
      }
    }

    .sc-s3-fields {
      grid-template-columns: 1fr 1fr;
    }

    .sc-details-fields > * {
      flex-grow: 1;
    }
  }

  @container scheduled-export-form (width < #{$mobileQuerySize}) {
    .sc-details-info {
      width: 100%;
    }

    .sc-sftp-fields {
      .sc-destination-fields,
      .sc-security-fields {
        grid-template-columns: 1fr;
      }
    }

    .sc-file-format .sc-fields {
      grid-template-columns: 1fr;
    }

    .sc-s3-fields {
      grid-template-columns: 1fr;
    }

    .sc-actions,
    .sc-export-filters {
      > button {
        width: 50%;
        min-width: auto !important;
      }
    }
  }

  .sc-frequency {
    .ekRadio_container {
      margin-top: 10px;
      display: flex;
      gap: 20px;
    }
  }

  > * {
    &:not(.sc-actions) {
      border-radius: $column-list-border-radius;
      background: white;
      padding: $containerPadding;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
