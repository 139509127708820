.companyForm {
  padding-bottom: 1px; // fix no bottom margin

  .iti__country-list {
    z-index: 6;
  }

  .subscriptionUrl {
    input,
    input:disabled {
      cursor: text;
    }
  }

  .boxedInput__toolTip {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    line-height: 16px;
    top: auto;
  }

  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px 30px 10px;
    margin-top: 30px;
  }

  &_fileUpload_row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 40px;

    @media (max-width: 1100px) {
      &.multiple > * {
        min-width: 45%;
      }
    }

    .file-upload-dropzone-inner a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        margin-right: 5px;
        flex-shrink: 0;
      }
    }

    .file-upload {
      flex-grow: 1;
      flex-basis: 0;
      display: inline-flex;
      width: auto;
      min-width: 300px;
      margin: 10px 0;
    }

    .file-upload-box {
      max-width: 100%;
      flex-grow: 1;
      padding-top: 0;
    }
  }

  &_title {
    @include pageTitle();
  }

  &_row {
    margin-bottom: 25px;

    &--multiple {
      display: flex;
    }
  }

  &_boxedSelectWrapper {
    margin-right: 10px;
  }

  &_fullWidthBoxedSelectWrapper {
    width: 100%;
  }

  &_actions {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    margin-right: 20px;
  }

  &_subscriptionLabel {
    @include remIt(font-size, 11);
    display: block;
    color: $color-primary-darkest;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  &_subscriptionUrl {
    @include remIt(font-size, 12);
    @include remIt(line-height, 15);
    color: $color-primary-darkest;
  }

  &_boxedInput--half {
    width: 49%;
  }

  &_ekRadioTitle {
    padding-bottom: 3px;
  }

  &_ekRadioContainer {
    @include box-columns(2, 20px);
  }

  &_ekRadioWrapper {
    margin-top: 5px;
    display: inline-block;

    &:last-child {
      margin-left: 25px;
    }
  }
}

.fieldErrorMsg--companyForm {
  margin-top: 10px;
}
