.side-list {
  display: flex;
  padding: 20px;
  height: 100%;
  width: 100%;

  > .list-details {
    font-size: 14px;
  }

  > .sc-list {
    font-size: 15px;
    border-radius: 6px;
    background: white;
    height: 100%;
    padding: 20px;
    min-width: 300px;

    .sc-item {
      border-radius: 4px;
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      &.sc-selected {
        color: white;

        svg {
          color: white;
        }
      }

      .align-svg {
        color: #b2b2b2;
        width: 16px;
        margin-left: 10px;
        height: 16px;

        svg {
          fill: currentColor;
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        background: #eaeaea;
      }
    }
  }
}

@media (min-width: 768px) {
  .side-list {
    > .sc-list {
      margin-right: 20px;
    }

    > .list-details > .sc-title-wrap {
      margin: 10px;
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .side-list {
    width: 100%;
    padding: 15px;

    input {
      @include remIt(font-size, 16);
    }

    > * {
      width: 100%;

      &:not(:last-child) {
        display: none;
      }
    }
  }
}
