.button-hover-msg {
  z-index: $flash_message_z_index + 100;
  border: 0.1em solid #dbdbdb;
  transition: opacity 0.2s;
  transition-delay: 0.3s;
  font-size: 0.9em;
  background: #f9f9f9;
  padding: 0 6px 2px;
  pointer-events: none;
  position: absolute;
  border-left-width: 0;
  opacity: 0;
  left: 0;

  &:not(.sc-overlap) {
    border-top-right-radius: 4px;
    border-bottom-width: 0;
    bottom: 0;
  }

  &.sc-overlap {
    border-bottom-right-radius: 4px;
    border-top-width: 0;
    top: 0;
  }

  &.sc-show {
    opacity: 1;
  }
}
