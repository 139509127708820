.list-modal {
  .addCompanyPage {
    .pageContainer {
      padding: 0;

      .addCompanyPage_content {
        padding: 0 30px;
      }
    }
  }
}
