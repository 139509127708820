.memberCommentForm_row {
  margin-bottom: 20px;
}

.memberCommentForm_button {
  width: 100%;
}

.fieldErrorMsg--memberCommentForm {
  margin-bottom: 2px;
}
