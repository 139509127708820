// mapbox-complete default styles

.mapbox-complete {
  $color-border: $color-primary2;
  $color-focus-svg: $color-primary-middle2;
  $color-address: $color-primary-middle2;
  $color-svg: $color-primary2-alt2;
  $color-cursor: $color-primary-light2;
  $color-shadow1: $color-border-shadow1;
  $color-shadow2: $color-border-shadow8;
  $color-bg: $color-white;

  .mc-input,
  .mc-hint {
    width: 100%;
    padding-right: 35px;
    padding-left: 16px;
    line-height: 40px;
    height: 40px;
    border: 1px solid $color-border;
    border-radius: 3px;
    outline: none;
    font: inherit;
    appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
  }

  .mc-input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .mc-input::-ms-clear {
    display: none;
  }

  .mc-input:hover ~ .mc-input-icon svg,
  .mc-input:focus ~ .mc-input-icon svg,
  .mc-input-icon:hover svg {
    fill: $color-focus-svg;
  }

  .mc-dropdown-menu {
    width: 100%;
    background: $color-bg;
    box-shadow: 0 1px 10px $color-shadow1, 0 2px 4px 0 $color-shadow2;
    border-radius: 3px;
    margin-top: 3px;
    overflow: hidden;
  }

  .mc-suggestion {
    $line-height: 2.875em;

    cursor: pointer;
    height: $line-height;
    line-height: $line-height / 2;
    padding-left: 1em;
    padding-right: 0.4em;
    overflow: hidden;
  }

  .mc-suggestion {
    em,
    mark {
      font-weight: bold;
      font-style: normal;
    }
  }

  .mc-name {
    vertical-align: middle;
  }

  .mc-address {
    vertical-align: middle;
    font-size: smaller;
    margin-left: 12px;
    color: $color-address;
  }

  .mc-suggestion-icon {
    margin-right: 10px;
    vertical-align: middle;
  }

  .mc-suggestion-icon svg {
    fill: $color-svg;
    -webkit-transform: none;
    transform: none;
  }

  .mc-input-icon {
    border: 0;
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    outline: none;
  }

  .mc-input-icon.mc-icon-pin {
    cursor: initial;
  }

  .mc-input-icon svg {
    fill: $color-svg;
    position: static;
    top: 50%;
    right: 0;
    -webkit-transform: none;
    transform: none;
  }

  .mc-suggestion {
    &:hover {
      background: lighten($color-cursor, 3%);
    }

    &.mc-cursor {
      background: $color-cursor;
    }

    &:hover,
    &.mc-cursor {
      .mc-suggestion-icon svg {
        -webkit-transform: none;
        transform: scale(1.1);
        fill: $color-focus-svg;
      }
    }
  }

  .mc-footer {
    opacity: 0.8;
    text-align: right;
    padding: 0.5em 1em;
    font-size: 12px;
    line-height: 12px;
  }

  .mc-footer a {
    color: inherit;
    text-decoration: none;
  }

  .mc-footer a svg {
    vertical-align: middle;
  }

  .mc-footer:hover {
    opacity: 1;
  }
}
