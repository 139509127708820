.bookingVehicleInfo {
  &_comment {
    margin-left: 0.2em;
    max-width: 400px;
  }

  &_headline {
    @include flex-valign-center;
  }

  &_sub_block {
    display: inline-block;
    vertical-align: top;

    &:first-child {
      @include remIt(margin-right, 40);
    }

    &:last-child {
      @include remIt(margin-right, 0);
    }
  }
}
