.invoiceFindBooking {
  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    @include remIt(margin-bottom, 10);
    overflow: hidden;
  }

  .numberResultsSelectForm_rowslabelText {
    margin-left: 10px;
  }
}

.bookingsFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  @include remIt(margin-bottom, 15);
  @include remIt(padding, 25 10);
  background-color: $color-white;

  .formRow {
    @include remIt(padding-left, 20);
  }
}

.searchBookingsWrapper {
  .boxedInput {
    &--date {
      > div:first-child {
        height: 38px !important;
        bottom: 0 !important;
      }
    }
  }
}

.searchBookingsForm {
  .filters {
    margin-bottom: 15px;
    color: $color-primary-darkest;

    svg {
      fill: $color-primary-darkest !important;
    }
  }

  &_inputWrapper {
    width: 200px;
    display: inline-block;
    height: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    margin-right: 15px;

    .boxedInput_label:before {
      left: inherit;
      right: 15px;
    }
  }

  &_actions {
    @include remIt(padding-right, 20);
    text-align: right;
  }

  &_actionsButton {
    @include remIt(margin-left, 10);
    min-width: 218px;
  }
}
