.contract-v2 {
  .list-details {
    .sc-main-wrap {
      .sc-option-tab {
        margin-left: 20px;
      }

      .sc-option-details {
        display: flex;

        .checkboxMaterial {
          display: inline-block;

          label {
            width: auto !important;
          }
        }

        .sc-category {
          flex-basis: 50%;
        }

        .sc-boxes {
          margin-top: 10px;
          margin-left: 10px;
        }

        .sc-flat-boxes {
          margin-left: 10px;
        }
      }

      .checkboxMaterial {
        margin-bottom: 5px;

        label {
          margin-left: -10px;
        }
      }

      .sc-error-star {
        color: red;
        position: absolute;
        margin-left: 5px;
      }

      .sc-error-desc {
        position: absolute;
        margin-top: 10px;
        margin-left: 20px;
      }

      .sc-actions {
        margin-top: 40px;

        button {
          min-width: 200px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .contract-v2 {
    .list-details {
      .sc-option-details {
        flex-direction: column;

        .sc-category:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
