.membersList {
  @include advanced-list-page-padding;
  display: flex;
  flex-direction: column;
  height: 100%;

  .advancedList_actions {
    width: 100%;
  }

  .membersList_actionsButtons {
    a {
      cursor: pointer;
    }
  }

  &_header {
    width: 100%;
    display: flex;
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex: 1;

    a {
      margin-left: 5px;
      display: inline-block;
    }
  }
}
