.backUsersPage {
  &_content {
    @include advanced-list-page-padding;
    height: 100%;
    font-family: $main-font;
  }

  &_title {
    @include pageTitle();
  }

  &_management {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &_managementHeader {
    margin-bottom: -35px;
  }

  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.backUsers_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}

$rowHeight: 54px;
$avatarSize: 40px;

.advancedList_row.backUsersPage_row {
  cursor: default;
  height: $rowHeight;

  .avatar {
    text-transform: uppercase;
    position: relative;
    height: $avatarSize;
    width: $avatarSize;
    margin-top: calc((#{$rowHeight} - #{$avatarSize}) / 2);
    margin-bottom: calc((#{$rowHeight} - #{$avatarSize}) / 2);

    &.suspended .status {
      background-color: #b51111 !important;
    }

    &.active .status {
      background-color: #6d9e61 !important;
    }

    .tooltip {
      white-space: nowrap;
      visibility: hidden;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 15px;
      position: absolute;
      text-transform: none;
      z-index: 1;
      font-size: 12px;
      top: 10px;
      left: 50px;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  .contentUser {
    display: inline-flex;
    height: $avatarSize;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: calc((#{$rowHeight} - #{$avatarSize}) / 2);
    margin-bottom: calc((#{$rowHeight} - #{$avatarSize}) / 2);
    padding-left: calc(#{$avatarSize} + 10px);
    position: absolute;
  }

  .status {
    position: absolute;
    left: 24px;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    svg {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 12px !important;
      height: 12px !important;
      color: white !important;
    }
  }
}
