.mapbox-complete .mc-dataset .spinner-container {
  display: none;
}

.mapbox-complete .mc-dataset .location-spinner {
  border: 0.15em solid #e1e1e1;
  border-top: 0.15em solid #555;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: locationSpinAnimation 2s linear infinite;
}

.mapbox-complete.spinner-active .mc-dataset {
  .spinner-container {
    display: inline-flex;
  }

  .mc-suggestion-icon.current-location-icon {
    margin-right: 0;
    width: 0;
  }

  .mc-suggestion {
    pointer-events: none;
  }

  .mc-suggestion:hover,
  .mc-cursor {
    background: inherit;
    cursor: default;

    svg {
      transform: none;
      fill: #cfcfcf;
    }
  }
}

@keyframes locationSpinAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
