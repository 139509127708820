$newFieldHeightPadding: 13px;
$newFieldWidthPadding: 15px;
$newFieldMobileHeightPadding: 12px;
$newFieldMobileWidthPadding: 10px;

.phone-number-container {
  $flagPaddingRight: $newFieldWidthPadding / 2;

  .iti {
    display: block;
  }

  .iti__selected-dial-code {
    font-size: 12px;
  }

  .iti--separate-dial-code .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .iti__selected-flag {
    padding-left: $newFieldWidthPadding;
    padding-right: $flagPaddingRight;
  }

  .iti--allow-dropdown input,
  .iti--allow-dropdown input[type='text'],
  .iti--allow-dropdown input[type='tel'],
  .iti--separate-dial-code input,
  .iti--separate-dial-code input[type='text'],
  .iti--separate-dial-code input[type='tel'] {
    &.new-design-field {
      padding-right: $newFieldWidthPadding;
    }
  }
}

@media (max-width: 767px) {
  $flagPaddingRight: $newFieldMobileWidthPadding / 2;

  .phone-number-container {
    .iti__selected-flag {
      padding-left: $newFieldMobileWidthPadding;
      padding-right: $flagPaddingRight;
    }

    .iti--allow-dropdown input,
    .iti--allow-dropdown input[type='text'],
    .iti--allow-dropdown input[type='tel'],
    .iti--separate-dial-code input,
    .iti--separate-dial-code input[type='text'],
    .iti--separate-dial-code input[type='tel'] {
      &.new-design-field {
        padding-right: $newFieldMobileWidthPadding;
      }
    }
  }
}
