$button: (
  button-variant1-color: $color-secondary-dark,
  button-text-size: 16
);

// Constructor
// ==========================================================================

.buttonUploadWrapper {
  position: relative;
  display: block;
}

.buttonUpload {
  @include basicClickable();
  @include buttonColor(map_get($button, button-variant1-color), $color-white, reverse);
  @include remIt(font-size, map_get($button, button-text-size));
  @include remIt(line-height, 14);
  @include transition(all 0.2s ease-in);
  position: relative;
  padding: 12px 10px;
  text-align: center;
  display: inline-block;
  text-decoration: none;

  &--fullWidth {
    display: block;
    width: 100%;
  }

  &:before {
    @include remIt(font-size, map_get($button, button-text-size));
    content: $icon-upload;
    font-family: $icons-font;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
  }

  &_input {
    @include box-opacity(0);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &_label {
    font-weight: $medium-weight;
    display: block;
    color: map_get($button, button-variant1-color);
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &_labelButton {
    @include remIt(font-size, 16);
    color: map_get($button, button-variant1-color);
  }

  &_name {
    @include remIt(font-size, 14);
    margin-left: 10px;
  }
}
