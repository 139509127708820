/* Header
============================= */

$header: (
  header-shadow-color: #dddddd
);

@media (max-width: 1200px) {
  .header-menuIcon {
    display: none !important;
  }

  .header-menu .header-menuIconMobile {
    display: block !important;
  }
}

.cursorPointer {
  cursor: pointer;
}

.header-warning-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  flex-grow: 1;

  .sc-warning {
    display: inline-flex;
    background: transparent;
    border: 0;
    padding: 0;

    svg {
      width: 30px;
      height: auto;

      .inner {
        fill: #40330d;
        stroke: #40330d;
      }
    }
  }
}

.new-ui,
.new-header {
  .header-warning-container {
    margin: 0 10px 0 0;
  }

  .header {
    .header-searchInput {
      &::placeholder {
        color: $color-list-input-placeholder;
      }
    }

    &-actionIcons {
      height: 100%;
      align-items: center;

      &-companyWrap {
        margin-right: 10px;
      }

      &-accountIcon {
        margin: 0 10px 0 0;

        > div {
          height: 100%;
          display: flex !important;
          align-items: center;
          justify-content: center;

          > button {
            display: inline-flex !important;
            padding: 0 !important;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &-icon {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .header:not(.header-searchMode) {
    background: white !important;

    .header-superCompanyEditing:after {
      background: $color-menu-text;
    }

    .header-searchInputWrap {
      background: $color-list-input-bg;
    }

    .header-menuIcon,
    .header-menuIconMobile {
      svg {
        fill: black !important;
      }
    }

    .header-actionIcons {
      &-accountIcon svg {
        fill: black !important;
      }

      &-companyWrap {
        height: 40px;
      }
    }

    .header-searchInput {
      &-searchIcon svg {
        fill: $color-list-icon-button !important;
      }
    }
  }
}

.header {
  background-color: $color-toolbar-background;
  height: $page-container-padding;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $header-z-index;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 $color-border-shadow2;
  transition: 250ms;

  &-menuIconMobile {
    display: none !important;
  }

  &-superCompanyCloseOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 887;
  }

  &-serchModeContentOverlay {
    width: 100vw;
    position: fixed;
    height: 100vh;
    background: #f2f2f2;
    left: 0;
    top: 0;
    z-index: 800;
    overflow-x: scroll;
    padding: $page-container-padding 20px 30px;

    &-menuClosed {
      padding-left: 24px;
    }
  }

  &-logo {
    width: 80px;
    height: 38px;
    margin-top: 7px;
  }

  &-searchMode {
    background-color: #f6f6f6 !important;
    box-shadow: 0 0 4px $color-border-shadow5, 0 4px 8px rgba(0, 0, 0, 0.28);

    .header-superCompanyEditing:after {
      background: $color-menu-text;
    }

    &-back {
      margin-left: -18px;

      > button > div > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-menu {
    padding: 4px 12px;
  }

  &-page-name {
    color: $color-toolbar-text;
    font-size: 16px;
    width: 185px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-searchWrap {
    display: flex;
    flex: 1;
    align-items: center;
  }

  &-searchInputWrap {
    width: 100%;
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.15);
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 40px;

    &-searchMode {
      background: white;
    }
  }

  &-searchInput {
    background: none;
    border: 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-size: 16px;
    height: 36px;
    padding-right: 15px;
    width: 100%;

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #fff;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #fff;
    }

    &-searchMode {
      color: #212121;
    }

    &-searchMode::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #757575;
    }

    &-searchMode:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #757575;
      opacity: 1;
    }

    &-searchMode::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #757575;
      opacity: 1;
    }

    &-searchMode:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #757575;
    }

    &-searchMode::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #757575;
    }

    &:focus {
      outline: none;
    }

    &-searchIcon {
      padding: 0 15px;
      margin-top: 5px;
    }
  }

  &-language-mainFlag {
    width: 27px;
  }

  &-actionIcons {
    display: flex;

    &-accountIcon {
      margin-right: 14px;
      margin-top: -4px;
    }

    &-flexColumn {
      display: flex;
      flex-direction: column;
    }

    &-account {
      display: flex;
      align-items: center;
      margin-top: -10px;
      background: #eeeeee;
      width: 279px;
      height: 75px;
      margin-bottom: 8px;
    }

    &-account svg {
      margin: 0 15px;
      min-width: 15px;
    }

    &-accountUsername {
      font-size: 15px;
      font-weight: bold;
    }

    &-accountEmail {
      color: rgba(0, 0, 0, 0.84);
      font-size: 13px;
    }

    &-companyName {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        i.super-company {
          display: inline-block;
          font-size: 28px;
          font-family: 'glyph-icons';
          font-style: normal;

          &:before {
            content: $icon-companies;
            vertical-align: text-bottom;
          }
        }
      }
    }

    &-superCompanyTooltip,
    &-companyTooltip {
      background: #616161;
      padding: 5px 15px;
      opacity: 0;
      font-size: 0.8em;
      color: #fff;
      border-radius: 3px;
      transition: 300ms;
      text-align: center;
    }

    &-superCompanyTooltip {
      min-width: 120px;
    }

    &-tooltipWrap {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      top: 62px;
    }

    &-companyWrap {
      height: 100%;
      display: flex;
      align-items: center;

      input {
        padding-right: 65px !important;
      }
    }
  }

  &-companyIconWrap {
    position: relative;
  }

  &-superCompanyEditing {
    border-radius: 2px;
    opacity: 0;
    transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;

    &:after {
      content: ' ';
      height: 2px;
      width: 0;
      position: absolute;
      top: 44px;
      z-index: 1000;
      background-color: $color-main;
    }

    &-edit {
      width: 256px;
      margin: 0 10px;
      opacity: 1;

      &:after {
        content: ' ';
        width: 256px;
      }
    }
  }

  &-superCompanyAutoComplete {
    display: flex;
  }

  &-superCompanyAutoCompleteWrap {
    display: flex;
    position: relative;
    z-index: $header-z-index;
  }

  &-companyAutoComplete-button {
    position: relative;

    i.company {
      display: inline-block;
      font-size: 20px;
      font-family: 'glyph-icons';
      font-style: normal;

      &:before {
        content: $icon-subcompanies;
        color: $color-white;
      }

      &.search:before {
        color: $color-menu-text;
      }
    }
  }

  &-actionIcons-companyTooltip {
    min-width: 90px;
  }

  &-superCompanyAutoComplete-button:hover .header-actionIcons-superCompanyTooltip {
    opacity: 0.9;
  }

  &-companyAutoComplete-button:hover .header-actionIcons-companyTooltip {
    opacity: 0.9;
  }

  @keyframes lds-rolling {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @-webkit-keyframes lds-rolling {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .lds-rolling {
    position: relative;
  }

  .lds-rolling div,
  .lds-rolling div:after {
    position: absolute;
    width: 70px;
    height: 70px;
    border: 8px solid $color-toolbar-background;
    border-top-color: transparent;
    border-radius: 50%;
  }

  .lds-rolling div {
    -webkit-animation: lds-rolling 1s linear infinite;
    animation: lds-rolling 1s linear infinite;
    top: 100px;
    left: 100px;
  }

  .lds-rolling div:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  // to check position
  .lds-rolling {
    width: 24px !important;
    height: 60px !important;
    -webkit-transform: translate(-25px, -25px) scale(0.25) translate(25px, 25px);
    transform: translate(-25px, -25px) scale(0.25) translate(25px, 25px);
  }
}

/* Navigation
============================= */
$headerNav: (
  headerNav-base-color: $color-primary-darkest,
  headerNav-subItems-color: $color-white,
  headerNav-link-size: 16,
  headerNav-subItems-link-size: 18,
  headerNav-subItems-icon-size: 24,
  headerNav-notif-icon-size: 20,
  headerNav-subItems-shadow: $color-primary
);
