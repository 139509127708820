.resetPasswordForm_boxedInput {
  margin-bottom: 30px;
}

.resetPasswordForm_rememberedPassword {
  @include remIt(font-size, 14);
  @include remIt(line-height, 17);
  @include basicClickable;

  display: flex;
  text-decoration: underline;
  text-align: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;
  color: $color-primary-darkest;

  &:hover {
    background: $color-bg-light-clear;
  }
}

.fieldErrorMsg--resetPasswordForm {
  margin-bottom: 2px;
}

.resetPasswordForm_mainErrorMsg {
  @include remIt(font-size, 14);
  color: $color-error;
  margin-bottom: 10px;
}

.resetPasswordForm_successMsg {
  @include remIt(font-size, 14);
  color: $color-good;
  margin-bottom: 10px;
}
