.paybackSettings {
  .connected-account-form {
    .bank-details-form {
      &-fields {
        margin-top: 2em;
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
    .submit-container {
      margin-top: 2em;
      display: flex;
      justify-content: end;

      .cta-payment,
      .remove-account,
      .migrate {
        margin-left: 10px !important;
      }

      .cta-payment {
        &.pending {
          button {
            background-color: $color-warn !important;
          }
        }
        &.completed {
          button {
            background-color: $color-main !important;
          }
        }
      }
    }
  }
}
