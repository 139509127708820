.fuel-gauge {
  cursor: pointer;

  svg {
    g.gauge {
      fill: #e8e7e7;
    }

    &.full-tank g.gauge {
      fill: #b4e283;
    }

    &.quarter-tank {
      rect:nth-child(1) {
        fill: #ff7676;
      }
    }

    &.half-tank {
      rect:nth-child(1),
      rect:nth-child(2) {
        fill: #ffdb79;
      }
    }
    &.three-quarter-tank {
      rect:nth-child(1),
      rect:nth-child(2),
      rect:nth-child(3) {
        fill: #e2e283;
      }
    }
  }
}
