.quick-sight {
  > .list-details > .sc-main-wrap {
    overflow: hidden;
  }

  > .list-details > .sc-title-wrap {
    display: flex;
    margin: 10px;
  }

  &.sc-no-items {
    > .list-details > .sc-title-wrap {
      display: none;
    }

    .sc-list {
      display: none;
    }
  }

  &.sc-hide-details {
    .list-details {
      visibility: hidden;
      position: absolute;
    }
  }

  > .list-details > .sc-main-wrap,
  .qs-dashboard-wrap,
  .qs-dashboard,
  .qs-container {
    height: 100%;
  }

  > .list-details,
  > .list-details > * {
    padding: 0;
  }

  .qs-error.sc-hide,
  .qs-container.sc-hide {
    display: none;
  }

  .qs-dashboard-info {
    display: flex;
    margin: 0 10px;
    $icon-padding: 2px;

    .qs-info-icon {
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(1em + #{$icon-padding * 2});
      width: calc(1em + #{$icon-padding * 2});
      background: $quickSight-info-icon-bg;
      border-radius: 50%;
      color: white;
    }
  }

  .qs-error {
    background: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;

    .sc-msg {
      position: absolute;
      background-color: white;
      margin: 20px;
      padding: 10px 20px;
      box-shadow: rgba(30, 30, 30, 0.3) 0 0 5px 0;
      border-radius: 3px;
      z-index: 1;

      h2 {
        color: orange;
      }
    }

    .sc-bg {
      padding: 10px;
      filter: blur(8px);

      img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .quick-sight {
    > .sc-list {
      margin-right: 20px;
    }
  }
}

@media (max-width: 767px) {
  .quick-sight {
    &.sc-hide-details:not(&.sc-no-items) {
      > .sc-list {
        display: block;
      }
    }
  }
}
