.booking-tile {
  .url-button {
    color: white;
    height: 36px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  &_wrap {
    &.timeline {
      height: 350px;
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        top: 25px;
        left: 50%;
        height: 250px;
        width: 3px;
        background-color: black;
      }

      div#estimations-side {
        width: 50%;
        color: #323c47;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > div {
          display: flex;
          flex-direction: column;
          max-width: 80%;
          padding: 0 10% 0 0;
          position: relative;
          border-bottom: 2px dashed #909090;
          right: 0.75px;

          &::after {
            content: ' ';
            width: 12px;
            height: 12px;
            background-color: #909090;
            position: absolute;
            left: calc(100% - 4px);
            top: calc(100% - 5px);
            border-radius: 12px;
            z-index: 1;
          }

          &#estimated-start {
            top: 100px;

            &.start-cancel-before-estim-start {
              top: 150px;
            }
          }

          &#estimated-end {
            top: 190px;
          }

          &#estimated-start,
          &#estimated-end {
            > span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:last-child {
                font-size: 14px;
                color: #323c47;
              }
              &:first-child {
                font-weight: 500;
                color: #909090;
              }
            }
          }
        }
      }

      div#events-side {
        width: 50%;

        > div {
          position: relative;
          display: flex;
          flex-direction: column;
          padding-left: 10%;
          justify-content: center;

          &:before {
            content: ' ';
            width: 15px;
            height: 15px;
            background-color: black;
            position: absolute;
            left: -6px;
            border-radius: 15px;
          }

          &#in_progress {
            &.start-before-estimation {
              top: 45px;
            }

            &.start-after-estimation {
              top: 110px;
              &.start-on-time {
                top: 79px;
                z-index: 1;
              }
            }
          }

          &#canceled {
            &.start-before-estimation {
              top: 45px;
            }

            &.start-after-estimation {
              top: 110px;
              &.start-on-time {
                top: 79px;
                z-index: 1;
              }
            }
          }

          &#scheduled {
            top: 10px;
          }

          &#completed {
            top: 125px;

            &.end-before-estimation {
              top: 130px;

              &:after {
                content: ' ';
                position: absolute;
                top: 26px;
                left: 0px;
                width: 3px;
                z-index: 1;
                height: 30px;
                background-color: lightgrey;
              }
            }

            &.end-after-estimation {
              top: 195px;

              &:after {
                content: ' ';
                position: absolute;
                top: -5px;
                left: 0px;
                width: 3px;
                height: 25px;
                background-color: black;
              }

              &.end-on-time {
                top: 173px;
                z-index: 1;
              }
            }
          }

          &#late-booking {
            top: 195px;

            &:before {
              background-color: #ff392e;
              z-index: 1;
            }

            > span {
              &:first-child {
                color: #ff392e;
              }
            }

            &:after {
              content: ' ';
              width: 3px;
              top: -4px;
              left: 0;
              height: 30px;
              position: absolute;
              background-color: black;
            }
          }

          &#late-booking,
          &#completed,
          &#scheduled,
          &#in_progress,
          &#canceled {
            > span {
              &:last-child {
                font-size: 14px;
                color: #323c47;
              }
              &:first-child {
                font-weight: 500;
              }
            }
          }
        }

        .booking_status {
          &_in_progress {
            color: #2aacbf;
          }
          //aka created
          &_scheduled {
            color: #ff3e00;
          }

          &_completed {
            color: #55bf65;
          }

          &_canceled {
            color: #424242;
          }
        }
      }
    }
  }
  &_actions {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    & span {
      text-transform: none !important;
    }
  }
}
