.booking-info-v2 {
  &_headline {
    // @include flex-valign-center;
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    margin: 0 0 10px;
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;

    & .row-info {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 50px;

      .label {
        color: grey;
        font-size: 12px;
      }
      .value {
        color: black;
        font-weight: 500;
        padding-right: 5px;

        &.nps-bullet {
          position: relative;
          padding-left: 20px;

          ::before {
            content: ' ';
            position: absolute;
            width: 10px;
            height: 10px;
            top: 7px;
            left: 0;
            border-radius: 10px;
          }
          &.green {
            ::before {
              background-color: $color-completed;
            }
          }
          &.orange {
            ::before {
              background-color: $color-scheduled;
            }
          }
          &.red {
            ::before {
              background-color: $color-canceled;
            }
          }
        }
        a {
          color: black;
        }
        div.align-svg div svg {
          width: 15px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    & .comment {
      background-color: #f4f4f4;
      padding: 15px 30px;
      margin-bottom: 10px;
      font-weight: 500;
      width: 100%;
    }

    & .integration-error {
      padding: 15px 5px;
      margin-bottom: 10px;
      font-weight: 400;
      width: 100%;
      .label {
        color: grey;
        font-size: 12px;
      }
      .message {
        padding-left: 10px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    flex-wrap: wrap;

    & > div,
    & span > div {
      margin: 5px;

      span {
        text-transform: none;
      }
    }
  }

  &_status {
    position: relative;
    padding-left: 20px;

    ::before {
      content: ' ';
      position: absolute;
      width: 15px;
      height: 15px;
      top: 2px;
      left: 0;
      border-radius: 15px;
    }

    &.booking_status {
      &_in_progress {
        color: $color-progress;
        ::before {
          background-color: $color-progress;
        }
      }

      &_scheduled {
        color: $color-scheduled;
        ::before {
          background-color: $color-scheduled;
        }
      }

      &_completed {
        color: $color-completed;
        ::before {
          background-color: $color-completed;
        }
      }

      &_canceled {
        color: $color-canceled;
        ::before {
          background-color: $color-canceled;
        }
      }

      &_pre_booked {
        color: $color-prebooked;
        ::before {
          background-color: $color-prebooked;
        }
      }
    }
  }
  .error-line {
    color: $color-canceled;
    display: flex;
    align-items: center;
  }
}
