.editStatusForm_row {
  margin-bottom: 20px;
}

.editStatusForm_button {
  width: 100%;
}

.editStatusForm_date {
  margin-left: 0 !important;
}

.editStatusForm_title {
  margin-top: -5px !important;
  font-weight: normal;
}
