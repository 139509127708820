.damage-report-list {
  display: flex;
  flex-direction: column;
  background: $color-white;
  padding: 0 15px 15px;
  height: 100%;
  min-width: 850px;

  .external-link-icon {
    top: 5px;
    right: 5px;
  }

  .advancedList_header_sortIcon {
    justify-content: center;
  }

  $list-padding: 6px;

  .damages-row {
    flex-shrink: 0;

    > div {
      padding-right: $list-padding;
      padding-left: $list-padding;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-right: $advanced-list-row-border;
      word-break: break-word;

      &:last-child {
        border: 0;
      }
    }
  }

  .advancedList_row {
    padding: 0;
  }

  .advancedList_data {
    .image-col {
      padding-right: 1px;
      padding-left: 0;
    }

    .before-image {
      padding-right: $list-padding * 2;
    }

    .after-image {
      padding-left: $list-padding * 2;
    }
  }

  .img-open {
    background: $color-white;
    padding: 10px;
    color: $color-black;
    font-size: 1.2em;
  }

  .no-images {
    text-align: center;
    font-size: 1.1em;
  }

  .image-preview {
    max-height: 110px;
    max-width: 100%;
    display: block;
  }

  .damages-date {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;

    > div {
      margin-bottom: 0px;

      &.action-button {
        margin-top: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
