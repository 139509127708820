.feedbacksPage {
  &_content {
    @include advanced-list-page-padding;
    font-family: $main-font;
    height: 100%;
  }

  &_title {
    @include pageTitle();
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_filters {
    margin-bottom: 15px;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }

  &_management {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &_listRow {
    padding: 10px 0;
    min-height: fit-content;
    height: 100%;
  }
}

.advancedList_actions {
  width: 100%;
}

.advancedList_header {
  min-height: fit-content;
}

.feedbacksPage_managementHeader {
  padding-bottom: 25px;
}

.feedbacks_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}

.feedbacksFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: $color-white;
  margin-bottom: 15px;
  padding: 25px 10px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .feedbacksPage {
    .advancedList_header {
      min-height: fit-content;
      flex-direction: row;
      flex-wrap: wrap;

      [class^='col-md'] {
        display: block;
        width: 50%;
        font-size: 2em;
        line-height: 2em;
      }
    }

    &_listRow {
      padding: 2px 0;
      min-height: fit-content;
      flex-direction: row;
      flex-wrap: wrap;

      [class^='col-md'] {
        display: block;
        width: 50%;
        font-size: 2em;
        line-height: 2em;
      }
    }
  }
}
