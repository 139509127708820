.extendShortenBookingForm_row {
  margin-bottom: 20px;
}

.extendShortenBookingForm_button {
  width: 100%;
}

.extendShortenBookingForm_title {
  text-align: center;
}

.extendShortenBookingForm_currentDateRow {
  margin-top: 25px;
}

.float-right {
  float: right;
}

.extendShortenBookingForm_customError {
  white-space: nowrap;
  width: 0;
}

.extendShortenBookingForm_currentDateRow_item {
  margin-right: 35%;
}

.extendShortenBookingForm_newDateRow_label {
  float: right;
  padding-top: 7%;
}

.float-left {
  float: left;
}

.extendShortenBookingForm_button_mt {
  margin-top: 15px;
}

.boxedInput_labelText_extendShortenBookingForm {
  font-size: 12px !important;
  display: block;
  color: #414546;
  padding-bottom: 5px;
  white-space: nowrap;
}

.fieldErrorMsg--extshortBooking {
  margin-top: 5px;
}

.extendShortenBookingForm_time {
  display: flex;

  .boxedSelectBlock {
    display: inline-block;
    vertical-align: top;

    &:first-child {
      margin-right: 8%;
    }
  }

  .boxedSelectWrapper {
    width: 100%;
  }
}

.extendShortenBookingForm_container {
  .formRow_item {
    display: flex;
  }
}
