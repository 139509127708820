/* scss-lint:disable QualifyingElement */
section.bankout {
  background-color: $color-white;
  margin-bottom: 5px;
  padding: 0 25px 15px;
  display: block;
  box-shadow: $advanced-list-container-shadow;

  .bankout-status {
    font-weight: bold;
  }

  .bankoutDetails {
    margin-right: 3em;
    width: 100%;

    h3 {
      button {
        position: absolute !important;
        right: 50px;
      }
    }
  }

  td {
    color: $color-text-dark;
    position: relative;

    span.json {
      word-break: break-all;
    }

    &.collapsed {
      height: 20px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    &.viewable {
      height: auto;
      overflow: visible;
      cursor: pointer;

      .more-details {
        position: relative;
        display: block;
      }
    }

    .more-details {
      position: absolute;
      top: 0;
      right: 0;
      background-color: white;
      padding: 0 5px;
      text-decoration: underline;
    }
  }

  tr > td:first-child {
    padding-right: 2em;
    font-weight: bold;
    width: 20%;
  }

  tr:not(last-child) > td {
    padding-bottom: 0.5em;
  }

  section {
    display: inline-block;
    min-width: 350px;
    vertical-align: top;
  }

  a {
    color: $color-text-dark2;
    display: flex;
    align-items: center;
  }

  $icon-size: 22px;

  .pdf-url:before {
    content: '';
    display: block;
    height: $icon-size;
    width: $icon-size;
    background-size: $icon-size $icon-size;
    // noinspection CssUnknownTarget
    background-image: url('/img/pdf.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
  }

  .modelForm_actionsButton {
    margin: 1.5em 0 0.5em;
  }

  a:hover {
    color: $color-black;
  }
}

@media (max-height: 780px) {
  section.bankout {
    display: flex;
    flex-direction: row;

    tr > td:first-child {
      width: 40%;
    }
  }
}

@media (max-width: 780px) {
  section.bankout {
    section {
      min-width: auto;
    }

    .bankoutDetails {
      margin-right: 5px;
    }
  }
}
