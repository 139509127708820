#booking-billing {
  &_headline {
    @include flex-valign-center;

    &_icon_container {
      @include remIt(margin-left, 15);
    }
  }

  [class^='payment_status'] {
    color: white;
    min-width: 90px;
    text-align: center;
    padding: 3px 0;
    font-size: 12px;
    display: inline-block;
  }

  .booking-tile {
    &_wrap {
      .full-row-info {
        width: 100%;
      }

      .value {
        .align-svg {
          height: 14px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    &_actions {
      height: 100px;
      align-items: end;
      gap: 5px;
    }
  }

  .payment_status {
    border: 1px solid;
    border-radius: 3px;

    &_paid {
      background-color: $color-completed;
      border-color: $color-completed;
    }

    &_unpaid {
      background-color: $color-error;
      border-color: $color-error;
    }

    &_partially {
      background-color: $color-scheduled;
      border-color: $color-scheduled;
      min-width: 110px;
    }
  }

  ul.bookingBilling_invoiceList {
    a {
      display: flex;
    }

    $icon-size: 22px;

    a:before {
      content: '';
      display: block;
      height: $icon-size;
      width: $icon-size;
      background-size: $icon-size $icon-size;
      // noinspection CssUnknownTarget
      background-image: url('/img/pdf.svg');
      background-repeat: no-repeat;
      margin-right: 5px;
    }

    a:hover {
      color: #000000;
    }
  }
}
