.vehicleCategoryPricingWidget {
  margin-top: 20px;
  @include box-shadow(0px 0px 5px 2px rgba($color-primary3-alt2, 0.5));
  background: rgba(0, 0, 0, 0.015);

  &._is_closed {
    @include box-shadow(0 0 0 0);
  }
}

.vehicleCategoryPricingWidget_header {
  background: $color-secondary-light;
  color: $color-white;
  height: 40px;
  width: 100%;
}

$vehicleCategoryPricingWidget_toggleHeight: 20;

.vehicleCategoryPricingWidget_toggle {
  @include basicClickable;
  @include remIt(width, $vehicleCategoryPricingWidget_toggleHeight);
  @include remIt(height, $vehicleCategoryPricingWidget_toggleHeight);
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  position: relative;
  text-indent: -9999px;

  &:after {
    @include remIt(font-size, $vehicleCategoryPricingWidget_toggleHeight);
    line-height: 20px;
    content: '-';
    position: absolute;
    text-indent: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &._is_closed:after {
    content: '+';
  }
}

.vehicleCategoryPricingWidget_content_section {
  margin-top: 30px;
  text-align: left;
  background-color: $color-white;

  .dash {
    background-color: rgba($color-primary3-alt2, 0.5);
    height: 1px;
    width: 100%;
    margin: 10px 0 20px;
  }
}

.vehicleCategoryPricingWidget_title {
  @include remIt(font-size, 14);
  margin: 0;
  cursor: pointer;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
}

.vehicleCategoryPricingWidget_content {
  border: solid 1px $color-primary-light;
  padding: 0 20px 20px;
}

.vehicleCategoryPricingWidget_section_title {
  @include remIt(font-size, 14);
  text-transform: uppercase;
  color: $color-primary-dark;
  float: left;
}

.container-submit-private-slots {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
