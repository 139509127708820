// scss-lint:disable SingleLinePerSelector
// ----------------------------------------

// old memberDetail below

$memberStatuses: (
  member-status: (
    'applied': $color-applied,
    'to_complete': $color-to-complete,
    'to_review': $color-to-review,
    'approved': $color-approved,
    'not_approved': $color-not-approved
  )
);

$statusColors: (
  state: (
    'PUBLISHED': $color-published,
    'UNPUBLISHED': $color-unpublished
  )
);

@mixin member_image_wrap {
  @include remIt(padding-bottom, 20);
  @include remIt(padding-right, 20);
  display: inline-block;
}

.memberDetail_customField_Image {
  @include remIt(margin-top, 10);
  max-height: 44%;
  max-width: 44%;

  .imageWrap_closed img {
    max-width: 100%;
    max-height: 100%;
  }

  .pdfWrap_placeholder {
    @include remIt(height, 200);
    @include remIt(width, 200);
  }
}

.memberDetailPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
    font-weight: bold;
    display: inline-block;
  }

  &_actions {
    @include flexbox();
    @include flex-wrap(wrap);
  }

  &_actionsButton {
    @include remIt(margin, 0 5 10 5);
    min-width: 165px;
  }

  &_approveButton {
    @include remIt(min-width, 250);
    @include remIt(margin-left, 15);
  }

  &_head {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
  }
}

.memberDetail {
  @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  background-color: $color-white;
  padding: 30px;
  margin-top: 20px;

  &_title {
    @include remIt(font-size, 13);
    text-transform: uppercase;
    font-weight: 200;
    margin-top: 0;
    text-align: left;
  }

  &_line {
    width: 100%;
    font-size: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &--alignRight {
      text-align: right;
    }
  }

  &_tableViewWrapper {
    width: 100%;
  }

  .tableView_cell {
    word-break: break-all;
  }

  &_item {
    @include remIt(font-size, 13);
    @include remIt(margin, 15 0);
    display: inline-block;
    width: 100%;
    word-break: break-all;

    &_label {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
    }

    &_result {
      margin-top: 15px;
      display: block;
      font-weight: 100;
    }
  }

  &_line--double &_item {
    width: 50%;
  }

  &_line--triple &_item,
  &_line--triple &_Picture {
    width: 33.3333333%;
    vertical-align: top;
  }

  &_line--quad &_item,
  &_line--quad &_Picture {
    width: 25%;
    vertical-align: top;
  }

  &_abort_button {
    float: left;
    width: 40%;
  }

  &_confirm_button {
    float: right;
    width: 40%;
  }

  &_openPdf {
    @include basicClickable;
    @include remIt(font-size, 13);
    display: inline-block;
    margin-top: 30px;
    color: $color-black;
    text-align: left;
    text-decoration: underline;
  }

  &_employerCertificate,
  &_drivingLicence {
    margin-top: 20px;
  }

  &_employerDocument,
  &_drivingDocument,
  &_identityDocument {
    vertical-align: top;
    display: inline-block;

    &_bloc_0 {
      width: 100%;
    }

    &_bloc_1 {
      width: 80%;
    }

    &_bloc_2 {
      width: 60%;
    }

    &_bloc_3 {
      width: 40%;
    }

    &_image {
      @include member_image_wrap;
      width: 100%;
      max-width: 20%;
    }

    &_pdf {
      @include member_image_wrap;
      width: 20%;
    }
  }
}

.memberDetail_employerDocument_image,
.memberDetail_drivingDocument_image,
.memberDetail_identityDocument_image {
  .imageWrap_closed img {
    max-width: 100%;
  }
}

.memberDetail_employerDocument_pdf,
.memberDetail_drivingDocument_pdf,
.memberDetail_identityDocument_pdf {
  .pdfWrap_placeholder {
    height: 200px;
  }
}

.contract_state,
.member_status {
  padding-left: 20px;
  position: relative;

  .memberDetailPage:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

.resetPasswordModal {
  @include remIt(width, 350);

  &_title {
    @include remIt(margin, 0 0 10 0);
  }

  .formRow_item {
    @include flex(auto);
    margin: auto;
  }

  .ekRadio_container {
    @include remIt(margin, 10 0 20 0);
    @include flexbox();
  }

  .ekRadio_wrapper {
    @include remIt(margin-right, 10);
  }

  &_confirm_button {
    width: auto;
    float: right;
  }
}

@each $state, $stateColor in map-get($statusColors, state) {
  .contract_state--#{$state} {
    color: $stateColor;

    &:before {
      background-color: $stateColor;
    }
  }
}

@each $status, $memberStatus in map-get($memberStatuses, member-status) {
  .member_status--#{$status} {
    color: $memberStatus;

    &:before {
      background-color: $memberStatus;
    }
  }
}

// -- New memberView --

.memberView_reviewButtonsContainer {
  margin-left: -1.19em;
  margin-top: 1em;
}

$imagePadding: 30;

.memberView_driverInfo {
  .detailView_cell_type_1 {
    white-space: nowrap;
  }
}

.memberView_driverInfo,
.memberView_certInfo,
.memberView_idCardInfo {
  .detailView_table:empty {
    display: none;
  }

  .detailView_table:first-child {
    @include remIt(margin-right, 60);
  }

  &_main {
    display: flex;
  }

  .fileImage {
    display: inline-block;
  }

  $fileMinWidth: 150;
  $fileMaxWidth: 250;

  &_files_1 {
    @include remIt(min-width, $fileMinWidth);
    @include remIt(max-width, $fileMaxWidth);

    .fileImage_type_loader,
    .fileImage_type_pdf {
      @include remIt(width, (250 + $imagePadding));
    }
  }

  &_files_2 {
    @include remIt(min-width, ($fileMinWidth * 2));
    @include remIt(max-width, ($fileMaxWidth * 2));

    .fileImage_type_image {
      max-width: 50%;
    }

    .fileImage_type_loader,
    .fileImage_type_pdf {
      @include remIt(width, (150 + $imagePadding));
      max-width: 50%;
    }
  }

  &_files_3 {
    @include remIt(min-width, ($fileMinWidth * 3));
    @include remIt(max-width, ($fileMaxWidth * 3));

    .fileImage_type_image {
      max-width: 33%;
    }

    .fileImage_type_loader,
    .fileImage_type_pdf {
      @include remIt(width, (150 + $imagePadding));
      max-width: 33%;
    }
  }

  .imageWrap_closed {
    @include remIt(padding-right, $imagePadding);

    img {
      @include remIt(max-height, 150);
      max-width: 100%;
      width: 100px;
    }
  }

  [class*='Info_files'] {
    display: flex;
  }

  .pdfWrap,
  .saving_wrap {
    @include remIt(padding-right, $imagePadding);
  }

  .pdfWrap_placeholder,
  .saving_placeholder {
    @include remIt(height, 150);
    width: 100%;
  }
}
