.support-page {
  height: 100%;

  .list-details {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    form > div {
      margin-bottom: 25px;
    }
  }

  .phone-numbers {
    display: flex;
    flex-direction: column;

    .hotline-entry {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 25px;

      .boxedInputWrapper {
        margin-right: 10px;
        width: 100%;
      }

      .white-text {
        color: white !important;
      }
    }
  }

  @media (max-width: 767px) {
    .phone-numbers {
      .hotline-entry {
        justify-content: space-between;
      }
    }
  }
}
