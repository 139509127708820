.list-modal {
  .siteDetailsPage {
    .pageContainer {
      padding: 0;

      .siteDetailsPage_content {
        padding: 30px 30px 0;

        .siteForm_container {
          margin-top: 0;
        }
      }
    }
  }
}
