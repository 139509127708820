.formSection {
  margin: 10px 0;

  &Fields {
    display: flex;
    flex-wrap: wrap;
  }

  &Title {
    margin-bottom: 10px;
  }
}
