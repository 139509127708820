$title-color: $color-dark-clear;
$title-margin: 0 15px;
$filters-height: 45px;
$main-font-size: 14px;

@mixin advanced-list-header-font() {
  color: $title-color;
  font-size: $main-font-size;
  font-weight: 600;
} // external usage

.container:last-child {
  border: 0;
}

.advancedList {
  .external-link-icon {
    top: 5px;
    right: 5px;
  }

  &_detailIcon {
    position: absolute;
    top: 0;
    right: 0;
    display: none;

    svg {
      fill: rgba(0, 0, 0, 0.55) !important;
    }
  }

  &_rowClickable:hover {
    text-decoration: underline;
  }

  &_filtersPopoverAnchor {
    color: $color-text-clear-dark;
    font-size: $main-font-size;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-between;
    cursor: pointer;

    svg {
      fill: rgba(0, 0, 0, 0.5) !important;
      margin-right: 15px;
    }
  }

  &_footer {
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    height: 45px;
    border-top: 1px solid $color-border-shadow2;

    &_actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;

      svg {
        fill: #757575 !important;
      }
    }

    &.advanced {
      flex-direction: row;
      align-items: center;
      font-size: $main-font-size;
      padding: $title-margin;
      color: $title-color;
    }
  }

  &_filtersForm {
    width: 100%;
  }

  &_filtersButton {
    color: white;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 60px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-secondary;
    cursor: pointer;
    transition: all 0.2s ease;

    &.disabled {
      background-color: grey;
      pointer-events: none;
    }

    svg {
      fill: currentColor !important;
      color: inherit !important;
    }
  }

  &_filtersInput {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &_filtersInput input {
    border: 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.7);
    display: block;
    height: 36px;
    padding-right: 15px;
    width: 100%;
    font-size: $main-font-size;

    &:focus {
      outline: none;
    }
  }

  &_filtersIcon {
    margin-bottom: -5px;
    margin-right: 12px;

    svg {
      fill: #757575 !important;
      width: 20px !important;
      height: 20px !important;
    }
  }

  &_rowWrap {
    position: relative;
  }

  &_rowWrap:last-child > .row {
    border: 0;
  }

  &_dropdownActions {
    display: flex;
    position: absolute;
    right: -11px;
    top: 3px;
    z-index: 100;
    justify-content: flex-end;
    width: 70px;
    height: 100%;

    svg {
      fill: #757575 !important;
    }
  }

  &_dropdownActions_closeOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 101;
  }

  &_chipsWrap {
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px auto 0;
    max-width: $advanced-list-max-width;
  }

  &_data {
    padding-right: 15px;
    padding-left: 15px;
    overflow-y: scroll;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  }

  &_data > .row:last-child {
    border: 0;
  }

  &_container {
    box-shadow: $advanced-list-container-shadow;
    background: $advanced-list-background;
    position: relative;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
  }

  &_wrap {
    max-width: $advanced-list-max-width;
    margin: 20px auto;
    width: 100%;
    display: flex;
    flex-shrink: 1;
    max-height: 100%;
    min-height: 0%; // IE (and firefox) fix | keep percentage: 0%
  }

  &_pagination {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &_header {
    @include advanced-list-header-font;
    background: $advanced-list-header-background;
    padding: $title-margin;
    display: flex;
    align-items: center;
    border-bottom: $advanced-list-header-border;
    height: $advanced-list-header-height;
    flex-shrink: 0;

    .single-item {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_sortIcon {
      height: 100%;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &:hover .sortIcon.sorted {
        svg {
          transition: all 0.3s ease !important;
          transform: rotate(180deg);
        }
      }

      &:not(:hover) .sortIcon.sorted {
        svg {
          transition: all 0.3s ease !important;
        }
      }

      .sortIcon {
        display: flex;
      }

      &:not(:hover) {
        .iconHoverable {
          svg {
            fill: none !important; // scss-lint:disable ImportantRule
          }
        }
      }

      .multiple {
        display: flex;
        flex-direction: column;
      }
    }

    &_sortIcon svg {
      min-width: 18px !important;
      min-height: 18px !important;
      width: 18px !important;
      height: 18px !important;
      margin-left: 10px;
    }

    &_sortIconHoverable {
      visibility: hidden;
    }
  }

  &_row {
    @include ieFlexMinHeight;
    color: $color-text-clear-dark-2;
    font-size: 13px;
    line-height: 16px;
    min-height: 5vh;
    border-bottom: $advanced-list-row-border;
    display: flex;
    align-items: center;
    padding: $title-margin;
    cursor: pointer;
    transition: background 0.3s ease;

    div {
      word-wrap: break-word;

      &.bw {
        word-wrap: normal;
      }
    }
  }

  &_row:hover {
    .advancedList_detailIcon {
      display: inline-flex;
    }
  }

  &_row:hover {
    background: #f5f5f5;
  }

  &_legacyActions {
    margin: 10px auto;
    display: flex;
    max-width: $advanced-list-max-width;

    &.page {
      width: 100%;
      margin-top: 15px;
    }

    button {
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_actions {
    flex-shrink: 0;
    margin: 10px auto;
    display: flex;
    justify-content: flex-start;
    max-width: $advanced-list-max-width;

    &.page {
      width: 100%;
      margin-top: 15px;
    }

    &.no-filters {
      height: $filters-height;
      margin-top: 35px;
      align-items: center;
    }

    button {
      color: $color-secondary !important;
    }

    button svg {
      fill: currentColor !important;
      color: inherit !important;
      margin-bottom: 3px;
    }

    button:disabled {
      color: $color-primary-dark !important;
      cursor: not-allowed !important;

      svg {
        fill: currentColor !important;
        color: inherit !important;
      }
    }
  }

  &_filters {
    display: flex;
    flex-shrink: 0;
    background: #fff;
    border-radius: 2px;
    width: 50%;
    height: $filters-height;
    align-items: center;
    padding-left: 25px;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
  }

  &_error {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50px !important;
      height: 50px !important;
      fill: red !important;
      margin-right: 15px;
    }

    span {
      color: red;
    }
  }

  &_noResults {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #9e9e9e;
      font-size: 1.1em;
      text-transform: uppercase;
    }
  }

  &_loaderWithData {
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 50px;
    left: 0;
    position: absolute;
    height: calc(100% - 50px) !important;
    z-index: 100;
  }

  &_loader {
    width: 100%;
    padding-top: 50px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .lds-rolling {
      position: relative;
    }

    .lds-rolling div,
    .lds-rolling div:after {
      width: 50px;
      height: 50px;
      border: 4px solid $color-secondary;
      border-top-color: transparent;
      border-radius: 50%;
    }

    .lds-rolling div {
      -webkit-animation: lds-rolling 1s linear infinite;
      animation: lds-rolling 1s linear infinite;
      top: 100px;
      left: 100px;
    }

    .lds-rolling div:after {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    .lds-rolling {
      -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
      transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }
  }
}

.advancedList_row {
  .action-button {
    cursor: pointer;
    background: $color-white;
    box-shadow: 0 0 4px 0 $color-border-shadow1;
    display: inline-flex;
    padding: 0 14px;
    min-height: 30px;
    align-items: center;
    user-select: none;
    border-radius: 3px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    span {
      display: flex;
    }
  }

  .action-button:active {
    cursor: pointer;
    background: $color-primary-light;
    box-shadow: 0 0 4px 0 $color-border-shadow1;
    color: $color-text-clear-dark;
    transition: background-color 0.1s ease, box-shadow 0.3s ease;
  }
}

.width0 {
  width: 0 !important;
  opacity: 0;
}

.firstLetterUppercase:first-letter {
  text-transform: uppercase;
}
