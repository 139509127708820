.parkingFormWrapper {
  margin-bottom: 40px;
}

.parkingForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 30px;
  }

  &_title {
    @include remIt(font-size, 13);
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &_row {
    margin-bottom: 25px;
  }

  &_rowAlt {
    margin-bottom: 10px;
  }

  &_marginTimeBox {
    @include remIt(margin-left, 50);
  }

  &_coordinatesLabel {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(row-reverse);
    @include justify-content(flex-end);
  }

  &_coordinatesLabelText {
    margin-left: 10px;
    text-transform: lowercase;
  }

  &_ekRadioTitle {
    @include remIt(font-size, 12);
    display: inline-block;
    width: 50%;
    padding-right: 10px;
    text-transform: inherit;
  }

  &_ekRadioContainer {
    display: inline-block;
  }

  &_ekRadioWrapper {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &_actions {
    margin-top: 20px;
    padding-bottom: 30px;
    display: flex;
    justify-content: end;

    button:first-child {
      margin-right: 20px;
    }
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    margin-right: 20px;
  }

  &_timeSlots_group {
    display: inline-block;
    width: 100%;
  }

  &_timeSlots_item {
    padding: 10px 15px 20px 20px;
    border: solid 1px #dddddd;
    display: table;
    width: 100%;
  }

  &_addTime_button {
    @include remIt(min-width, 150);
    @include remIt(margin-top, 25);
    @include remIt(margin-left, 10);
    float: right;
  }

  &_addTimeSlot_button {
    @include remIt(min-width, 218);
    @include remIt(margin-bottom, 10);
    float: right;
  }

  &_timeSlots {
    clear: both;
    text-align: left;
  }

  &_timeSlots_item_content {
    @include table-display();
  }

  &_timeSlots_item_actionButton {
    @include table-cell();
    @include remIt(width, 20);
  }

  &_subTimeSlots {
    @include remIt(margin-top, 20);
  }

  &_timeSlots_subGroup {
    @include remIt(width, 510);
  }

  &_labeledCheckbox {
    padding: 5px 0;
  }

  &_optionItems {
    @include inline_flex;
    width: 100%;
  }

  &_dayItem {
    @include remIt(margin-right, 10);
  }

  &_weekly_title {
    @include remIt(font-size, 11);
    text-transform: uppercase;
  }

  &_timeSlots_item_removeButton {
    @include basicClickable;
    @include remIt(width, 20);
    @include remIt(height, 20);
    @include remIt(margin-top, 20);
    overflow: hidden;
    text-indent: -9999px;
    position: relative;
    line-height: 1;

    &:after {
      @include remIt(font-size, 20);
      text-indent: 0;
      content: 'X';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.fieldErrorMsg--parkingForm {
  margin-top: 10px;
}

.v-top {
  vertical-align: top;
}

.parkingTimeSlot {
  display: inline-block;

  &_dateWrapper {
    @include remIt(margin-right, 10);
    vertical-align: top;
    width: 20%;
  }

  &_CommentWrapper {
    @include remIt(margin-right, 10);
    vertical-align: top;
    width: 55%;
  }

  &_from_label {
    vertical-align: top;
    width: 100%;
  }
}
