.searchVehicle {
  &_tripSelection {
    margin: 0 0 20px 0;
    text-align: center;
  }

  .ekRadio_wrapper {
    display: inline-block;
    margin: 0 20px;
  }

  .ekButton.submit {
    padding: 12px 10px;
  }

  &_boxedInput,
  &_boxedSelectWrapper {
    background-color: rgba($color-white, 0.9);
    width: 100%;
  }

  .boxedInput_labelText,
  .boxedSelect_label {
    text-transform: uppercase;
  }

  .fieldErrorMsg--searchVehicleForm {
    margin-top: 10px;
  }

  ._is_hidden {
    display: block;
    visibility: hidden;
  }

  .boxedSelect {
    padding-left: 5px;
  }

  .formRow_item {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }

  .boxedSelectBlock {
    position: relative;
  }

  .boxedInput--date input {
    padding: 0 0 0 40px !important;
  }

  &_formRow {
    margin-top: 15px;

    .formRow_item:first-child {
      @include flex(5);
    }

    .formRow_item:nth-child(2) {
      @include flex(2);
    }

    .formRow_item:nth-child(3),
    .formRow_item:nth-child(4) {
      margin-left: 10px;
    }

    &:last-child {
      height: 90px;
    }
  }
}

.searchVehicle_col {
  display: inline-block;

  &--left {
    width: 75%;
  }

  &--right {
    padding-left: 15px;
    width: 25%;

    .formRow {
      height: 90px;
    }

    .formRow_item:last-child {
      margin-top: 20px;
    }
  }
}

.searchVehicle_AdressAutoCompleteDisabled {
  @include remIt(font-size, 12);
  @include remIt(line-height, 15);
  border: 1px solid $color-primary3-alt;
  width: 100%;
  height: 40px;
  padding: 10px;
  color: $color-primary-middle;
  background-color: $color-primary-lightest;
}

.searchVehicle ._is_favorite {
  .geosuggest-item:first-child:after {
    @include remIt(font-size, 18);
    content: $icon-starFull;
    font-family: $icons-font;
    color: $color-secondary;
    float: right;
  }
}
