.address-autocomplete {
  .mapbox-complete {
    font-size: 0.8rem;
  }

  &.country-mode {
    .mc-header {
      display: none;
    }

    .mc-suggestion {
      cursor: pointer;
      height: 2em;
      line-height: 1.2em;
      padding-right: 0.5em;
      padding-left: 0.5em;
      display: flex;
      align-items: center;

      .mc-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .mc-suggestion-icon {
      display: none;
    }

    .mapbox-complete .mc-dropdown-menu {
      min-width: auto;
    }
  }

  input {
    @extend .boxedInput;
  }
}

.geosuggest {
  position: relative;
}

.geosuggest__input {
  @include remIt(font-size, 12);
  @include remIt(line-height, 15);
  background-color: $color-white;
  border: 1px solid $color-primary3-alt;
  width: 100%;
  height: 40px;
  padding: 10px;
  color: $color-primary-darkest;
  transition: 0.2s ease color;

  @include placeholder() {
    color: $color-primary-darkest;
    opacity: 0.5;
  }
}
