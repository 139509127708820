.support-page {
  .tabs-rci {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0;

    .list-wrap {
      padding-left: 0;
      padding-right: 0;
    }

    &.oneTab .list-wrap {
      padding-top: 0;
    }

    > .tabs-content {
      height: 100%;
      overflow: hidden;
    }

    > .tab-list {
      @include listWrapProps;

      margin: 0;
      padding: 0 10px;
      background: white;
      height: auto;

      .tab-list-item {
        display: inline-block;
        list-style: none;
        cursor: pointer;

        .item-tab {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem 0.75rem 0;

          hr {
            background: white;
            border: 0;
          }
        }

        &.tab-list-active {
          font-weight: bold;

          .item-tab {
            position: relative;
            padding: 1rem 0.75rem;

            hr {
              width: 60%;
              height: 3px;
              top: calc(3rem - 3px);
              position: absolute;
            }
          }
        }
      }
    }
  }
}
