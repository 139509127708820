/* _____ MIXINS _____ */

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin baseTitle() {
  font-family: $main-font;
  @include remIt(font-size, 18);
  text-transform: uppercase;
}

@mixin table-display() {
  display: table;
  width: 100%;
}

@mixin table-cell($align: middle) {
  display: table-cell;
  vertical-align: $align;
}

@mixin toggle($color) {
  &:after {
    @include cssTriangle(5px, $color, right);
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    transition: 0.3s all ease;
  }

  &--opened:after,
  &._is_opened:after {
    transform: rotate(-90deg);
    vertical-align: top;
    margin-left: 8px;
  }

  &._is_opened:after {
    transform: rotate(-90deg);
  }
}

@mixin buttonColor($color1, $color2, $reverse: 'noreverse') {
  @if ($reverse == 'noreverse') {
    background-color: $color1;
    border: 1px solid $color1;
    color: $color2;
  }

  @if ($reverse == 'reverse') {
    background-color: $color2;
    border: 1px solid $color1;
    color: $color1;
  }
}

@mixin pellet() {
  @include remIt(font-size, 14);
  @include border-radius(20px);
  min-width: 20px;
  padding: 0 5px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: $color-secondary-light;
  color: $color-white;

  &--variant1 {
    background-color: $color-primary-darkest;
  }

  &--variant2 {
    background-color: $color-tertiary;
  }

  &--variant3 {
    background-color: $color-good-secondary;
  }

  &--reverse {
    background-color: transparent;
    color: $color-secondary-light;
    border: 1px solid $color-secondary-light;
  }

  &--variant1Reverse {
    background-color: transparent;
    color: $color-primary-darkest;
    border: 1px solid $color-primary-darkest;
  }

  &--text {
    @include remIt(font-size, 22);
    @include remIt(line-height, 32);
    font-weight: 700;
  }
}

@mixin pageTitle() {
  @include remIt(font-size, 13);
  @include remIt(line-height, 16);
  font-weight: bold;
  color: $color-primary-darkest;
  text-transform: uppercase;
  margin-top: 10px;
}

// copied from library
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin advanced-list-page-padding {
  padding: 10px 25px 20px;
}

@mixin ieFlexMinHeight($height: inherit) {
  :after {
    content: '';
    min-height: $height;
    font-size: 0;
  }
}

@mixin newTabIconIcon() {
  $padding-arrow: 5px;
  $padding-square: 8px;
  $icon-size: 20px;

  .external-link-icon {
    transition-delay: 0s;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    position: absolute;
    top: -15px;
    right: -15px;
    background: $color-white;
    border-radius: 50%;
    padding: $padding-arrow $padding-arrow $padding-square $padding-square;
    box-shadow: 0 9px 20px -6px $color-border-shadow6;
    pointer-events: none;

    svg {
      margin: 2px;
      height: $icon-size;
      width: $icon-size;
      vertical-align: middle;
    }
  }

  &:hover {
    .external-link-icon {
      pointer-events: all;
      opacity: 1;

      &:hover {
        cursor: alias;
      }
    }
  }
}

@function getImgUrl($img-name) {
  @return url('#{$img-path}#{$img-name}');
}

@function getFontPath($base-name) {
  @return $fonts-path + $base-name;
}
