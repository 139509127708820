.addInvoicePage {
  &_content {
    font-family: $main-font;
    @include remIt(padding, 10 25 0);
  }

  &_title {
    @include pageTitle();
  }

  &_managementHeader {
    @include remIt(padding-bottom, 25);
  }

  .noResultMessage {
    @include remIt(margin-bottom, 15);
    @include remIt(padding, 25 10);
    background-color: #fff;
    text-align: center;
  }
}
