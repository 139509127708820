#transactions-booking,
#booking-billing {
  &.booking-tile {
    .booking-tile_wrap {
      table {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;

        &.expandable {
          display: block;
          height: 90px;
          overflow: hidden;
        }

        thead {
          tr {
            background-color: white;
          }
          tr > th {
            color: grey;
            font-weight: normal;
            text-align: left;
          }
        }

        tr {
          background-color: #f4f4f4;
          border: 2px solid white;

          td {
            font-size: 14px;
            color: black;
            vertical-align: middle;
            padding: 5px;

            &:nth-of-type(1) {
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }

            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              border-left: 1px solid white;
            }

            &:nth-of-type(3),
            &:nth-of-type(4) {
              text-align: center;
            }

            &.desc {
              width: 30%;
              font-size: 13px;
              border-left: 1px solid white;
              font-style: italic;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
              &_wide {
                width: 60%;
                word-break: break-all;
              }
            }
            // &.date {
            //   width: 20%;
            // }

            span {
              & [class^='status'] {
                color: white;
                font-weight: normal;
                border-radius: 3px;
                display: block;
              }
              &.status-green {
                background-color: $color-completed;
              }

              &.status-red {
                background-color: $color-error;
              }

              &.status-orange {
                background-color: $color-scheduled;
              }
            }
          }
        }
      }
    }

    .booking-tile_actions {
      margin-top: 20px;
    }
    .refusal-tooltip {
      width: 14px;
      display: inline-flex;
      height: 14px;
      border-radius: 16px;
      line-height: 16px;
      color: $color-white;
      margin-left: 5px;
      padding: 10px;
      background-color: $color-black;
      justify-content: center;
      align-items: center;
    }
  }
}
