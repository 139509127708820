.contractForm {
  @include clearfix;
}

.contractForm_title {
  @include pageTitle();
}

.contractForm_row {
  margin-bottom: 25px;
}

.contractForm_row_options {
  margin-bottom: 25px;

  .formRow_item {
    .contractForm_definitionList {
      display: inline-block;
      margin: 10px 50px 0 0;

      label {
        width: 100% !important;
      }
    }
  }
}

.contractForm_definitionList_term {
  @include remIt(font-size, 11);
  display: block;
  text-transform: uppercase;
}

.contractForm_definitionList_definition {
  @include remIt(font-size, 12);
  padding: 0;
  margin: 10px 0 0;
}

.contractForm_option {
  float: left;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }
}

.ekButton.contractForm_actionsButton {
  min-width: 218px;
  float: right;
  margin-top: 20px;
}

.fieldErrorMsg--contractForm {
  margin-top: 10px;
}
