.ekTextarea {
  @include remIt(font-size, 12);
  width: 100%;
  padding: 10px;
  border: 1px solid $color-primary-light;

  @include placeholder() {
    @include remIt(font-size, 14);
    font-weight: $light-weight;
  }

  &_labelText {
    @include remIt(font-size, 11);
    display: block;
    color: $color-primary-darkest;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
}
