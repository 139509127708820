.hotlineFormWrapper {
  margin-bottom: 40px;
}

.hotlineForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 20px;
  }

  &_title {
    @include remIt(font-size, 11);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &_actions {
    text-align: right;
    margin-top: 45px;

    > *:first-child {
      margin-right: 20px;
    }
  }

  &_row {
    margin-bottom: 25px;
  }

  &_row--phoneNumbers {
    @include flex-basis(100%);
    margin-left: 0;
    max-width: 55%;
    min-width: 51%;

    .boxedInputWrapper {
      @include remIt(margin, 0 20 0 0);
    }

    .hotline-entry {
      display: inline-flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: flex-start;
      justify-content: space-between;

      .boxedInputWrapper {
        max-width: 55%;
        min-width: 51%;

        &.extra {
          padding-top: 20px;
        }
      }

      .ekButton {
        &.extra {
          margin-top: 20px;
        }
      }
    }
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    margin-right: 20px;
  }
}

.fieldErrorMsg--hotlineForm {
  margin-top: 10px;
}
