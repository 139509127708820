$paid-color: #24b282;
$unpaid-color: #fe5001;
$retry-color: $color-to-review;

$tableView: (
  tableView-state: (
    'PAID': $paid-color,
    'UNPAID': $unpaid-color
  )
);

.invoicesList {
  @include advanced-list-page-padding;
  display: flex;
  flex-direction: column;
  height: 100%;

  .advancedList_detailIcon {
    position: static;
  }

  &_header {
    width: 100%;
    display: flex;
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}

.invoice-ref,
.price-detail,
.vat-detail,
.member-detail {
  span {
    display: block;
  }
}

.status {
  a {
    text-decoration: none;
    display: block;
  }

  .paid,
  &.paid {
    color: $paid-color;
  }

  .unpaid,
  &.unpaid {
    color: $unpaid-color;
  }
}

.invoicesPage {
  &_management {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_content {
    font-family: $main-font;
    @include advanced-list-page-padding;
    height: 100%;

    .advancedList_row {
      cursor: auto !important;
      height: 60px;
    }
  }

  .advancedList_rowWrap {
    .retry-payment {
      .initial {
        display: block;
      }

      .unpaid,
      &.unpaid {
        color: $retry-color;
      }

      .retry {
        display: none;
      }

      &:hover {
        cursor: pointer;

        .initial {
          display: none;
        }

        .retry {
          display: block;
        }
      }
    }
  }

  &_title {
    @include pageTitle();
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_filters {
    max-width: $advanced-list-max-width;
    margin: 0 auto;
    width: 100%;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }
}

.tippy-tooltip-content {
  .tooltip-content {
    span {
      display: block;
    }
  }
}

.invoicesPage_managementHeader {
  @include remIt(padding-bottom, 25);
}

.invoices_tableView_action {
  @include basicClickable;
  text-decoration: underline;
  color: $color-secondary-dark;
}

.invoicesFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
}
