.siteFormWrapper {
  margin-bottom: 40px;
}

.siteForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 20px;
  }

  &_title {
    @include remIt(font-size, 13);
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
  }

  &_row {
    margin-bottom: 35px;
  }

  &_ekRadioTitle {
    display: inline-block;
    width: 150px;
    padding: 0 10px 0 0;
    text-align: right;
  }

  &_ekRadioContainer {
    display: inline-block;
  }

  &_ekRadioWrapper {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &_actions {
    margin-top: 20px;
    padding-bottom: 30px;
    display: flex;
    justify-content: end;

    button:first-child {
      margin-right: 20px;
    }
  }

  &_boxedSelectWrapper {
    width: 100%;
  }
}

.siteForm_actionsButton {
  vertical-align: middle;
  min-width: 218px;
}

.siteForm_actionsLink {
  margin-right: 20px;
}

.siteForm .formRow {
  @include align-items(flex-end);
}

.siteForm_parameters {
  justify-content: space-between;

  .formRow_item {
    flex: none;
    margin: 0 0 45px;
  }

  .ekRadio_block {
    @include flexbox();
    @include align-items(center);

    position: relative;

    .fieldErrorMsg--siteForm {
      bottom: -20px;
    }
  }

  .boxedInput {
    &_label {
      @include flexbox();
      @include align-items(center);
    }

    &_labelText {
      display: inline-block;
      width: 150px;
      padding: 0 10px 0 0;
      text-align: right;
    }

    &_inputText {
      display: inline-block;
      width: 100px;
    }
  }

  .fieldErrorMsg--siteForm {
    right: 0;
  }
}

.siteForm-override-params {
  justify-content: unset;

  &.formRow {
    align-items: flex-start;
  }

  .formRow_item {
    margin-left: 0;
    margin-right: 25px;
    margin-bottom: 25px;
    flex: inherit;

    > .ekRadio_block {
      display: flex;
      flex-direction: column;
    }

    .siteForm_ekRadioTitle {
      margin-bottom: 5px;
      width: 200px;
      text-align: left;
      display: block;
    }

    .override-toggle {
      width: 300px !important;

      div > label {
        @include remIt(font-size, 11, $after: ' !important');
        text-transform: uppercase;
        width: 50% !important;
        margin-bottom: 20px;
      }
    }
  }

  .info {
    color: #212121;
    width: 150px;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
  }
}

.fieldErrorMsg--siteForm {
  position: absolute;
  margin-top: 10px;
}
