.upload-member {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    margin: 1rem 0;
  }

  &-tuto {
    font-size: smaller;
    display: flex;
    border: 2px solid $color-border-shadow1;
    border-radius: 5px;
    padding: 15px;
    background-color: $color-bg-super-light;

    &-col {
      flex-basis: 50%;
    }

    &-row {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-info {
      font-size: smaller;
      margin-top: 5px;
      color: $color-text-dark2;
    }
  }

  @function hr-gradient($direction) {
    @return linear-gradient(
      $direction,
      $color-transparent,
      $color-border-shadow4,
      $color-border-shadow4,
      $color-border-shadow4,
      $color-transparent
    );
  }

  &-vr {
    border-right: 1px solid;
    border-image: hr-gradient(to top) 1;
    margin: 0 20px;
    flex: 1 0;
  }

  &-hr {
    background-image: hr-gradient(to right);
    width: 120px;
    margin: 60px auto 30px;
    height: 1px;
  }

  &-box {
    background-color: $color-white;
    max-width: 670px;
    min-width: 585px;
    border: 1px solid $color-border-shadow1;
    padding: 15px 50px 40px;

    .members-rejected-box {
      margin-top: 20px;

      span {
        display: block;
        margin-bottom: 10px;
      }
    }

    .resume-figures {
      span {
        display: block;
      }
    }

    .rejected-members-list {
      max-height: 250px;
      overflow-x: scroll;

      div {
        &.error {
          border-top: 1px solid $color-border-shadow1;
          border-left: 1px solid $color-border-shadow1;
          border-right: 1px solid $color-border-shadow1;
          border-bottom: 0;
          padding: 5px;

          &:first-child {
            border: 1px solid $color-border-shadow1;
            border-radius: 5px 5px 0 0;
            border-bottom: 0;
          }

          &:last-child {
            border: 1px solid $color-border-shadow1;
            border-radius: 0 0 5px 5px;
          }
        }
      }
    }

    .import-retry {
      margin-top: 20px;
      text-align: center;
    }
  }

  &-add-input {
    display: none;
  }

  &-dropzone {
    min-height: 125px;
    border: 2px dashed $color-border-shadow3;
    border-radius: 10px;
    outline: none;
    position: relative;

    &-inner {
      text-align: center;
      position: absolute;
      bottom: -16px;
      left: 0;
      right: 0;
    }

    &-active {
      border-color: $color-text-dark;
      background-color: $color-bg-super-light;
    }
  }
}
