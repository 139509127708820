div#vehicle-position.booking-tile {
  .booking-tile {
    &_map {
      height: 120px;
    }
    &_wrap {
      padding-top: 10px;
      > .booking-tile_wrap {
        padding-top: 0;
        width: 100%;
      }

      .row-info .value a {
        display: flex;
        align-items: center;

        > svg {
          display: none !important;
          position: relative;
          left: -10px;
        }
        &:hover {
          animation: all 0.2s;
          > svg {
            left: 10px;
            display: inline-flex !important;
          }
        }
      }
    }

    &_actions {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }

  // map Marker
  $diamInner: 40px;
  $diamOutter: 66px;
  .shapeDot {
    width: $diamInner;
    height: $diamInner;
    border: 1px solid transparent;
    border-radius: $diamInner;
    background-color: #ff3e00;
    position: relative;

    &::before {
      content: ' ';
      position: absolute;
      width: $diamOutter;
      height: $diamOutter;
      top: (-($diamOutter - $diamInner) / 2) - 1px;
      left: (-($diamOutter - $diamInner) / 2) - 1px;
      border-radius: $diamOutter;
      background-color: rgba(#ff3e00, 0.5);
    }
  }
}
