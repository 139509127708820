.dashboardPage {
  display: block;
  width: 100%;

  .pageContainer {
    display: block;
  }

  &_management {
    display: flex;
    justify-content: center;
  }

  &_list {
    display: flex;
    align-items: center;
  }

  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  .dashboardItems {
    font-family: $main-font;
    width: 100%;
    overflow: hidden;
    margin: 20px 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .dashboardItem {
      svg#lowFuel {
        & > * {
          color: black;
        }
        #needle {
          color: $color-main;
        }
      }
    }
  }
}
