.voucher-group-filters {
  .sc-title {
    margin-bottom: 15px;
  }

  .sc-items {
    display: flex;

    > *:not(:last-child) {
      margin-right: 15px;
    }

    .group-name {
      width: 40%;
    }
  }

  .sc-block:not(:last-child) {
    margin-bottom: 30px;
  }

  .boxedInput--date > div > div:first-child {
    left: 12px !important;
    color: #333 !important;
  }

  .sc-type-button {
    $icon-size: 20px;

    color: #333;
    display: flex;
    background: white;
    border-radius: 4px;
    padding: 5px 10px;
    align-items: center;
    border: 1px solid $color-list-selected-item;

    &-icon-wrap {
      display: flex;
      margin-right: 10px;

      svg {
        color: $color-list-selected-item !important;
        height: $icon-size !important;
        width: $icon-size !important;
      }
    }

    &.sc-active {
      background: $color-list-active-item;
      border-color: $color-list-active-item;
      color: white;

      svg {
        color: white !important;
      }

      &:hover {
        background: $color-list-active-item;
        border-color: $color-list-active-item;
      }
    }

    &:hover {
      background: $color-list-selected-item;
      border-color: $color-list-selected-item;

      svg {
        color: white !important;
      }
    }
  }
}
