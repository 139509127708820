.addBookingPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  &_managementHeader {
    padding-bottom: 25px;
  }

  .noResultMessage {
    background-color: #fff;
    margin-bottom: 15px;
    padding: 25px 10px;
    text-align: center;
  }
}
