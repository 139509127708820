.createInvoice {
  &_wrapper {
    @include remIt(margin-bottom, 15);
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    @include remIt(padding, 10 20);
    background-color: $color-white;

    .bookingDetail {
      @include remIt(margin-bottom, 15);
      display: inline-flex;
      width: 100%;

      .grid-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 30% 70%;
        -ms-grid-rows: 100px;
        grid-template-columns: 30% 70%;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        .item {
          padding: 20px;
          margin: 10px;

          &.r1c1 {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
          }

          &.r1c2 {
            -ms-grid-row: 1;
            -ms-grid-column: 2;
          }

          &.r2c1 {
            -ms-grid-row: 2;
            -ms-grid-column: 1;
          }

          &.r2c2 {
            -ms-grid-row: 2;
            -ms-grid-column: 2;
          }

          &.r3c1 {
            -ms-grid-row: 3;
            -ms-grid-column: 1;
          }

          &.r3c2 {
            -ms-grid-row: 3;
            -ms-grid-column: 2;
          }
        }
      }
    }
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }

  .tableView_cell,
  .tableView_headCell {
    text-align: center;
  }
}

// bookingView invoice container
.createInvoiceForm_container {
  ul {
    margin: 0 20px;

    li {
      padding: 2px;
      margin: 1px;
      border-radius: 5px;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba($color-grey-light4, 0.2);
        box-shadow: 0 0 5px 0 rgba($color-black, 0.4);
      }

      span {
        display: inline-block;
        font-weight: normal;
        font-size: 0.8em;

        &.desc {
          width: 60%;
        }

        &.quantity-unit {
          width: 20%;
        }

        &.price {
          width: 15%;
          text-align: right;
        }

        &.delete-item {
          width: 5%;
          text-align: right;
          vertical-align: middle;
        }
      }

      &.header-invoice {
        span {
          font-weight: bold;
          font-size: 0.7em;
        }

        &:hover {
          background-color: $color-white;
          box-shadow: none;
        }
      }

      &.footer-invoice {
        &:hover {
          background-color: $color-white;
          box-shadow: none;
        }

        span {
          font-weight: bold;
          font-size: 0.8em;

          &.amount {
            width: 10%;
            text-align: right;
          }

          &.desc {
            width: 85%;
          }
        }
      }
    }
  }
}
