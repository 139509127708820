.companyInvoiceForm {
  margin-top: 15px;

  .fieldErrorMsg--invoiceParams {
    margin-top: 5px;
    position: relative;
    right: 0;
  }

  .companyForm_row {
    margin-bottom: 30px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    .button {
      max-width: 200px;
      width: 100%;

      $m-margin: 15px;

      &:first-child {
        margin-right: $m-margin;
        margin-left: 0;
      }

      &:last-child {
        margin-left: $m-margin;
        margin-right: 0;
      }
    }
  }

  .invoice-params {
    .boxedSelectWrapper {
      display: block;
    }

    .credential-status {
      @include remIt(margin-left, 10);
      @include remIt(font-size, 15);
      display: flex;
      align-items: flex-start;

      .credential-icon {
        @include remIt(margin-right, 10);

        .circle-outline {
          @include addIcon($icon-checkCircleOutline, $size: 23px, $parentSize: 24px);
        }
      }

      .edit-credentials {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .dual {
      display: flex;

      .ekRadio_block {
        display: inline-flex;
        flex-direction: column;
      }

      $right-margin: 10px;

      .boxedInputWrapper,
      .ekRadio_block,
      .boxedSelectBlock,
      .auto {
        vertical-align: top;
        margin-right: $right-margin;
        width: calc(50% - #{$right-margin});

        .ekRadio_container {
          flex-grow: 1;
          display: flex;
          align-items: center;
          padding-left: 7px;
        }

        .companyForm_ekRadioWrapper {
          margin-top: 0;
        }

        &:last-child {
          margin-right: 0;
          width: 50%;
        }
      }
    }

    .fourth {
      display: flex;
      justify-content: space-between;
      margin-top: -5px;

      .boxedInput_label {
        display: inline-flex;
        flex-direction: column;
      }
    }
  }
}
