.vehiclesCategoryFormWrapper {
  margin-bottom: 40px;
}

.vehiclesCategoryForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 20px;
  }

  &_title {
    @include remIt(font-size, 11);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    margin-right: 20px;
  }

  &_row {
    margin-bottom: 20px;
  }
}

.fieldErrorMsg--vehiclesCategoryForm {
  margin-top: 10px;
}
