.bookingFixIntErrForm {
  &_row {
    @include remIt(margin-bottom, 25);
  }

  &_button {
    width: 70%;
  }

  &_message {
    @include remIt(margin-bottom, 15);
    @include remIt(font-size, 15);
  }

  &_label {
    float: right;
    padding-top: 10%;
  }
}
