._vouchers._groups {
  &._add {
    .list-details > .sc-main-wrap {
      padding: 0 10px;
    }

    .sc-title-wrap {
      margin-bottom: 15px;
    }
  }

  &._edit {
    .sc-title-wrap {
      margin-bottom: 0;
    }
  }
}

.vouchers-v2 {
  .auto-complete {
    display: flex;
  }

  .voucherGroupDetail {
    padding: 0;

    > .container > .row > div {
      margin-bottom: 5px;
    }

    &_actions {
      margin: 0;
    }

    > .container {
      padding: 0;
      margin: 10px 0 0;
    }
  }

  .column-list {
    .sc-list-item {
      display: block;

      .sc-name-wrap {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .pageContainerCentered {
    padding-top: 0;
  }

  .addVoucherGroupForm_actions {
    margin-bottom: 0;
  }

  .page-info-block {
    box-shadow: none;
  }

  .addVoucherGroupForm {
    &_container {
      padding: 5px;
    }

    &_wrap {
      @media (max-width: 1400px) {
        flex-direction: column;
      }
    }

    &_col1 {
      @media (max-width: 1400px) {
        width: 100%;
      }
    }

    &_col2 {
      @media (max-width: 1400px) {
        width: 100%;
      }
    }
  }
}

@media (min-width: 768px) {
  .vouchers-v2 {
    .column-list {
      width: 350px;
    }
  }
}
