.filter-chip {
  .text {
    display: flex;
    white-space: pre;
  }
}

.v2-filter-chip {
  border-radius: 4px !important;
  background: #eaeaea !important;
  padding: 5px 10px !important;
  align-items: center;

  > span {
    padding: 0 10px 0 0 !important;
    line-height: normal !important;
  }

  .text {
    font-size: 13px;

    .value {
      font-weight: bold;
      color: #555;
    }
  }

  svg {
    $size: 11px;

    opacity: 0.4;
    background-size: $size;
    background-image: url('/img/close-2.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 !important;
    fill: red !important;
    width: $size !important;
    height: $size !important;

    path {
      display: none;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
