$vehicleDamageStatusColor: (
  color-active: #17b7c4,
  color-repaired: #24b282,
  color-duplicated: #ffa000,
  color-wontrepair: #c42015
);

.vehicleDamageDetailPage {
  &_head {
    display: flex;
    justify-content: flex-end;
  }

  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
    font-weight: bold;
    display: inline-block;
  }

  &_actionButton {
    float: right;
    width: 200px;
  }
}

.vehicleDamageDetail {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: $color-white;
  padding: 30px;
  margin-top: 20px;

  &_title {
    @include remIt(font-size, 13);
    text-transform: uppercase;
    font-weight: 200;
    margin-top: 0;
  }

  &_line {
    @include flexbox();
    margin: 30px 0;
  }

  &_item {
    @include flex(1);
    @include remIt(font-size, 13);

    &_label {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
    }

    &_result {
      margin-top: 15px;
      display: block;
      font-weight: 100;
    }

    &--damagePicture {
      max-width: 250px;
      margin-right: 45px;
    }

    &_link {
      color: $color-primary-darkest;
      text-decoration: underline;
    }
  }

  &_line--triple &_item {
    @include flex(none);
    width: 33.3333333%;
  }

  &_damagePicture {
    margin: 0;

    &_img {
      max-width: 100%;
    }
  }

  &_actionsButton {
    min-width: 218px;
    float: right;
    margin-top: 15px;
  }
}

.vehicle_damage_detail_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}

.vehicleDamageDetail_tableViewWrapper .tableView_cell {
  width: 50%;
}

.vehicleDamageDetail_statusTypeCell {
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
  }

  &--ACTIVE {
    color: map-get($vehicleDamageStatusColor, color-active);

    &:before {
      background-color: map-get($vehicleDamageStatusColor, color-active);
    }
  }

  &--REPAIRED {
    color: map-get($vehicleDamageStatusColor, color-repaired);

    &:before {
      background-color: map-get($vehicleDamageStatusColor, color-repaired);
    }
  }

  &--DUPLICATED {
    color: map-get($vehicleDamageStatusColor, color-duplicated);

    &:before {
      background-color: map-get($vehicleDamageStatusColor, color-duplicated);
    }
  }

  &--WONT_REPAIR {
    color: map-get($vehicleDamageStatusColor, color-wontrepair);

    &:before {
      background-color: map-get($vehicleDamageStatusColor, color-wontrepair);
    }
  }
}
