.booking-start-form {
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .start-booking {
      &.error {
        > button#submit-start-booking {
          background-color: white !important;
          span {
            color: red !important;
          }
        }
      }
    }
  }
}
