$paid-color: #24b282;
$unpaid-color: #fe5001;
$retry-color: $color-to-review;

@mixin shortColumn() {
  .sc-list-header > .sc-item-wrap,
  .sc-list-items {
    grid-template-columns: 45px 3fr 2fr;
  }

  .sc-list-header > .sc-item-wrap {
    > *:nth-child(n + 3) {
      display: none;
    }
  }

  .sc-list-items {
    > *:nth-child(n + 4) {
      display: none;
    }
  }
}

.invoices-v2 {
  .auto-complete {
    display: flex;
    flex-grow: 1;

    input {
      width: 100%;
    }

    > ul > li[aria-selected='true'] {
      background: rgba(123, 123, 123, 0.15);
    }
  }

  .list-wrap > section:not(:last-of-type) {
    margin-right: 15px;
  }

  .column-list {
    width: 400px;

    .sc-multi-item {
      display: flex;
      flex-direction: column;

      .sc-dates {
        display: flex;
        align-items: center;
      }

      > *:first-child + * {
        color: #333;
      }

      svg {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }

    .sc-item-wrap {
      .sc-status {
        &.button {
          border: 0;
          padding: 0;
          margin: 0;
          background: transparent;
        }

        &.paid {
          color: $paid-color;
        }

        &.unpaid {
          color: $unpaid-color;
        }

        &.retry {
          color: $retry-color;
        }
      }

      .sc-view-button {
        padding: 0;
        width: 35px;
        height: 35px;
        display: inline-flex;
        border: 0;
        background: transparent;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        svg {
          color: #888;
        }

        &:hover {
          svg {
            color: #444;
          }
        }
      }

      &:not(:hover) {
        .sc-view-button {
          display: none;
        }
      }
    }

    .sc-list-header {
      margin-bottom: 10px;

      .sc-icon-header {
        grid-column: span 2;
      }

      > .sc-item-wrap {
        height: 35px;
        display: grid;
        font-weight: bold;
        font-size: 1.1em;
        background: #f9f9f9;
        color: #333;

        &:hover {
          background: #f9f9f9;
        }
      }
    }

    .sc-list-header > .sc-item-wrap,
    .sc-list-items {
      gap: 10px;
      padding: 0 10px;
    }

    .sc-list-items {
      display: grid;
      min-height: 50px;

      .sc-icon {
        display: inline-flex !important;
        width: 20px !important;
        height: auto !important;
        margin: 0 5px;
        flex-shrink: 0;
      }
    }

    .sc-list-item {
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      flex-grow: 1;
      flex-basis: 0;

      &:not(.sc-icon-item) {
        white-space: nowrap;
        overflow: hidden;

        span {
          display: block;
        }

        * {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.sc-wide {
    .list-wrap {
      min-width: auto;
      width: 100%;
    }

    .column-list {
      width: 100%;

      .dropdown-search-wrap {
        max-width: 300px;
      }

      .sc-list-header > .sc-item-wrap,
      .sc-list-items {
        grid-template-columns: 45px 4fr 2fr 4fr 2fr;
      }
    }
  }

  &:not(.sc-wide) {
    .column-list {
      @include shortColumn;
      width: 400px;
    }
  }

  .list-details {
    background: inherit;
    box-shadow: inherit;
    padding: 0;

    > .sc-title-wrap {
      font-size: 16px;
      padding: 0 0 5px;
      margin-bottom: 10px;

      .close-button {
        display: flex !important;
        height: 30px !important;
        line-height: 30px !important;
        background: white !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0 0 5px 2px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        transition: none;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px 2px;
        }
      }
    }

    > .sc-main-wrap {
      position: relative;
      height: 100%;
      padding: 0;

      > * {
        position: absolute;
      }

      .pageContainer {
        padding: 0;
      }
    }
  }
}

@media (max-width: 1000px) {
  .invoices-v2:not(.sc-wide) .column-list {
    display: none;
  }
}

@media (max-width: 767px) {
  .invoices-v2.sc-wide .column-list {
    @include shortColumn;

    .dropdown-search-wrap {
      max-width: 100%;
    }
  }
}
