.memberTypes {
  @include advanced-list-page-padding;
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;

  .advancedList_actions {
    justify-content: flex-end;
  }

  .advancedList_row {
    cursor: default;

    a {
      display: flex;
      cursor: pointer;
      float: left;

      &:first-child {
        margin-right: 5px;
      }
    }
  }

  &_loadingOverlay {
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 255;
    display: flex;
    justify-content: center;
    padding-top: 100px;
  }
}
