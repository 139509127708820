.column-list-icon {
  .align-svg {
    justify-content: center;
    width: 20px;
    height: 20px;

    &.refund-icon {
      margin-bottom: 2px;
      height: 24px;
      width: 24px;
    }

    > div {
      height: inherit;
      width: inherit;
    }

    svg {
      height: auto;
      width: auto;
    }
  }

  &.bg-invoice {
    background: #855747;
  }

  &.bg-credit {
    background: #256af4;
  }

  &.bg-misc {
    background: #774dcb;
  }

  &.bg-comm {
    background: #269dd9;
  }

  .sc-status-icon {
    &.bg-paid {
      background: $color-completed;
    }

    &.bg-unpaid {
      background: hsl(0, 90, 60);
    }

    &.bg-pending {
      background: $color-scheduled;
    }
  }
}

.column-list-tooltip {
  background: black;
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 3px;
  text-align: left;
  position: absolute;
  top: -5px;
  display: flex;
  left: 55px;
  flex-direction: column;
  color: white;
  z-index: 50;

  span.inline {
    min-width: 150px;

    span {
      &:nth-child(2) {
        padding-left: 5px;
      }
    }
  }
}
