.bookingFindMember {
  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_vehiclesFiltersFormWrapper {
    background-color: #fff;
    margin-bottom: 15px;
    padding: 25px 10px;
  }
}
