$checkbox: (
  checkbox-link-size: $baseFontSize,
  checkbox-color1: $color-secondary-light,
  checkbox-color2: $color-white,
  checkbox-checked-icon-size: 12
);

.checkboxMaterial svg {
  fill: $color-secondary-light !important;
}

.checkboxMaterial.disabled {
  svg {
    fill: #8b8b8b !important;
  }
}

.labeledCheckbox_input {
  &:checked {
    + .labeledCheckbox_label:before {
      background-color: $color-white;
      color: $color-primary;
    }
  }
}

.labeledCheckbox_label {
  margin-left: 10px;

  &:before {
    border-color: $color-primary;
  }
}
