.PaybackList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .advancedList_wrap {
    margin: 0;
    max-width: none;
  }

  .payback-detail-actions {
    margin-bottom: 20px;
  }

  .advancedList_chipsWrap {
    margin: 0 0 15px;
    max-width: none;
  }
}
