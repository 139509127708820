.autolibCardForm {
  @include clearfix;
}

.autolibCardForm_title {
  @include remIt(font-size, 13);
  text-transform: uppercase;
  font-weight: 200;
  margin: 0 0 30px;
}

.autolibCardForm_row {
  margin-bottom: 25px;
}

.autolibCardForm_definitionList_term {
  @include remIt(font-size, 11);
  display: block;
  text-transform: uppercase;
}

.autolibCardForm_definitionList_definition {
  @include remIt(font-size, 12);
  padding: 0;
  margin: 10px 0 0;
}

.autolibCardForm_option {
  float: left;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }
}

.ekButton.autolibCardForm_actionsButton {
  min-width: 218px;
  float: right;
  margin-top: 20px;
}

.fieldErrorMsg--autolibCardForm {
  margin-top: 10px;
}
