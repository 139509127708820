$z-index: 1100;

.tooltip-container {
  display: inline-flex;

  .popper-ref {
    display: inline-flex;
  }

  .popper-wrapper {
    user-select: none;
    pointer-events: none;
    z-index: $z-index;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    transition-delay: 0s;
  }

  &:hover {
    .popper-wrapper {
      transition-delay: 100ms;
      z-index: $z-index;
      opacity: 1;
    }
  }

  [disabled] {
    pointer-events: none;
  }
}
