.detailView_header {
  .booking_status {
    &_in_progress {
      color: $color-progress;
    }

    &_scheduled {
      color: $color-scheduled;
    }

    &_completed {
      color: $color-completed;
    }

    &_canceled {
      color: $color-canceled;
    }

    &_pre_booked {
      color: $color-prebooked;
    }
  }
}

.bookingView {
  &_cancelBooking_abort_button {
    float: left;
    width: 40%;
  }

  &_cancelBooking_confirm_button {
    float: right;
    width: 40%;
  }

  .driving-license-detail {
    .detailView_table {
      margin-right: 3rem;
    }

    .image-preview-component {
      img {
        max-width: 120px;
        padding: 10px;

        &.component-img-open {
          max-width: unset;
          padding: unset;
        }
      }
    }
  }
}
