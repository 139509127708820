.bookingFindVehicle {
  .memberDetail {
    margin-bottom: 15px;
  }

  &_filterWrapper {
    margin-bottom: 15px;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }

  .tableView_cell,
  .tableView_headCell {
    text-align: center;
  }
}

$vehiclesFiltersForm: (
  vehiclesFiltersForm-bg-color: $color-white,
  vehiclesFiltersForm-text-color: $color-primary-darkest,
  vehiclesFiltersForm-text-size: 11,
  vehiclesFiltersForm-border-color: $color-primary3-alt
);

.vehiclesFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: map_get($vehiclesFiltersForm, vehiclesFiltersForm-bg-color);
  margin-bottom: 15px;
  padding: 25px 10px;
}
