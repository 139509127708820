.contractPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }
}

.contractPage_management {
  &_header {
    font-size: 0;
    padding-bottom: 12px;

    &_title {
      @include remIt(font-size, 11);
      @include remIt(line-height, 12);
      display: block;
      width: 100%;
      background-color: $color-primary3-alt;
      text-transform: uppercase;
      padding: 9px 10px;
      margin-bottom: 15px;
      font-weight: 600;
    }
  }
}

.contractPage_managementHeader {
  padding-bottom: 25px;
}

.contractPage_form {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background: $color-white;
  padding: 20px;
}
