$iconPaddingLeft: 15;
$iconPaddingRight: 15;
$textAdditionalPaddingRight: 11;

.flashMessageDisplayer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: 200ms;
  z-index: $flash_message_z_index;

  &_show {
    bottom: 0px;
    opacity: 1;
  }

  &_hide {
    bottom: -50px;
    opacity: 0;
  }

  &_close {
    display: none;
  }

  &_left_icon {
    @include remIt(padding-right, $iconPaddingRight);
    @include remIt(padding-left, $iconPaddingLeft);
    display: flex;
  }
}

.flashMessageDisplayer_message:hover {
  .flashMessageDisplayer_left_icon {
    display: none;
  }

  .flashMessageDisplayer_close {
    cursor: pointer;
    display: flex;
    @include remIt(padding-right, $iconPaddingRight);
    @include remIt(padding-left, $iconPaddingLeft);
  }
}

.flashMessageDisplayer_message_wrap {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.flashMessageDisplayer_message {
  @include remIt(font-size, 14);
  @include remIt(line-height, 18);
  background: $color-grey-dark;
  border-radius: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 15px 15px 15px;
  box-shadow: $color-border-shadow2 0px 1px 6px, $color-border-shadow2 0px 1px 4px;

  &_raw {
    @include remIt(padding-right, $iconPaddingRight + $textAdditionalPaddingRight);
    max-width: 368px;
    word-wrap: break-word;
  }

  &:before {
    @include remIt(font-size, 14);
    font-family: $icons-font;
    display: inline-block;
    margin-right: 5px;
  }

  &--info {
    fill: white !important;
  }

  &--warning {
    fill: orange !important;
  }

  &--error {
    fill: red !important;
  }

  &--success {
    fill: #30dc30 !important;
  }
}
