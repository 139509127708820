@media (max-width: 1000px) {
  .contract-v2 {
    .list-details.mobile-home {
      .hs-container {
        flex-direction: column;

        #items,
        #preview-tiles,
        .hs-form-wrapper {
          flex-basis: 100%;
          width: 100%;
          position: relative;
          min-height: 900px;
        }
        #items,
        #preview-tiles {
          order: 2;
        }
        .hs-form-wrapper {
          order: 1;
        }
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .contract-v2 {
    .list-details.mobile-home {
      .hs-container {
        flex-direction: row;

        #preview-tiles {
          &.less {
            .iphone-x {
              @include transform(scale(0.7));
              @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
            }
          }
          .iphone-x {
            @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
            @include transform(scale(0.8));
          }
        }
      }
    }
  }
}

@media (max-width: 1420px) and (min-width: 1200px) {
  .contract-v2 {
    .list-details.mobile-home {
      .hs-container {
        flex-direction: row;

        #preview-tiles {
          &.less {
            .iphone-x {
              @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
              @include transform(scale(0.7));
            }
          }
          .iphone-x {
            @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
            @include transform(scale(0.9));
          }
        }
      }
    }
  }
}

@media (min-width: 1420px) {
  .contract-v2 {
    .list-details.mobile-home {
      .hs-container {
        flex-direction: row;

        #preview-tiles {
          .iphone-x {
            @include transition(all 0.4s cubic-bezier(0.88, -0.99, 0, 1.81));
            @include transform(scale(1));
          }
        }
      }
    }
  }
}
