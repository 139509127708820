.accountPage {
  .mainContent_inner {
    margin: 50px auto 30px;
    max-width: 900px;
    background: white;
    border-radius: 10px;
    padding: 30px 40px 5px;
  }

  &_title {
    margin-bottom: 30px;
  }

  &_form {
    margin-top: 35px;
  }
}
