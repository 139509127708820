.list-modal {
  &.small {
    height: 140px !important;
  }
  .addSitePage {
    .pageContainer {
      padding: 0;

      .addSitePage_content {
        padding: 30px 30px 0;

        .siteForm_container {
          margin-top: 0;
        }
      }
    }
  }

  .siteCopyForm {
    display: flex;
    margin: 10px 20px;
    display: flex;
    align-items: center;

    .site-select {
      margin: 0 10px;
    }
  }
  .fieldErrorMsg--copySite {
    margin: 10px 20px;
  }
}
