.add-task-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $color-white;
  padding: 15px;

  .taskForm {
    .time-inline {
      display: flex;
      align-content: center;

      .box-minutes {
        padding-top: 20px;
        padding-left: 10px;
      }
    }
  }

  .taskForm_actions {
    display: flex;
    padding-top: 20px;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .taskForm {
    .formRow {
      &:last-of-type {
        margin-top: 25px;
      }
    }
  }
}
