$child-padding: 20px;
.detailView.profile-history {
  .detailView_body {
    overflow: hidden;
    padding: 0;
    margin: 0;
    > div {
      padding: 0;
      &:hover {
        transition: none;
        animation: none;
      }
    }

    div.memberView_profileHistory {
      padding: 0;
      margin: 0;
      position: relative;

      .events-list {
        .table-shadow {
          width: 100%;
          height: calc(100vh - 175px);
        }

        thead {
          position: sticky;
          z-index: 700;
          top: 0;
        }

        table {
          tbody tr.diff {
            position: relative;
            background-color: white;

            &:hover {
              background-color: $color-primary-lightest;
            }

            &.opened {
              border-top: 1px solid #d1eed1;
              border-bottom: 1px solid #d1eed1;
              position: sticky;
              background-color: $color-primary-lightest;
              top: 40px;
            }

            &-detail {
              display: none;

              &.show-diff {
                display: table-row;
                border-bottom: 1px solid #d1eed1;

                tr {
                  &:hover {
                    background: #d1eed1;
                  }
                }
                > td {
                  padding: 0px;

                  td {
                    padding: 10px;
                    &:first-child {
                      padding-left: $child-padding;
                    }

                    &:last-child {
                      padding-right: $child-padding;
                    }
                  }
                }

                td {
                  span.expand-handler {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                  }
                }

                thead,
                th {
                  background: #f4f4f4;
                  border-bottom: 1px solid #d1eed1;
                  top: 85px;
                  z-index: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
