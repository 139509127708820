$vp-uppercase-font-size: 12px;

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .VehiclePlanningEditBooking_col1Row2 .boxedSelect {
    color: black;
  }
}

.vehiclePlanning {
  &_date_block {
    width: 115px;
  }
}

.vp_memberType {
  &_boxedSelectBlock {
    margin: 15px 0 8px;
    line-height: 11px;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    .boxedSelect_label {
      display: flex;

      span {
        font-size: $vp-uppercase-font-size;
      }
    }
  }

  &_boxedSelectWrapper {
    width: 100%;
  }
}

.vehiclePlanningSetup {
  .addBookingConfirmationForm_button {
    width: auto !important;
  }

  &_sideMenuOpen {
    width: calc(100% - #{$side-menu-width}) !important;

    @media (max-width: 1200px) {
      width: 100% !important;
    }
  }

  &_container {
    color: rgba(0, 0, 0, 0.8);
    background: white;
    margin: 15px;
    margin-left: 35px;
    margin-top: 80px;
    position: relative;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
    height: calc(100vh - 155px);
    overflow: scroll;
    overflow-x: hidden;
    padding-top: 70px;
  }

  &_changePeriodWrap {
    display: flex;
    align-items: center;
    z-index: 50;
    margin-right: 15px;
  }

  &_container_inline {
    height: 60px;
    background: white;
    padding: 0 15px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 60px;
    z-index: 99;
    width: 100%;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;

    .boxedSelectWrapper:after {
      display: none;
    }

    .boxedSelectWrapper {
      border: 0;
    }

    .boxedSelectWrapper select {
      font-size: 16px !important;
      color: rgba(0, 0, 0, 0.8) !important;
    }

    .vehiclePlanningSetup_label {
      display: none;
    }

    .vehiclePlanningSetup_buttonWrap {
      margin-top: 0;
      margin-left: auto;
    }

    .vehiclePlanningSetup_formRow {
      margin-bottom: 0;
    }

    .vehiclePlanningSetup_formRow .boxedSelectWrapper {
      width: 175px;
    }

    .boxedInput--date input {
      font-size: 16px !important;
      color: rgba(0, 0, 0, 0.8) !important;
    }

    form {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &_wrap_inline {
    width: 100%;
    display: flex;
  }

  &_wrap {
    background: white;
    width: 400px;
    padding: 25px;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
  }

  &_buttonWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 35px;
  }

  &_formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: row;

    .boxedInputWrapper {
      width: 130px;
    }

    &.usages {
      width: 40%;
    }

    .usage {
      div {
        div {
          width: auto !important;
          float: none !important;
        }
      }
    }

    .boxedInput {
      border: 0;
      cursor: pointer;
    }

    .boxedInput--date {
      background: inherit;
    }

    .boxedInput--date input {
      font-size: 14px !important;
    }

    .boxedSelectWrapper {
      width: 150px;
    }
  }

  &_label {
    color: rgba(0, 0, 0, 0.8);
  }
}

.vehiclePlanning {
  &.mainContainer_content {
    width: calc(100% - #{$side-menu-width});
  }

  &_closeIconWrap {
    display: flex;
    justify-content: flex-end;

    svg {
      fill: rgba(0, 0, 0, 0.7) !important;
      cursor: pointer;
    }

    .filter {
      display: inline-flex;
      padding: 5px;
      width: 90%;

      svg {
        margin-top: 7px;
      }
    }
  }

  &_closeIconAbosuluteWrap {
    position: absolute;
    right: 0;
    top: 0;

    svg {
      fill: rgba(0, 0, 0, 0.7) !important;
      cursor: pointer;
    }
  }

  &_statusDetail_buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -10px;
    margin-right: 15px;
  }

  &_usageSelector {
    margin: 15px 0;
  }

  &_typeWrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .ekRadio_block {
      width: 100%;
    }

    &.fullrow {
      display: block;

      .checkboxMaterial {
        margin-top: 10px;

        label {
          line-height: 24px !important;
        }
      }
    }

    .checkboxMaterial {
      label {
        line-height: 16px !important;
      }
    }
  }

  &_card_headerStatus svg {
    fill: white !important;
  }

  &_card_line {
    display: flex;
    padding: 10px 30px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;

    .member-status {
      display: inline-block;
      @include remIt(line-height, 20);
      border-radius: 3px;
      padding: 0 8px;
      color: $color-white;
    }
  }

  &_card_lineIcon svg {
    fill: rgba(0, 0, 0, 0.7) !important;
    text-decoration: none;
    margin-right: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  &_card_lineData a {
    font-size: 14px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);

    &:hover {
      text-decoration: underline;
    }

    &.actions {
      color: #3972de;
      text-transform: uppercase;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  &_card_lineIcon {
    width: 75px;
    padding-right: 10px;
  }

  &_card_header {
    height: 145px;
    background: $color-completed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;

    .vehiclePlanning_card_headerStatus {
      display: flex;
      align-items: center;
      margin-top: -12px;
    }

    .vehiclePlanning_card_headerStatus svg {
      margin-right: 10px;
    }

    .vehiclePlanning_vehicleRow_firstField {
      min-width: auto;
      width: auto;
      display: flex;
      margin: 0 15px;
      padding: 5px;
    }

    .vehiclePlanning_card_headerClose {
      margin-top: -12px;
      margin-right: -12px;
    }

    .vehiclePlanning_vehicleRow_vehicleInfo {
      font-size: 12px;
    }

    .vehiclePlanning_vehicleRow_registrationNumber {
      font-size: 14px;
      margin-top: 5px;
    }

    img {
      width: 75px;
    }

    .vehiclePlanning_vehicleRow_fuelLevel {
      display: none;
    }
  }

  &_card_headerTopLine {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: white;

    svg {
      color: inherit !important;
      fill: currentColor !important;
    }
  }

  &_card_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
    justify-content: center;
    align-items: center;
    padding-top: 110px;
  }

  &_card_opened {
    display: flex;
  }

  &_card_wrap {
    width: 400px;
    background: white;
    height: auto;
    padding-bottom: 25px;
    box-shadow: 0 24px 38px 3px $color-border-shadow5, 0 9px 46px 8px $color-border-shadow2, 0 11px 15px -7px $color-border-shadow1;
  }

  &_openhours {
    height: 50px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    color: grey;
    z-index: 21;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 10px;

    .start {
      align-items: flex-start;
      padding: 10px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .end {
      align-items: flex-end;
      padding: 10px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_closedhours {
    height: 100%;
    border-radius: 1px;
    background-color: rgba($color-black, 0.1);
    position: absolute;
    top: 0;
    color: grey;
    z-index: 21;
    text-align: center;
  }

  &_status {
    height: 35px;
    border-radius: 3px;
    background: #767676;
    position: absolute;
    bottom: 5px;
    color: white;
    z-index: 23;
    text-align: center;

    div.status-detail {
      display: inline-block;
      margin-top: 7px;
    }

    span {
      padding: 3px;
      border-radius: 5px;
      position: relative;
      display: inline-block;

      &.start-date,
      &.end-date {
        background: white;
        color: black;
      }

      &.end-date {
        margin-right: 5px;
      }
    }

    div.detail-tooltip {
      display: block !important;
      height: 35px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.pad {
        padding-top: 10px;
      }
    }
  }

  &_booking {
    min-width: 10px;
    height: 35px;
    position: absolute;
    bottom: 5px;
    padding: 0;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    z-index: 25;
    cursor: pointer;
    transition: 300ms;

    &.counter0 {
      border-radius: 0 3px 3px 3px !important;

      &::after {
        content: attr(data);
        display: block;
        position: absolute;
        background: $color-bg-dark;
        color: $color-white;
        width: 100px; // For IE
        width: max-content;
        height: 18px;
        overflow: hidden;
        top: -18px;
        padding: 2px 4px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }

    &.borderR {
      border-radius: 3px;
    }

    .detail-tooltip {
      padding: 4px;
      overflow: hidden;
    }

    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;

      &.vehiclePlanning_booking_name {
        span {
          display: inline;

          &:nth-child(1) {
            padding-right: 4px;
          }

          &:nth-child(3) {
            display: block;
          }
        }
      }
    }

    &:hover {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
      -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
      -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#444')";
      z-index: 200;

      span {
        overflow: hidden;
      }
    }

    &_delayed {
      height: 35px;
      background: red;
      border-radius: 3px;
      position: absolute;
      bottom: 5px;
      z-index: 55;
      font-weight: 600;
      font-size: 10px;
      padding: 0;
      cursor: pointer;

      .detail-tooltip {
        display: inline-block !important;
        height: 100%;
        width: 100%;
        padding: 4px;
        color: $color-white;
      }

      .detail-tooltip .vehiclePlanning_booking_name_delayed {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: inline;

          &:nth-child(1) {
            padding-right: 4px;
          }

          &:nth-child(3) {
            display: block;
          }
        }
      }

      &:hover {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

        span {
          overflow: hidden;
        }
      }
    }

    &_wrap {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    &_reservationBuffer {
      height: 35px;
      background: #9f9f9f;
      position: absolute;
      bottom: 5px;
    }

    &_name {
      font-size: 11px;
      white-space: nowrap;
    }

    &_tooltip {
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      top: -45px;
      border-radius: 3px;
      z-index: 10;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 4px;
      white-space: nowrap;
    }
  }

  .advancedList_loader {
    padding-top: 0;
    height: 55px;
  }

  &_noResults {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: grey;
    text-transform: uppercase;
  }

  &_site {
    position: relative;
    z-index: 55;

    &_name {
      position: absolute;
      top: 2px;
      right: 10px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }

  &_parking {
    position: relative;

    &_name {
      text-transform: uppercase;
      font-weight: 600;
      margin-left: 10px;
      left: 0;
      top: 2px;
      z-index: 57;
      font-size: 12px;
      height: 20px;
    }
  }

  &_loadingOverlay {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 255;
    padding-top: 100px;
  }

  &_container {
    color: rgba(0, 0, 0, 0.8);
    background: white;
    margin: 15px;
    margin-left: 35px;
    margin-top: 80px;
    position: relative;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
    height: calc(100vh - 155px);
    overflow: scroll;
    overflow-x: hidden;
    padding-top: 70px;
  }

  .current-time {
    position: absolute;
    top: 70px;
    left: 424px;
    z-index: 23;
    width: 1px;
    height: 100%;
    background: red;
    box-shadow: none;

    &:before {
      content: ' ';
      position: absolute;
      left: -4px;
      top: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid red;
    }

    // &:hover {
    //   width: 3px;
    //   box-shadow: 0 1px 10px 2px silver;
    // }
  }

  &_bookingMode {
    &:hover {
      background: #c7c7c7;

      cursor: pointer;
    }

    &_selectedDay {
      background: #c7c7c7;
    }
  }

  &_firstRow_sideMenuOpen {
    @media (max-width: 1200px) {
      width: calc(100% - 50px) !important;
    }
  }

  &_firstRow {
    display: flex;
    height: 70px;
    position: fixed;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: calc(100% - 50px);
    background: #f4f3f4;
    z-index: 57;
    margin-top: -70px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;

    &_sideMenuOpen {
      width: calc(100% - 285px);
    }

    &_hours {
      display: flex;
      font-size: 10px;
      margin: 5px -10px;
    }

    &_hour {
      flex: 1;
      padding-left: 3px;
    }

    &_firstField {
      width: 150px;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }

    &_field {
      padding: 5px 10px;
      flex: 1;
    }

    &_day {
      margin-bottom: 2px;
    }
  }

  &_vehicleRow_vehicleInfo a {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &_vehicleRow_registrationNumber a {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &_vehicleRow {
    height: 110px;
    font-size: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    position: relative;
    overflow: hidden;

    &_hourField {
      flex: 1;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }

    &_fuelLevel {
      position: absolute;
      right: 2px;
      top: 40px;
    }

    &_firstField {
      min-width: 150px;
      width: 150px;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      height: 100%;
      padding-left: 10px;
      padding-top: 5px;
      position: relative;
      z-index: 26;
      background: white;

      .vehicle-usage {
        border-radius: 10px;
        text-align: center;
        color: $color-white;
        padding: 1px 5px 3px;
        margin-bottom: 5px;
        display: table;
      }
    }

    &_field {
      flex: 1;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      z-index: 22;
      display: flex;
    }

    img {
      width: 50px;
      height: 32px;
      object-fit: cover;
    }
  }
}

.edit-booking-loading {
  background: $color-white !important;
}

.VehiclePlanningEditBooking {
  .replacement-vehicle-confirm {
    margin-top: 10px;

    .hr {
      margin-top: 10px;
      border-bottom: 1px solid $color-primary3-alt;
    }
  }

  .customFieldsBookingWrapper {
    .formFieldWrapper {
      padding: 7px 7px 7px 0;
    }

    .ekRadio_title {
      font-size: $vp-uppercase-font-size;
    }
  }

  .ekRadio_wrapper_disabled {
    .ekRadio_label {
      cursor: not-allowed;
      color: $color-vp-disabled;
    }
  }

  .createBooking_textarea {
    height: 40px;
    resize: vertical;
    outline: none;
  }

  .boxedSelect:disabled {
    cursor: not-allowed !important;
    background-color: inherit !important;
  }

  &_container {
    display: flex;
  }

  &_col1 {
    width: 50%;
    background-color: $color-vp-booking2;
    transition: all 0.2s ease;

    .title {
      color: $color-white;
      padding-left: 25px;
      font-size: larger;
      margin-top: 10px;
    }

    &.wide {
      .title {
        display: none;
      }

      .user-detail {
        background: $color-white;

        &.read {
          &:after {
            content: '';
          }
        }

        &:after {
          content: ' ';
          position: absolute;
          top: 47px;
          height: calc(100% - 47px);
          width: 600px;
          background-color: $color-grey-transparent;
          z-index: 25;
          padding: 0;
          margin: 0 -20px;
        }
      }
    }

    .user-detail {
      height: 90px;
      color: $color-white;
      padding: 5px 20px;
      background: $color-vp-booking2;
      cursor: pointer;

      span {
        display: inline-block;

        &.user-name {
          .edit {
            display: none !important;
          }

          span {
            vertical-align: super;
          }
        }

        svg {
          fill: $color-white !important;
        }

        &.user-mail {
          font-size: 12px;
          width: 100%;
        }

        .fieldErrorMsg {
          position: absolute;
        }
      }

      &.read {
        cursor: default;

        .profil {
          display: inline-block !important;
        }

        .edit {
          display: none !important;
        }

        .user-mail {
          .email-read {
            border: 0;
            background-color: $color-vp-booking2;
            color: $color-white;
            height: auto;
          }
        }
      }

      &:hover {
        .user-name {
          .profil {
            display: none !important;
          }

          .edit {
            display: inline-block !important;
          }
        }

        &.read {
          .user-name .profil {
            display: inline-block !important;
          }

          .user-name .edit {
            display: none !important;
          }
        }
      }
    }
  }

  &_changeVehicle {
    position: absolute !important;
    left: 7px;
    bottom: 15px;
    fill: $color-vp-booking2 !important;
  }

  &_col2 {
    width: 50%;
    color: rgba(0, 0, 0, 0.75);
    position: relative;

    .box {
      height: 65vh;
      overflow: auto;
      padding: 5px 20px 0;

      // .formRow.stick {
      //   height: 60px !important;
      // }

      .addBookingConfirmationForm_buttonRow {
        // .formRow.stick
        position: sticky;
        bottom: 0;
        height: 80px;
        z-index: 6;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 50%);
      }

      .customFieldsBookingWrapper {
        display: flex;
        flex-direction: column;

        .formFieldWrapper {
          margin: 5px;
          // border: 1px dashed red;
          display: block;
        }
      }
    }

    .ekButton {
      text-transform: uppercase;
      margin-top: 5px;
      height: 37px;

      &.voucher_button {
        margin-top: 25px;
      }
    }

    .addBookingConfirmationFormWrapper {
      padding-top: 15px;
    }

    .ekButton--reverse {
      display: none;
    }

    .ekTextarea_labelText {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      padding-bottom: 2px;
    }

    .addBookingConfirmationForm_row {
      margin: 2px;
    }

    .addBookingConfirmationForm_textarea {
      height: 60px;
    }

    &CarWrap {
      display: flex;
    }

    img {
      width: 140px;
      height: 85px;
      object-fit: cover;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
    }

    h4 {
      display: none;
    }

    @keyframes blink {
      // At the start of the animation the dot has an opacity of .2
      0% {
        opacity: 0.2;
      }
      // At 20% the dot is fully visible and then fades out slowly
      20% {
        opacity: 1;
      }
      // Until it reaches an opacity of .2 and the animation can start again
      100% {
        opacity: 0.2;
      }
    }

    .price {
      @include remIt(font-size, 50);

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: 40%;

      .line-through {
        position: relative;
        text-decoration: line-through;
        display: inline-block;

        &:after {
          content: ' ';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 50%;
          background-color: $color-black;
        }
      }
    }

    .loading-price span {
      @include remIt(font-size, 50);
      position: relative;
      bottom: 14px;
      animation-name: blink;
      color: black;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }

    .loading-price span:nth-child(2) {
      animation-delay: 0.2s;
    }

    .loading-price span:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  &_col1Row1 {
    height: 30%;
    filter: brightness(1.2);
    color: white;
    padding: 5px 45px;
    position: relative;
    min-height: 150px;
    max-height: 150px;

    &Icons svg {
      fill: white !important;
      height: 20px !important;
      width: 20px !important;
    }

    &Icons {
      position: absolute;
      height: 70px;
      left: 10px;
      top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &Icons_bigDot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 2px solid white;
    }

    &Icons_smallDot {
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background: rgba(255, 255, 255, 0.8);
    }

    .ekRadio_label {
      color: white;
    }

    .ekRadio_container {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
    }

    .ekRadio_wrapper {
      margin-right: 10px;
    }

    .geosuggest {
      margin: 5px 0;
    }

    .geosuggest__input {
      background-color: inherit !important;
      border: 0;
      border-bottom: 1px solid white;
      padding: 10px 0;
      color: white;
      box-sizing: border-box;
      font-size: 14px;
    }

    .geosuggest__input:disabled {
      color: $color-vp-disabled !important;
      border-bottom: 1px solid $color-vp-disabled !important;
      cursor: not-allowed;
    }

    .geosuggest__input:focus {
      outline: none;
    }

    .geosuggest__suggests {
      color: rgba(0, 0, 0, 0.7);
      border: 0;
    }

    .searchVehicle_tripSelection {
      margin-top: -8px;
    }

    .user-detail {
      background-color: $color-white;
      color: $color-black;
    }
  }

  &_col1Row2 {
    height: 20%;
    background: $color-vp-booking2;
    padding: 13px 45px 0 45px;
    display: flex;
    min-height: 70px;
    max-height: 70px;

    &_pickupDate {
      width: 115px;
    }

    svg {
      fill: white !important;
      height: 20px !important;
      width: 20px !important;
      position: absolute;
      left: 45px;
      margin-top: 15px;
    }

    .boxedInput--date {
      background: inherit;
    }

    .boxedInput_labelText {
      display: none;
    }

    .boxedInput {
      background: transparent;
      border: 0;
      border-bottom: 1px solid white;
      color: white;
    }

    .boxedInput input {
      color: white !important;
      padding: 14px 0 0 35px !important;
    }

    .searchVehicle_boxedSelectWrapper {
      background: transparent;
      width: auto;
      border: 0;
    }

    .boxedSelectWrapper:after {
      display: none;
    }

    &_dateWrap {
      display: flex;
      height: 40px;
      margin-left: 10px;
      border-bottom: 1px solid white;
      width: 100px;
      padding-left: 25px;
    }

    &_dateWrap svg {
      height: 17px !important;
      width: 17px !important;
      left: 170px;
      margin-left: 0;
      margin-top: 18px;
    }

    &_dvojtecka {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      color: white;
      margin-bottom: 3px;
      margin-right: 7px;
      font-size: 12px;
      line-height: 20px;
    }

    .boxedSelect {
      color: white;
      min-width: 20px;
      padding: 0 !important;
      margin-top: 17px;
      background-color: inherit !important;
      border: 0;
      outline: none;
      height: 20px;
      font-size: 12px;
      line-height: 20px;

      option {
        color: #333 !important;
        background: white !important;
      }
    }

    .boxedSelect:focus::-ms-value {
      background-color: rgba($color-vp-booking2, 0.6);
    }

    .boxedInput--date > div:first-child {
      width: 115px !important;
    }

    .paddingTop0 {
      padding-top: 0;
    }
  }
}

.vehiclePlanningChangeVehicle {
  width: 50%;
  color: rgba(0, 0, 0, 0.75);
  position: relative;
  overflow: auto;

  &-available-list {
    height: 30vh;
    overflow-y: scroll;
  }

  &_loaderWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 40px;
  }

  &_row {
    height: 65px;
    padding: 10px;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 12px;
    position: relative;

    &_fuelLevel {
      position: absolute;
      right: 25px;
      top: 10px;
    }

    img {
      width: 80px;
      height: 45px;
      object-fit: cover;
      margin-right: 10px;
    }
  }
}

.vehiclePlanningCreateStatus {
  &_buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -10px;
    margin-top: 10px;
  }

  .VehiclePlanningEditBooking_col1Row2 {
    background: white;
    padding: 10px 0;
  }

  .boxedInput--date {
    background: inherit;
  }

  .boxedInput_labelText {
    display: none;
  }

  .boxedInput {
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    color: rgba(0, 0, 0, 0.7);

    &--textarea {
      resize: vertical;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.7);
    }
  }

  .boxedInput input {
    padding: 14px 0 0 35px !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }

  &_dateWrap {
    display: flex;
    height: 40px;
    margin-left: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    width: 90px;
    padding-left: 25px;
  }

  &_dateWrap svg {
    height: 17px !important;
    width: 17px !important;
    left: 170px;
    margin-top: 18px;
  }

  .VehiclePlanningEditBooking_col1Row2_dvojtecka {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
    margin-right: 7px;
    font-size: 12px;
    line-height: 20px;
  }

  .boxedSelect {
    min-width: 20px;
    padding: 0 !important;
    margin-top: 18px;
    background-color: white;
    border: 0;
    outline: none;
    height: 20px;
    font-size: 12px;
    color: $color-black;
  }

  .boxedInput--date > div:first-child {
    width: 115px !important;
  }

  .VehiclePlanningEditBooking_col1Row2 {
    margin: 0 5px;
    position: relative;

    &_dateWrap {
      display: flex;
      height: 40px;
      margin-left: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
      width: 100px;
      padding-left: 25px;
    }

    &_dateWrap svg {
      height: 17px !important;
      width: 17px !important;
      left: 125px;
      margin-top: 18px;
      fill: rgba(0, 0, 0, 0.7) !important;
    }
  }

  .vehiclePlanningCreateStatus_calendarIcon {
    fill: rgba(0, 0, 0, 0.7) !important;
    height: 20px !important;
    width: 20px !important;
    position: absolute;
    left: 5px;
    margin-top: 15px;
  }
}

.addBookingConfirmationForm_buttonRow {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-end;

  .flatButton {
    margin: 5px 5px 0 !important;
  }
}

.tippy-popper {
  .tippy-tooltip-content {
    padding: 0;
    background: $color-grey-light4 !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

    .status-detail {
      span {
        display: block;
        padding: 3px 10px;
        text-align: center;

        &.type {
          background: $color-white;
          color: $color-grey-light4;
          border: 1px solid $color-grey-light4;
        }

        &.dots {
          display: none;
        }
      }
    }
  }
}

//booking tooltip
.card {
  text-align: left;

  .show {
    display: block;

    a {
      font-size: 14px;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);

      &:hover {
        text-decoration: underline;
      }

      &.actions {
        color: #3972de;
        text-transform: uppercase;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    .details {
      padding: 4px;

      span {
        min-width: 20%;
        padding-right: 5px;
        display: inline-block;

        &.member-status {
          display: inline-block;
          @include remIt(line-height, 20);
          border-radius: 3px;
          padding: 0 8px;
          color: $color-white;
        }
      }
    }

    .hint-workflow {
      padding: 2px 5px;
      background-color: $color-primary-light;
      border: 1px solid $color-grey-light;
      border-radius: 3px;
      margin: 10px 0;
    }

    .card-header {
      > div {
        padding-right: 0 !important;
      }

      svg {
        color: white !important;
      }

      .prebooked-icon {
        display: inline-block;

        svg.svg-icon {
          fill: $color-black;
        }
      }

      .delayed {
        background-color: $color-error;
        padding: 4px;
        color: $color-white;
        border-radius: 4px;
        margin-left: 25px;
      }

      svg.svg-icon {
        fill: $color-black;

        &.tik-tok {
          width: 40px;
          height: 40px;
          fill: #fff;

          .iconic-clock-hour-hand,
          .iconic-clock-minute-hand,
          .iconic-clock-second-hand-arm {
            stroke: $color-white;
          }
        }
      }
    }

    .actions > div {
      margin: 2px !important;
    }
  }

  .hide {
    display: none;
  }
}

.tabs-rci {
  .member-exist {
    color: $color-error;
  }

  .tab {
    display: inline-block;
  }

  .tabHide {
    display: none !important;
    width: 0 !important;
  }

  .tabPre {
    display: inline-block;
    width: 100% !important;
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: ' ';
      width: 100%;
      height: 2px;
      background-color: $color-main;
    }
  }
}

.modal-qr-code {
  h4 {
    span.member-name {
      padding-left: 5px;
      font-weight: normal;
    }
  }

  .add-member-files {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;

    .svg-icon.mobile-qr {
      margin: 0 auto 20px;
      height: 100px;
      display: block;
      width: 100px;
    }

    span {
      font-weight: 700;
    }

    .small-size-text {
      padding-top: 10px;
      span {
        font-weight: normal;
        font-size: smaller;
      }
    }

    .presubscribe-fake-actions {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-top: 20px;
      gap: 10px;
    }
  }
}
