// ekSelect
// ==========================================================================

$select: (
  select-border-color: $color-primary3-alt,
  select-text-color: $color-primary-darkest,
  select-bg-color: $color-white,
  select-height: 40px,
  select-input-color: $color-white
);

// Constructor
// ==========================================================================

$border: 1px solid map_get($select, select-border-color);
$select-padding: 10px 30px 10px 10px;

%boxedSelectWrapper {
  position: relative;
  display: inline-block;
  background-color: map_get($select, select-bg-color);

  select {
    border: $border;
    cursor: default;

    option {
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}

.boxedSelect {
  @include remIt(font-size, 13);
  @include remIt(line-height, 15);
  @include appearance();
  @include border-radius(0);
  border: 0;
  width: 100%;
  min-width: 66px;
  color: map_get($select, select-text-color);
  padding: $select-padding;
  position: relative;
  cursor: pointer;
  background: transparent;
  z-index: 5;
  outline: none;
  height: map_get($select, select-height);

  &::-ms-expand {
    display: none;
  }

  &_hidden {
    display: none;
  }

  &--small {
    min-width: 86px;
  }

  &--medium {
    min-width: 218px;
  }

  &:disabled {
    background-color: $color-most-light;
    color: rgba($color-primary-darkest, 0.8);
    -webkit-text-fill-color: currentColor;
    cursor: not-allowed;

    option {
      cursor: not-allowed;
      color: inherit;
    }
  }
}

.boxedSelect_label {
  @include remIt(font-size, 11);
  display: block;
  color: map_get($select, select-text-color);
  padding-bottom: 5px;
  text-transform: uppercase;

  &._is_hidden {
    display: none;
  }

  &._is_not_visible {
    visibility: hidden;
  }
}

.boxedSelectWrapper {
  @extend %boxedSelectWrapper;

  &._is_disabled {
    background-color: rgba($color-white, 0.8);

    &:after {
      background: inherit;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: map_get($select, select-bg-color) url('#{$img-path}chevron.svg') no-repeat right center;
    width: 15px;
    margin: 0 10px;
    height: map_get($select, select-height);
  }

  &--fullWidth {
    display: block;
    width: 100%;
  }
}

.boxedSelectMultipleWrapper {
  @extend %boxedSelectWrapper;
  width: 100%;
}

.boxedSelectBlockInner {
  position: relative;
}

.boxedSelectBlock_phoneNumber {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .boxedSelectWrapper {
    height: 100%;
  }
}
