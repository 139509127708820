// scss-lint:disable SingleLinePerSelector

@mixin header() {
  display: flex;
  align-items: center;
}

$header-height: 4em;
$page-margin: 1.6em;
$regular-color: $color-grey-light2;
$alt-color: $color-grey-light;
$screen-media: 900px;
$small-screen-media: 600px;

.detailView_header {
  background-color: $color-white-alt;
  border-bottom: 0.1em solid $color-border-shadow2;
  padding-left: 1.5em;
  padding-right: 0.5em;
  display: flex;
  justify-content: space-between;
  height: $header-height;

  &_left {
    @include header();
  }

  &_right {
    @include header();
  }

  &_text {
    white-space: nowrap;
    margin-left: 1em;
  }

  &_status {
    margin-left: 2em;
  }
}

$main-bg-color: $color-white;
$info-block-left-margin: 0.5em;

@mixin bottom-margin($bottom-margin) {
  &:after {
    @include remIt(height, $bottom-margin);
    content: '';
    display: block;
  }
}

$body-margin: 20;
$info-block-left-margin: 0.5em;
$table-row-padding: 1em;
$h-margin-top: 0.7em;

@media screen and (min-width: $screen-media) {
  .detailView_body {
    @include remIt(padding-top, 10);
    @include remIt(padding-left, 10);
    @include remIt(padding-right, 10);
    @include bottom-margin(30);

    > div {
      @include remIt(margin-top, $body-margin * 1);
      @include remIt(margin-left, $body-margin * 1);
      @include remIt(margin-right, $body-margin * 1);
      @include remIt(padding-bottom, $body-margin * 0.8);
      @include remIt(padding-left, ($body-margin * 1));
      @include remIt(padding-right, ($body-margin * 1));
    }
  }
}

$mobile-padding: 20px;
$table-mobile-padding: calc(#{$mobile-padding} + #{$table-row-padding} - #{$info-block-left-margin});

@media screen and (max-width: $screen-media) {
  .detailView_body {
    @include bottom-margin(15);
    @include remIt(padding, 15 10 10 0);

    > div {
      padding-left: $mobile-padding;
      padding-right: $mobile-padding;
      padding-bottom: 30px;

      &:last-child {
        padding-bottom: 0;
      }

      .table-headline {
        margin-left: $info-block-left-margin;
      }

      &:not(.table) {
        padding-left: $table-mobile-padding;
        padding-right: $table-mobile-padding;
      }
    }
  }
}

@media screen and (max-width: $small-screen-media) {
  .detailView_body {
    .driving-license-detail .memberView_driverInfo_body .memberView_driverInfo_main {
      flex-direction: column;

      div[class^='memberView_driverInfo_files_'] {
        padding: 20px 0;
      }
    }
  }
}

.detailView {
  box-shadow: 0 0 0.2em 0 $color-border-shadow3;
  background: $main-bg-color;
  margin: $page-margin;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-text-grey;
  }

  h4 {
    @include remIt(margin-bottom, 15);
    font-size: 1.1em;
  }

  h5 {
    @include remIt(margin-bottom, 10);
    font-size: 1.05em;
  }

  a {
    color: $regular-color;
  }

  .tooltip {
    position: relative;

    &_text {
      @include remIt(padding, 3 9);
      @include remIt(bottom, -30);
      @include remIt(border-radius, 3);
      font-size: 0.8rem;
      visibility: hidden;
      background-color: $color-grey-light3;
      color: $color-white;
      text-align: center;
      position: absolute;
      z-index: 1;
      box-shadow: 0.25em 0.25em 0.313em 0 $color-border-shadow4;
      font-weight: 400;
      white-space: nowrap;
      pointer-events: none;
    }
  }

  .tooltip:hover {
    .tooltip_text {
      visibility: visible;
    }
  }
}
.tile {
  .detailView_body {
    height: calc(100vh - 64px);
  }
}

.detailView_body {
  overflow: auto;
  height: calc(100vh - #{$header-height} - #{$page-margin} - #{$page-margin} - #{$page-container-padding});

  .detailView_single_headline {
    @include remIt(padding-bottom, 0);
  }
}

.detailView_body > div {
  transition: box-shadow 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $h-margin-top;
  }

  &:hover {
    animation: highlight 2s ease-in-out 0s 1;
  }

  @media screen and (max-width: $screen-media) {
    display: flex;
    flex-direction: column;

    &:hover {
      animation: none;
    }
  }

  [class$='_body'] {
    margin-left: $info-block-left-margin;
    padding-top: 0.5em;
  }

  [class$='_body_2'] {
    margin-left: $info-block-left-margin;
    display: inline-block;
  }

  &.table {
    tbody {
      .empty-cell {
        padding: 0;
      }
    }

    .mobile_label {
      display: none;
    }

    h5 {
      color: $color-text-dark3;
    }

    table {
      h4 {
        margin-bottom: 20px;
      }
    }

    td {
      max-width: 500px;
      text-align: center;
      padding-top: $table-row-padding / 2;
      padding-bottom: $table-row-padding / 2;
      padding-left: $table-row-padding;
      padding-right: $table-row-padding;

      @media screen and (min-width: calc($screen-media + 1)) {
        &.status {
          text-align: left;
        }
      }
    }

    &.type-2 {
      td {
        &:first-child {
          font-weight: bold;
        }
      }
    }

    @media screen and (max-width: $screen-media) {
      display: flex;
      flex-direction: column;

      .empty-td {
        display: none;
      }

      h4 {
        @include remIt(margin-top, 10);
        @include remIt(margin-bottom, 20);
      }

      thead {
        display: none;
      }

      &.type-2 {
        td {
          &:first-child {
            background-color: $color-light-backgroud;
            text-align: center;
          }
        }
      }

      tr {
        border: 1px solid $color-border-shadow7;
        border-bottom: 3px solid $color-border-shadow7;
        display: block;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      td {
        background-color: $color-white;
        max-width: $screen-media;
        border-bottom: 1px solid $color-border-shadow7;
        display: block;
        text-align: right;
        color: $color-text2;

        &:last-child {
          border-bottom: 0;
        }
      }

      .mobile_label {
        font-weight: bold;
        margin-right: 2em;
        display: inline;
        float: left;
      }

      table {
        width: 100%;
      }
    }

    @media screen and (min-width: calc($screen-media + 1)) {
      &.type-2 {
        td {
          &:first-child {
            text-align: left;
          }
        }

        .table-headline {
          display: none;
        }
      }

      .table-headline {
        @include flex-center;
        margin-bottom: 5px;
      }

      table {
        tbody {
          tr:not(:only-child) {
            &:nth-of-type(odd) {
              background: $color-table-bg;
            }
          }
        }
      }
    }
  }

  @keyframes highlight {
    0% {
      box-shadow: 0 0 0 1px $main-bg-color, 0 0 0 3px rgba(185, 185, 185, 0);
    }

    35% {
      box-shadow: 0 0 0 8px $main-bg-color, 0 0 0 12px rgba(185, 185, 185, 0.5);
    }

    100% {
      box-shadow: 0 0 0 8px $main-bg-color, 0 0 0 12px rgba(185, 185, 185, 0);
    }
  }
}

$dashPadding: 0.5em;

.detailView_dash {
  padding-left: $dashPadding;
  padding-right: $dashPadding;
}

.detailView_component {
  &_headline_date {
    margin-left: 0.7em;
    color: $alt-color;
    font-size: 1em;
    font-weight: normal;
  }

  &_headline_status {
    font-weight: normal;
  }
}

.detailView_table {
  display: flex;
  flex-direction: column;

  .detailView_row:last-child {
    padding-bottom: 0;
  }
}

$row-padding: 1em;

.detailView_row {
  display: flex;
  align-items: center;
  padding-bottom: $row-padding;
}

[class^='detailView_cell_type'],
[class^='detailView_td'] {
  color: $regular-color;
  font-size: 1.05em;
}

.detailView_tr {
  td {
    padding-bottom: $row-padding;

    &:last-child {
      padding-right: 0;
    }
  }

  &:last-child {
    td {
      padding-bottom: 0;
    }
  }
}

$cell-type-1-padding: 2em;

.detailView_td_type_1 {
  padding-right: $cell-type-1-padding;
}

.detailView_td_type_2 {
  padding-right: 4em;
}

.detailView_td_type_3 {
  padding-right: 3em;
}

.detailView_cell_type_1 {
  align-items: center;
  margin-right: $cell-type-1-padding;
}

.detailView_cell_type_1:last-child {
  margin-right: 0;
}

.detailView_cell_type_2 {
  align-items: end;
  min-width: 12em;
}

.detailView_cell_type_3 {
  align-items: end;
  width: 19em;
  margin-right: 1em;
}

.detailView_cell_type_2,
.detailView_cell_type_3 {
  &:last-child {
    min-width: auto;
  }
}

.detailView_cell_type_4 {
  @include remIt(margin-right, 40);
  display: flex;
  white-space: nowrap;
}

.detailView_cell_type_4:last-child {
  margin-right: 0;
  display: flex;
  white-space: normal;
  word-break: break-all;
}

.detailView_cell_type_5 {
  color: $alt-color;
  margin-bottom: 0.5em;
  margin-left: 0.7em;

  span {
    color: $regular-color;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
