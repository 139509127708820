$radius: 5px;
$marginToCenter: $radius / 2;

.shapeDot {
  background-color: $color-secondary-light;
  height: $radius;
  width: $radius;
  border-radius: 50%;
  margin-top: -$marginToCenter;
  margin-left: -$marginToCenter;
}
