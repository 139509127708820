.contract-general-info {
  .fieldErrorMsg {
    position: absolute;
    margin-top: 5px;
  }

  .boxedInput--date {
    cursor: pointer;

    > * {
      cursor: inherit !important;
    }
  }

  > .sc-details {
    > * {
      margin-bottom: 30px;
    }
  }

  > .sc-title {
    font-size: 13px;
    color: #414546;
    margin-bottom: 20px;

    .sc-value {
      margin-left: 5px;
      color: #333;
      text-transform: none;
      font-weight: bold;
    }
  }

  .contractForm_definitionList {
    color: #414546;

    &_definition {
      margin-left: 10px;
    }
  }
}
