.vouchers-v2 {
  .voucherGroupDetail > .container > .row {
    display: flex;
    flex-direction: column-reverse;

    > div {
      width: 100%;
    }

    > div:not(:first-child) {
      margin-bottom: 30px;
    }
  }
}
