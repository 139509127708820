.memberDetail_information_group_bloc {
  width: 70%;
  display: inline-block;
}

.memberDetail_IdentityFile {
  display: inline-block;
  height: 200px;
  margin: 0;
  text-align: right;
  vertical-align: top;
  width: 33%;

  img {
    max-width: 100%;
  }
}
