.siteDetailsPage {
  &_content {
    font-family: $main-font;
    padding: 15px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  .siteFormWrapper {
    max-width: 100%;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }
}

.parking_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
