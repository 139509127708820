.addBookingConfirmationForm {
  &_title {
    margin: 0 0 15px;
    text-align: center;
  }

  &_row {
    margin: 15px 0;
  }

  .addBookingConfirmationForm_button {
    width: 100%;
  }

  &_textarea {
    height: 110px;
    resize: none;
  }
}
