.column-list {
  box-shadow: rgba(30, 30, 30, 0.02) 10px 0 10px 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 15px 0 0;

  > .sc-body > .sc-title {
    margin-bottom: 10px;
  }

  > .sc-body > .sc-items {
    .sc-item-wrap {
      position: relative;

      &:hover,
      &.active {
        + div:after {
          visibility: hidden;
        }
      }

      &:not(.active):not(:hover) {
        border-color: transparent;

        &:after {
          position: absolute;
          height: 100%;
          width: 100%;
          content: '';
          z-index: 1;
        }

        &:only-child:after {
          border-bottom: 1px solid $color-list-selected-item;
          top: 2px;
        }

        &:not(:first-child):not(:only-child):after {
          border-top: 1px solid $color-list-selected-item;
          bottom: 2px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

.list-wrap {
  padding: 15px 15px 0;
}

@media only screen and (max-width: 767px) {
  .column-list > .sc-head {
    .sc-chips-wrap {
      display: none;
    }
  }
}
