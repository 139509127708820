.numberResultsSelectForm {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 12px;
  color: $color-text-clear-dark;

  &_label {
    display: inline-block;
    margin-right: 10px;
  }

  &_labelText {
    @include remIt(font-size, 11);
    display: inline-block;
    margin-left: 10px;
    color: $color-primary-darkest;

    &_rowslabelText {
      font-size: 12px;
      color: $color-text-clear-dark;
    }
  }
}
