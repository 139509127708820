.sk-circle .sk-child:before {
  background-color: $color-secondary;
}

.sideMenu_sections {
  margin: 0;
}

// ------ Login page ------

.loginBox {
  padding: 150px 45px 60px;
  background-repeat: no-repeat;
  background-position: center 40px;
}

// ------ Header ------
.header {
  &_subItems {
    @include box-shadow(3px 4px 8px rgba(map-get($headerNav, headerNav-subItems-shadow), 0.5));
  }
}

.tableView_headCell_content--sortable {
  &._is_sortReversed {
    .tableView_headCell_action_centered:after {
      right: -30px;
    }
  }

  .tableView_headCell_action_centered:after {
    right: -30px;
  }
}

.smartcardsDetail {
  &_flatButton {
    color: $color-secondary !important;
  }
}

.header-superCompanyEditing:after {
  background-color: $color-white;
}

.vehiclePlanningCreateStatus {
  .VehiclePlanningEditBooking_col1Row2 {
    .boxedSelect:focus::-ms-value {
      background-color: rgba(#262626, 0.6);
    }

    .boxedSelect {
      background: $color-white;

      * {
        background: $color-white;
        color: $color-black;
      }
    }
  }
}

.sideMenuMobile_logo {
  content: url('/img/logo-dark.svg');
  max-width: 110px;
}

.sideMenu_logo {
  content: url('/img/logo-white.svg');
  max-width: 110px;
}
