.loginPage {
  @include flex-center;
  background-color: $color-primary-darkest;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;

  &_content {
    width: 100%;
  }

  &_title {
    @include remIt(font-size, 20);
    @include remIt(line-height, 23);
    margin-bottom: 35px;
    color: $color-primary-darkest;
    text-align: center;
  }

  &_form.hide {
    display: none;
  }
}
