.feedbackDetailPage {
  &_head {
    display: flex;
    justify-content: flex-end;
  }

  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
    font-weight: bold;
    display: inline-block;
  }

  &_actionButton {
    float: right;
    width: 200px;
  }
}

.feedbackDetail {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: $color-white;
  padding: 30px;
  margin-top: 20px;

  &_title {
    @include remIt(font-size, 13);
    text-transform: uppercase;
    font-weight: 200;
    margin-top: 0;
  }

  &_line {
    @include flexbox();
    margin: 30px 0;
  }

  &_item {
    @include flex(1);
    @include remIt(font-size, 13);

    &_label {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
    }

    &_result {
      margin-top: 15px;
      display: block;
      font-weight: 100;
    }

    &--damagePicture {
      max-width: 250px;
      margin-right: 45px;
    }

    &_link {
      color: $color-primary-darkest;
      text-decoration: underline;
    }
  }

  &_line--triple &_item {
    @include flex(none);
    width: 33.3333333%;
  }

  &_damagePicture {
    margin: 0;

    &_img {
      max-width: 100%;
    }
  }

  &_actionsButton {
    min-width: 218px;
    float: right;
    @include remIt(margin-top, 15);
    @include remIt(margin-right, 10);
  }

  &_tableViewWrapper {
    width: 100%;
  }

  .tableView_cell {
    word-break: break-all;
  }

  .report-image {
    overflow: hidden;
    margin: 0 5px;

    span {
      display: block;
    }

    img {
      max-width: 100%;
      display: block;
    }

    .image-preview-component .external-link-icon {
      top: 2px;
      right: 2px;
    }
  }
}
