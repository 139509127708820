.pageManagementHeader {
  display: flex;
  justify-content: flex-end;

  &_titleWrapper {
    @include table-cell(top);
    opacity: 0;
  }

  &_title {
    @include remIt(font-size, 11);
    @include remIt(line-height, 12);
    display: block;
    background-color: $color-primary3-alt;
    text-transform: uppercase;
    padding: 9px 10px;
    font-weight: 600;
  }
}
