.bookingsPage {
  &_bookingListIcon_tootlip {
    background: black;
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 3px;
    text-align: left;
    position: absolute;
    top: -5px;
    display: flex;
    left: 55px;
    flex-direction: column;
    color: white;
    z-index: 50;

    span.inline {
      min-width: 150px;

      span {
        &:nth-child(3) {
          padding-left: 5px;
        }
      }
    }
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  &_management {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_firstItemWrap {
    display: flex;
    align-items: center;
  }

  &_listRow {
    height: 55px !important;
  }

  &_doubleColumnWrap {
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.7);

    svg {
      margin: -4px 5px;
      fill: rgba(0, 0, 0, 0.65) !important;
      width: 18px !important;
      height: 18px !important;
    }
  }

  &_content {
    @include advanced-list-page-padding;
    height: 100%;
    font-family: $main-font;
  }

  &_title {
    @include pageTitle();
  }

  &_filters {
    margin-bottom: 15px;
    width: 100%;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }

  &_retryAllButton,
  &_createBookingButton {
    float: right;
    margin-left: 5px;
  }

  &_actionButton {
    float: right;
    margin-left: 5px;
    cursor: pointer;
  }

  &_managementHeader {
    padding-bottom: 25px;
  }

  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_btnContainer {
    display: inline-block;
  }
}

.bookings_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}

.bgCompleted {
  background: $color-completed;
}

.bgCanceled {
  background: $color-canceled;
}

.bgScheduled {
  background: $color-scheduled;
}

.bgProgress {
  background: $color-progress;
}

.bgPrebooked {
  background: $color-prebooked;
  padding-top: 2px;
}

.bgPerson {
  background: #279ddf;
}

.bgBusiness {
  background: #77554a;
}

.bgGroup {
  background: #6744b1;
}

.bgCar {
  background: #e32a67;
}
