// EkRadio constructor
// ==========================================================================

$radio: (
  radio-text-size: 11
);

// Constructor
// ==========================================================================

.ekRadio_wrapper_disabled {
  .ekRadio_label {
    cursor: not-allowed;
  }
}

.ekRadio {
  display: none;

  &_wrapper {
    position: relative;

    &--col {
      display: inline-block;
    }
  }

  &_wrapper--multiple {
    .ekRadio_label {
      margin-right: 10px;
    }
  }

  &_title {
    @include remIt(font-size, map-get($radio, radio-text-size));
    display: block;
    color: $color-primary-darkest;
    text-transform: uppercase;
  }

  $label-font-size: 12;

  &_label {
    @include remIt(font-size, 12);
    display: inline-block;
    color: $color-primary-darkest;
    cursor: pointer;

    &:before {
      @include transition(all 0.2s ease-in);
      @include border-radius(50%);
      @include size(16px);
      content: '';
      display: inline-block;
      background-color: $color-white;
      border: 1px solid $color-primary2;
      vertical-align: text-bottom;
      margin-right: 10px;
    }
  }

  &-input-container {
    .tip-container {
      display: inline-block;
      margin-left: 5px;
    }

    .tip {
      @include border-radius(50%);
      display: inline-flex;
      height: 1.5em;
      width: 1.5em;
      align-items: center;
      justify-content: center;
      background-color: $color-grey-dark;
      color: $color-white;
      padding: 0 6px;
      font-size: 12px;
      cursor: alias;
    }
  }

  &_info {
    @include remIt(font-size, $label-font-size - 1);
    margin-left: 0.5em;
    color: $color-text-dark3;
  }

  &:checked {
    + label:before {
      @include remIt(font-size, map-get($radio, radio-text-size));
      @include box-shadow(0 0 0 3px $color-white inset);
      content: '';
      display: inline-block;
      background-color: $color-secondary-light;
    }
  }

  &_disabled:checked {
    + label:before {
      background-color: #8c8c8c;
    }
  }
}
