.auto-complete {
  display: inline-block;
  position: relative;

  .sc-header {
    margin: 0.3em;
    padding: 0.3em 0.5em;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    color: #444;

    .sc-value {
      font-weight: normal;
      font-style: italic;
    }
  }

  .sc-suggestions {
    margin: 0.3em 0;
    overflow-y: auto;
    max-height: 180px;

    li {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.auto-complete input {
  height: 40px;
  padding-left: 20px;
  font-size: 1em;
  color: #7b7b7b;
  border-radius: 8px;
  border: 0;
  outline: 0;
  background-color: #f1f3f4;
}

.auto-complete input::placeholder {
  color: rgba(123, 123, 123, 0.5);
  transition: all 0.3s ease;
}

.auto-complete ul {
  position: absolute;
  overflow-y: hidden;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0.5em 0 0;
  border-radius: 0.6em;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
  border: 1px solid rgba(33, 33, 33, 0.07);
  z-index: 1000;
  outline: 0;
}

.auto-complete ul:empty,
.auto-complete ul[hidden] {
  display: block;
  opacity: 0;
  transform: scale(0);
}

.auto-complete ul li {
  margin: 0.3em;
  padding: 0.3em 0.5em;
  list-style: none;
  text-align: left;
  font-size: 1em;
  color: #212121;
  border-radius: 0.35em;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.auto-complete ul li::selection {
  color: rgba(#fff, 0);
  background-color: rgba(#fff, 0);
}

.auto-complete ul li:hover {
  cursor: pointer;
  background-color: rgba(123, 123, 123, 0.1);
}

.auto-complete ul li mark {
  background-color: transparent;
  color: #ff7a7a;
  font-weight: 700;
}

.auto-complete ul li mark::selection {
  color: rgba(#fff, 0);
  background-color: rgba(#fff, 0);
}

.auto-complete ul li[aria-selected='true'] {
  background-color: rgba(123, 123, 123, 0.14);
}
