.list-wrap {
  height: 100%;
  min-width: 100%;
  width: max-content;
  font-size: smaller;
  display: flex;
  padding: 25px;

  > section:not(:last-of-type) {
    margin-right: 25px;
  }
}

@media (min-width: 768px) {
  .list-wrap .column-list .sc-back {
    display: none;
  }
}

@media (max-width: 767px) {
  .list-wrap {
    width: 100%;
    padding: 0;

    input {
      @include remIt(font-size, 16);
    }

    > section {
      width: 100%;
      border-radius: 0;

      &:not(:last-of-type) {
        display: none;
      }
    }
  }
}
