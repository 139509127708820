.smartcardsPage,
.smartcardsEventsPage {
  .smarctcardsListIcon {
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;

    svg {
      color: white !important;
    }
  }
}

.smartcardsPage {
  &_content {
    @include advanced-list-page-padding;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: $main-font;
  }

  &_title {
    @include pageTitle();
  }

  &_filters {
    margin-bottom: 15px;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }

  &_actionButton {
    float: right;
    margin-left: 5px;
  }

  &_managementHeader {
    padding-bottom: 25px;
  }

  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }
}

.smartcardsPage_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
