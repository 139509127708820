.iphone-x {
  position: relative;
  margin: 40px;
  width: 360px;
  height: 780px;
  overflow: scroll;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 0 11px #1f1f1f, 0 0 0 13px #191919, 0 0 0 20px #111;

  &:after {
    content: ' ';
    position: sticky;
    bottom: 5px;
    top: 770px;
    width: 140px;
    height: 4px;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin: 10px 90px 0;
  }

  .heading {
    position: sticky;
    top: 0;
    width: 320px;
    display: block;

    &:before {
      content: '';
      top: -10px;
      width: 180px;
      height: 35px;
      background-color: #000000;
      border-radius: 10px;
      position: relative;
      display: flex;
      margin: auto;
    }
  }

  i,
  b,
  s,
  span {
    position: absolute;
    display: block;
    color: transparent;
    z-index: 5;
  }

  // speaker
  i {
    top: 0;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0 -3px 3px 0 rgba(256, 256, 256, 0.2);
  }

  // camera
  b {
    left: 10%;
    top: 0;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0 -3px 2px 0 rgba(256, 256, 256, 0.2);

    &:after {
      content: '';
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.5);
    }
  }
}
