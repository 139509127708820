.customFieldCreation {
  .buttonCancel {
    @include remIt(margin-right, 10);
  }

  .boxedSelect {
    padding: 10px 34px 10px 10px;
  }

  .companyDetail_title_id {
    text-transform: lowercase;
  }

  .vehicle-usages {
    width: 100%;

    .list-select-component .list-select-container {
      flex-basis: 50%;
    }
  }
}
