.payback-forms {
  .payback-submit {
    text-align: right;
  }

  .radio-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .ekRadio_wrapper {
      margin-top: 0.5em;
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 1.5em;
      }
    }
  }
}
