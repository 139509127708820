.backUserRoles {
  .backUserForm_roles {
    border: 1px solid $color-primary3-alt;
    border-radius: 2px;
    padding: 15px 15px 20px 20px;

    .formRow_item {
      flex: auto 0 1;

      &:not(:first-child) {
        margin-left: 60px;
      }
    }
  }
}

.backUserForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 30px;
  }

  &_title {
    @include remIt(font-size, 13);
    @include remIt(line-height, 16);
    color: $color-primary-darkest;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: bold;
  }

  &_row {
    margin-bottom: 25px;
  }

  &_ekRadioTitle {
    padding-bottom: 3px;
  }

  &_ekRadioContainer-2 {
    @include box-columns(2, 20px);
  }

  &_ekRadioContainer-3 {
    @include box-columns(3, 20px);
  }

  &_ekRadioContainer-4 {
    @include box-columns(4, 20px);
  }

  &_ekRadioContainer-5 {
    @include box-columns(5, 20px);
  }

  &_ekRadioWrapper {
    margin-top: 5px;
  }

  &_boxedSelectWrapper {
    margin-right: 10px;
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    margin-right: 20px;
  }
}

.fieldErrorMsg--backUserForm {
  margin-top: 10px;
  width: 100%;
}
