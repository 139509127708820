// Base Title Component
// ==========================================================================

$title: (
  title-default-color: $color-secondary-light,
  title-variant1-color: $color-primary-darkest,
  title-variant2-color: $color-tertiary
);

// Constructor
// ==========================================================================

.baseTitle {
  @include baseTitle();
  color: map-get($title, title-default-color);
  line-height: 20px;

  &--variant1 {
    color: map-get($title, title-variant1-color);
  }

  &--variant2 {
    color: map-get($title, title-variant2-color);
  }

  .round_icon {
    vertical-align: top;
  }
}
