.booking-tile {
  &_headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    margin: 0;
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;

    & .row-info,
    & .full-row-info {
      display: flex;
      flex-direction: column;
      width: 50%;
      min-height: 50px;
      padding-bottom: 5px;

      .label {
        color: grey;
        font-size: 12px;
      }

      .value {
        color: black;
        font-weight: 500;

        &.inline {
          display: flex;

          .fuel-gauge {
            margin-left: 10px;
            cursor: auto;
          }
        }

        a {
          color: black;
        }

        .link {
          cursor: pointer;
        }
      }
    }
  }
}
