$paid-color: #24b282;
$unpaid-color: #fe5001;
$retry-color: $color-to-review;

.v2-invoice-details {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .sc-more-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > * {
      background: white;
      border-radius: 4px;
      flex-basis: 400px;
      min-width: calc(50% - 5px);
      flex-grow: 1;
    }
  }

  .booking-info-v2 {
    padding: 0 15px;
  }

  .sc-invoice-info {
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    .sc-multi-item {
      display: flex;
      flex-direction: column;

      > *:first-child + * {
        filter: unset;
      }
    }

    .sc-item {
      .sc-key {
        color: grey;
        font-size: 12px;
      }

      .sc-value {
        font-weight: bold;
      }
    }

    .sc-status {
      font-weight: normal;

      &.button {
        border: 0;
        padding: 0;
        margin: 0;
        background: transparent;
      }

      &.paid {
        color: $paid-color;
      }

      &.unpaid {
        color: $unpaid-color;
      }

      &.retry {
        color: $retry-color;
        text-decoration: underline;
      }
    }
  }

  .invoice-pdf-container {
    min-height: 400px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 10px;
    border-width: 0;
    flex-grow: 1;
    width: 100%;
  }
}
