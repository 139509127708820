.company-email-setup-form {
  margin-top: 15px;

  .companyForm_row {
    margin-bottom: 30px;

    > .file-upload {
      margin-bottom: 0;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    .button {
      max-width: 200px;
      width: 100%;

      $m-margin: 15px;

      &:first-child {
        margin-right: $m-margin;
        margin-left: 0;
      }

      &:last-child {
        margin-left: $m-margin;
        margin-right: 0;
      }
    }
  }

  .field-error {
    margin-top: 5px;
    position: absolute;
    right: 0;
  }
}

.confirm-container {
  max-width: 500px;

  .buttons {
    display: flex;
    justify-content: space-around;

    .button {
      width: 100%;

      $m-margin: 10px;

      &:first-child {
        margin-right: $m-margin;
      }

      &:last-child {
        margin-left: $m-margin;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 1.1em;
  }

  $s-margin: 5px;

  .body {
    margin: 20px 0 20px + $s-margin;
    text-align: center;
    color: $color-text-dark;
  }
}
