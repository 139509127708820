.memberView_companyInfo {
  h4 {
    &.sub-title {
      margin-top: 30px;
    }
  }

  &_body {
    display: flex;
  }

  .detailView_table:empty {
    display: none;
  }

  .detailView_table {
    max-width: 500px;
  }

  .detailView_table:first-child {
    @include remIt(margin-right, 60);
  }
}
