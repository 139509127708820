.updateBookingPrice {
  &_row {
    margin-bottom: 15px;
    width: 240px;
  }

  &_actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  &_response {
    color: #29b129;
    font-size: 14px;
    border-radius: 2px;
    margin-top: 15px;
    position: relative;
  }
}

.updateBookingPriceWrapper {
  .checkboxMaterial {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.marginTop10 {
  margin-top: 10px;
}
