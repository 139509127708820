.booking-events-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  .table-shadow {
    box-shadow: $advanced-list-container-shadow;
    overflow-x: auto;
  }

  table {
    width: 100%;
    background: $color-white;
  }

  thead {
    border-bottom: $advanced-list-header-border;
  }

  tbody {
    tr {
      text-align: left;
      border-bottom: 1px solid $advanced-list-header-background;

      &:hover {
        background: $color-white-alt;
      }
    }
  }

  $cell-padding: 10px;
  $child-padding: $cell-padding * 2;

  .icon-container {
    display: flex;
    margin-left: 5px;
    width: 24px;
    height: 24px;
  }

  svg {
    color: $color-text-dark2 !important;
  }

  th {
    color: $color-text-dark;
    background: $advanced-list-header-background;
    text-align: left;
    padding: $cell-padding;

    &:not(:hover) {
      .sort-icon {
        display: none !important;
      }
    }

    &:first-child {
      padding-left: $child-padding;
    }

    &:last-child {
      padding-right: $child-padding;
    }
  }

  td {
    font-size: 0.95em;
    color: $color-text-dark;
    padding: $cell-padding;
    max-width: 500px;

    &:first-child {
      padding-left: $child-padding;
    }

    &:last-child {
      padding-right: $child-padding;
    }

    &.event-status-error {
      color: $color-error-mid;
    }

    &.event-status-good {
      color: $color-good;
    }

    &.event-status-other {
      color: $color-to-review;
    }

    &:empty {
      padding: 0;
    }
  }
}
