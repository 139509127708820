$color-reparation: #ffa000;
$color-ready: #24b282;
$color-inactive: #929292;
$color-cleaning: #17b7c4;
$color-intervention: #fe5001;
$color-maintenance: #781a04;
$color-rented-in-rrs: $color-error;

$tableView: (
  tableView-status: (
    'REPARATION': $color-reparation,
    'READY': $color-ready,
    'INACTIVE': $color-inactive,
    'CLEANING': $color-cleaning,
    'INTERVENTION': $color-intervention,
    'MAINTENANCE': $color-maintenance,
    'RENTED_IN_RRS': $color-rented-in-rrs
  )
);

.fleetPage {
  &_listImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: fill;
    margin-right: 15px;
  }

  &_content {
    @include advanced-list-page-padding;
    height: 100%;
    font-family: $main-font;
  }

  &_title {
    @include pageTitle();
  }

  &_filters {
    margin-bottom: 15px;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }

  &_management {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &_managementHeader {
    padding-bottom: 15px;
  }

  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_actionButton {
    float: right;
    margin-left: 5px;
    cursor: pointer;
  }
}

.fleet_statusTypeCell {
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

@each $status, $statusColor in map-get($tableView, tableView-status) {
  .fleet_statusTypeCell--#{$status} {
    color: $statusColor;

    &:before {
      background-color: $statusColor;
    }
  }
}

.fleet_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
