.loginForm {
  &_boxedInput {
    margin-bottom: 30px;

    .boxedInput_button {
      width: auto;
    }
  }

  &_forgotPassword {
    @include remIt(font-size, 14);
    @include remIt(line-height, 17);
    @include basicClickable;

    text-decoration: underline;
    display: inline-block;
    color: $color-secondary;
    padding: 10px 15px;

    &.solo {
      margin: 0 auto;
    }

    &:hover {
      background: $color-bg-light-clear;
    }
  }

  &_actionWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_redirectAction_container,
  &_actionWrap {
    margin-top: 30px;
  }

  &_redirectAction_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_checkbox {
    white-space: nowrap;

    .labeledCheckbox {
      display: flex;
      align-items: center;
    }
  }

  &_mainErrorMsg {
    @include remIt(font-size, 14);
    color: $color-error;
    margin-bottom: 10px;
  }
}

.fieldErrorMsg--loginForm {
  margin-bottom: 2px;
}
