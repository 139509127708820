$vp-uppercase-font-size: 12px;
$background: #e9e8e8;
$hour-background: #fff;
$btn-bg: #e5e5e5;
$firstColumnWidth: 290px;
$row-height: 65px;

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .new-vehicle-planning-edit-booking_col1Row2 .boxedSelect {
    color: black;
  }
}

.vp_memberType {
  &_boxedSelectBlock {
    margin: 15px 0 8px;
    line-height: 11px;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    .boxedSelect_label {
      display: flex;

      span {
        font-size: $vp-uppercase-font-size;
      }
    }
  }

  &_boxedSelectWrapper {
    width: 100%;
  }
}

.extend-action {
  .ekButton--reverse.cancel {
    margin-right: 15px;
  }
}

.new-vehiclePlanningSetup {
  .addBookingConfirmationForm_button {
    width: auto !important;
  }

  &_sideMenuOpen {
    width: calc(100% - #{$side-menu-width}) !important;

    @media (max-width: 1200px) {
      width: 100% !important;
    }
  }

  &_container {
    color: rgba(0, 0, 0, 0.8);
    background: white;
    margin: 15px;
    margin-left: 35px;
    margin-top: 100px;
    position: relative;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
    height: calc(100vh - 205px);
    overflow: scroll;
    overflow-x: hidden;
    padding-top: 70px;
  }

  &_changePeriodWrap {
    display: flex;
    align-items: center;
    z-index: 50;
    margin-right: 15px;
  }

  &_container_inline {
    height: 60px;
    padding: 0 15px;
    background-color: $background;
    display: flex;
    align-items: center;
    position: fixed;
    top: 70px;
    z-index: 99;
    width: 100%;

    .boxedSelectWrapper:after {
      display: none;
    }

    .boxedSelectWrapper {
      border: 0;
    }

    .boxedSelectWrapper select {
      font-size: 16px !important;
      color: rgba(0, 0, 0, 0.8) !important;
    }

    .new-vehiclePlanningSetup_buttonWrap {
      margin-top: 0;
      margin-left: auto;

      .vp-filters-wrap {
        display: flex;
        align-items: center;

        .auto-complete > input.sc-search {
          padding-left: 30px;
        }

        .sc-search-icon {
          display: inline-flex;
          align-items: center;
          height: 40px;
        }

        .sc-filters-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          margin-right: 10px;
          width: 35px;

          > span {
            display: inline-flex;
          }

          svg {
            cursor: pointer;
            color: darken($color-list-icon-button, 20%) !important;
            height: auto !important;
            width: 100% !important;
            border: 2px solid $color-list-selected-item;
            padding: 3px;
            border-radius: 4px;

            &:hover {
              color: darken($color-list-icon-button, 40%) !important;
              border-color: $color-list-selected-hover;
            }
          }

          &.sc-active {
            svg {
              color: white !important;
              border-color: $color-list-active-item;
              background: $color-list-active-item;
            }
          }
        }
      }

      .flatButton {
        background: $btn-bg !important;
        line-height: normal !important;

        &:hover {
          background-color: white;
        }

        div span {
          text-transform: initial !important;
        }
      }

      .filter-text {
        height: 36px;
        margin-right: 10px;
        border: 1px solid $btn-bg;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-image: url('#{$img-path}search.svg');
        background-position: 5px center;
        padding-left: 30px;

        &:focus {
          outline: none;
        }
      }
    }

    .new-vehiclePlanningSetup_formRow {
      margin-bottom: 0;

      .period {
        width: 230px !important;
        height: 38px;

        > div {
          border-top: 1px solid $btn-bg;
          border-bottom: 1px solid $btn-bg;

          &:first-child {
            border-left: 1px solid $btn-bg;
            border-right: 1px solid $btn-bg;
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-left: 1px solid $btn-bg;
            border-right: 1px solid $btn-bg;
            border-radius: 0 4px 4px 0;
          }
        }

        label {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          width: 100% !important;
          height: 36px;
        }

        input:checked + div label {
          background-color: $btn-bg;
        }
      }

      .boxedInput--date > div:first-child {
        width: 100%;
      }

      .boxedInput--date > div:last-child {
        width: 100%;
        height: 50px;
        top: -50px;
        position: relative;
      }

      .boxedInput--date,
      .boxedInput .boxedInput--date {
        background-image: none !important;
        height: 100%;
        width: 100%;

        input {
          width: 100% !important;
          font-size: 14px !important;
          font-weight: bold !important;
        }
      }
    }

    form {
      display: flex;
      align-items: center;
      // width: 100%;
    }
  }

  &_wrap_inline {
    width: 100%;
    display: flex;
    background-color: white;
    font-size: small;
    // box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
    transition: 0.3s ease;
  }

  &_wrap {
    background: white;
    width: 400px;
    padding: 25px;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
  }

  &_buttonWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 35px;
  }

  &_formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: row;

    &.usages {
      width: 40%;
    }

    .usage {
      div {
        div {
          width: auto !important;
          float: none !important;
        }
      }
    }

    .boxedInput {
      border: 0;
      cursor: pointer;
    }

    .boxedSelectWrapper {
      width: 150px;
    }
  }

  &_label {
    color: rgba(0, 0, 0, 0.8);
  }
}

.menu-sites {
  div.new-vehicle-planning_parking {
    &_name {
      display: flex !important;
      cursor: pointer;
      margin-left: 10px;
      margin-right: 5px;
      max-width: 300px;
      padding-left: 5px;
      border-radius: 3px;

      &:hover {
        background-color: lightgrey;
      }
    }
  }
}

.new-vehicle-planning {
  background-color: $background;

  &_date_block {
    width: 115px;
  }

  .flatButton {
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 3px;
  }

  &.mainContainer_content {
    width: calc(100% - #{$side-menu-width});
  }

  .legend-wrapper {
    position: sticky;
    top: 140px;
    margin: 0 15px;
    font-size: 12px;

    .legend {
      height: 40px;
    }

    &--handler {
      display: flex;
      align-items: center;
    }

    span {
      position: relative;
      margin: 0 20px 0 0;

      > span {
        padding-left: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        top: 0;
        border-radius: 13px;
      }

      &.completed::before {
        background-color: $color-completed;
      }

      &.prebooked::before {
        background-color: $color-prebooked;
      }

      &.scheduled::before {
        background-color: $color-scheduled;
      }

      &.progress::before {
        background-color: $color-progress;
      }

      &.delayed::before {
        background-color: $color-delayed;
      }
    }
  }

  &_closeIconWrap {
    display: flex;
    margin-left: auto;

    svg {
      fill: rgba(0, 0, 0, 0.7) !important;
      cursor: pointer;
    }

    .filter {
      display: inline-flex;
      padding: 5px;
      width: 90%;

      svg {
        margin-top: 7px;
      }
    }
  }

  &_closeIconAbosuluteWrap {
    position: absolute;
    right: 0;
    top: 0;

    svg {
      fill: rgba(0, 0, 0, 0.7) !important;
      cursor: pointer;
    }
  }

  &_statusDetail_buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -10px;
    margin-right: 15px;
  }

  &_usageSelector {
    margin: 15px 0;

    &.vehicle-usage-selector {
      max-width: 35%;
    }
  }

  &_typeWrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .ekRadio_block {
      width: 100%;
    }

    &.fullrow {
      display: block;

      .checkboxMaterial {
        margin-top: 10px;

        label {
          line-height: 24px !important;
        }
      }
    }

    .checkboxMaterial {
      label {
        line-height: 16px !important;
      }
    }
  }

  &_card_headerStatus svg {
    fill: white !important;
  }

  &_card_line {
    display: flex;
    padding: 10px 30px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;

    .member-status {
      @include remIt(line-height, 20);

      display: inline-block;
      border-radius: 3px;
      padding: 0 8px;
      color: $color-white;
    }
  }

  &_card_lineIcon svg {
    fill: rgba(0, 0, 0, 0.7) !important;
    text-decoration: none;
    margin-right: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  &_card_lineData a {
    font-size: 14px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);

    &:hover {
      text-decoration: underline;
    }

    &.actions {
      color: #3972de;
      text-transform: uppercase;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  &_card_lineIcon {
    width: 75px;
    padding-right: 10px;
  }

  &_card_header {
    // height: 145px;
    background: $color-completed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // margin-bottom: 15px;

    .new-vehicle-planning_card_headerStatus {
      display: flex;
      align-items: center;
      margin-top: -12px;
    }

    .new-vehicle-planning_card_headerStatus svg {
      margin-right: 10px;
    }

    .new-vehicle-planning_vehicleRow_firstField {
      min-width: auto;
      width: auto;
      display: flex;
      margin: 0 15px;
      padding: 5px;
    }

    .new-vehicle-planning_card_headerClose {
      margin-top: -12px;
      margin-right: -12px;
    }

    .new-vehicle-planning_vehicleRow_vehicleInfo {
      font-size: 12px;
    }

    .new-vehicle-planning_vehicleRow_registrationNumber {
      font-size: 14px;
      margin-top: 5px;
    }

    img {
      width: 75px;
    }

    .new-vehicle-planning_vehicleRow_fuelLevel {
      display: none;
    }
  }

  &_card_headerTopLine {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: white;

    svg {
      color: inherit !important;
      fill: currentColor !important;
    }
  }

  &_card_container {
    position: fixed;
    top: 0;
    right: 10px;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
    justify-content: center;
    align-items: center;
  }

  &_card_opened {
    display: flex;
  }

  &_card_wrap {
    .mainContainer_content {
      .pageContainer {
        padding-top: 0;
        height: 100%;

        .detailView {
          margin: 0;

          &_header {
            position: relative;
            width: 100%;
          }

          &_body {
            overflow: scroll;
            font-size: 0.9em;
          }

          &.tile {
            [class^='memberView_'] {
              padding: 0;
            }

            .detailView_header_left {
              .tooltip-container {
                padding: 2px;

                svg {
                  width: 2em !important;
                }
              }
            }

            .detailView_header_text {
              margin-left: 0;
              font-size: 0.8em;

              span {
                &.booking_status_pre_booked {
                  background: black;
                  border-radius: 3px;
                  padding: 1px 8px 2px;
                }
              }
            }

            .driving-license-detail,
            .memberView_basicInfo {
              max-width: 340px;
            }

            .driving-license-detail .memberView_driverInfo_body .memberView_driverInfo_main,
            .memberView_driverInfo_main {
              flex-direction: column;

              .detailView_cell_type_1:last-child {
                text-overflow: ellipsis;
              }

              [class^='memberView_driverInfo_files_'] {
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .detailView_body {
              padding: 0.9375rem 0.625rem 0.625rem 0;

              > div {
                padding-bottom: 15px;
              }

              .memberView_basicInfo_body {
                .detailView_cell_type_1:last-child {
                  word-break: break-all;
                }
              }

              .memberView_commentsInfo_body {
                .detailView_row {
                  flex-direction: column;
                  align-items: flex-start;

                  span {
                    padding: 5px 0;
                  }
                }
              }
            }

            .memberView_ProfileInfo {
              .detailView_cell_type_3 {
                width: 9em;
              }
            }

            .bookingTransactionInfo,
            .bookingTransactionInfo > div,
            .bookingTripInfo,
            .bookingTripInfo > div {
              display: flex;
              flex-direction: column;

              &.table {
                tbody {
                  .empty-cell {
                    padding: 0;
                  }
                }

                .mobile_label {
                  font-weight: bold;
                  margin-right: 2em;
                  display: inline;
                  float: left;
                }

                table {
                  width: 100%;

                  h4 {
                    margin-bottom: 20px;
                  }
                }

                h5 {
                  color: $color-text-dark3;
                }

                td {
                  max-width: 500px;
                  text-align: right;
                  padding-top: $table-row-padding / 2;
                  padding-bottom: $table-row-padding / 2;
                  padding-left: $table-row-padding;
                  padding-right: $table-row-padding;

                  @media screen and (min-width: #{$screen-media + 1}) {
                    &.status {
                      text-align: right;
                    }
                  }
                }
              }

              .empty-td {
                display: none;
              }

              h4 {
                @include remIt(margin-top, 10);
                @include remIt(margin-bottom, 20);
              }

              thead {
                display: none;
              }

              &.type-2 {
                td {
                  &:first-child {
                    background-color: $color-light-backgroud;
                    text-align: center;
                  }
                }
              }

              tr {
                border: 1px solid $color-border-shadow7;
                border-bottom: 3px solid $color-border-shadow7;
                display: block;
                margin-bottom: 1em;

                &:last-child {
                  margin-bottom: 0;
                }
              }

              td {
                background-color: $color-white;
                max-width: $screen-media;
                border-bottom: 1px solid $color-border-shadow7;
                display: block;
                text-align: right;
                color: $color-text2;

                &:last-child {
                  border-bottom: 0;
                }
              }

              .table-headline {
                display: inline-block;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  &_openhours {
    height: 50px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    color: grey;
    z-index: 21;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 10px;

    .start {
      align-items: flex-start;
      padding: 10px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .end {
      align-items: flex-end;
      padding: 10px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_closedhours {
    border-radius: 1px;
    background: repeating-linear-gradient(-45deg, #dddbdb, #dddbdb 1px, #fff 2px, #fff 10px);
    position: absolute;
    top: 5px;
    bottom: 0;
    height: $row-height;
    z-index: 23;
    text-align: center;
    transition: 0.3s ease;
    border-left: 1px dashed #dddbdb;
    border-right: 1px dashed #dddbdb;
  }

  &_status {
    height: calc(#{$row-height} - 5px);
    border-radius: 3px;
    background: #767676;
    position: absolute;
    top: 5px;
    padding-left: 5px;
    color: white;
    z-index: 24;
    min-width: 10px;

    .status-detail {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.padd-right {
        padding-right: 5px;
        align-items: flex-end;
      }

      &.padd-left {
        padding-right: 5px;
        align-items: flex-start;
      }
    }

    span {
      border-radius: 5px;
      position: relative;
      display: block;
      color: white;

      &.type {
        font-weight: bold;
      }
    }

    .detail-tooltip {
      display: block !important;
      height: $row-height;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.pad {
        padding-top: 30px;
      }
    }
  }

  &_booking {
    min-width: 10px;
    height: $row-height;
    position: absolute;
    top: 5px;
    padding: 0;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    z-index: 25;
    cursor: pointer;
    transition: 300ms;

    &.counter0 {
      border-radius: 0 3px 3px !important;

      &::after {
        content: attr(data);
        display: block;
        position: absolute;
        background: $color-bg-dark;
        color: $color-white;
        width: 100px; // IE fix
        width: max-content;
        height: 18px;
        overflow: hidden;
        top: 0;
        padding: 2px 4px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }

    &.borderR {
      border-radius: 3px;
    }

    .detail-tooltip {
      padding: 2px 4px;
      top: -20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 100%;
    }

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;

      &_name {
        span {
          display: inline-block;

          &:nth-child(1) {
            padding-right: 4px;
          }

          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    &:hover {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
      -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
      -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#444')";
      z-index: 200;

      span {
        overflow: hidden;
      }
    }

    &_delayed {
      background: red;
      border-radius: 3px;
      position: absolute;
      bottom: 5px;
      z-index: 55;
      font-weight: 600;
      font-size: 10px;
      padding: 0;
      cursor: pointer;

      .detail-tooltip {
        display: flex;
        align-self: center;
        height: 100%;
        width: 100%;
        padding: 4px;
        color: $color-white;

        span {
          display: inline-block;

          &:nth-child(1),
          &:nth-child(2) {
            display: inline-block;
          }

          &:nth-child(3) {
            display: block;
          }
        }
      }

      &:hover {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
      }
    }

    &_wrap {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    &_reservationBuffer {
      height: $row-height;
      background: #d9d9d9;
      position: absolute;
      top: 5px;
      z-index: 24;
    }

    &_name {
      font-size: 11px;
      white-space: nowrap;
    }

    &_tooltip {
      position: absolute;
      background: rgba(0, 0, 0, 0.7);
      top: -45px;
      border-radius: 3px;
      z-index: 10;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 4px;
      white-space: nowrap;
    }
  }

  .advancedList_loader {
    display: flex;
    height: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 0;

    .lds-rolling {
      top: 40px;
      left: 50px;
    }
  }

  &_noResults {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: grey;
    text-transform: uppercase;
  }

  &_site {
    position: relative;
    z-index: 55;

    &.hide {
      color: greenyellow;
    }

    &_name {
      position: relative;
      top: 2px;
      text-transform: initial;
      font-weight: 600;
      font-size: 12px;
    }
  }

  &_parking {
    margin-bottom: 10px;

    &.cache {
      display: none;
    }

    &_name {
      display: inline-flex;
      font-size: 12px;
      min-height: 36px;
      align-items: center;
      margin-top: 5px;
      margin-left: 15px;

      &.selected {
        font-weight: 700;
        background-color: lightgrey;
      }

      .icon-parking div svg,
      .icon-parking svg {
        display: flex;
        height: 20px;
        width: 20px;
      }

      span {
        padding-left: 2px;
      }
    }
  }

  &_loadingOverlay {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 200px;
    top: 0;
    transition: 0.3s ease;
    z-index: 255;

    &.menu-opened {
      width: calc(100% - 235px);
    }
  }

  &_container {
    color: rgba(0, 0, 0, 0.8);
    margin: 15px;
    margin-top: 140px;
    overscroll-behavior: contain;
    position: relative;
    height: calc(100vh - 220px);
    overflow: scroll;
    overflow-x: hidden;

    &.error {
      background: white;
      margin-top: 10px;
      height: calc(100vh - 80px);
      display: flex;
      justify-content: center;

      span {
        margin-top: 10px;
      }
    }

    &.legend-visible {
      height: calc(100vh - 260px);
    }

    &.disable-hover {
      .new-vehicle-planning_booking {
        pointer-events: none;
        background-color: #3972de !important;
        color: #3973de4f !important;
      }
    }
  }

  .current-time {
    position: fixed;
    transition: 0.3s ease;
    top: 200px;
    left: 310px;
    z-index: 64;
    width: 1px;
    height: calc(100% - 220px);
    background: red;

    &.pad {
      top: 240px;
      height: calc(100% - 260px);
    }
  }

  &_bookingMode {
    &:hover {
      background: #c7c7c7;
      cursor: pointer;
    }

    &_selectedDay {
      background: #c7c7c7 !important;
    }
  }

  &_firstRow_sideMenuOpen {
    @media (max-width: 1200px) {
      width: calc(100% - 30px) !important;
    }
  }

  &_firstRow {
    display: flex;
    height: 70px;
    position: fixed;
    width: calc(100% - 30px);
    background: $background;
    // z-index: 64;
    margin-top: -70px;
    font-size: 14px;
    transition: 0.3s ease;

    &_sideMenuOpen {
      width: calc(100% - 265px);
    }

    &_day {
      padding: 15px 10px 16px;
      background-color: $color-white;

      &.multidays {
        white-space: pre;
        text-align: center;
        position: relative;

        &::after {
          content: ' ';
          position: absolute;
          right: 0;
          background: $background;
          width: 1px;
          height: 100%;
          top: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }

    &_hours {
      display: flex;
      font-size: 10px;
      background-color: $color-white;
      margin-top: 5px;

      &.extra-padding {
        padding-right: 17px;
      }
    }

    &_hour {
      flex: 1;
      position: relative;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background: $background;
        width: 1px;
        height: 100%;
      }

      &:first-child {
        &::after {
          width: 0;
        }
      }
    }

    &_firstField {
      width: $firstColumnWidth;
      margin-right: 5px;
    }

    &_field {
      flex: 1;
    }

    .filters {
      background-color: $color-white;
      height: 100%;
      max-height: 70px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;

      .count {
        display: flex;
        align-content: center;
        align-items: center;
        height: 30px;

        > span {
          padding: 0 5px;
        }
        .reg-num {
          border: 1px solid black;
          border-radius: 3px;
          display: flex;
          font-weight: 500;
          margin: 0 5px;
          padding: 0 5px;
        }
      }

      .chips {
        height: 40px;

        .advancedList_chipsWrap {
          position: absolute;
          margin: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 290px;
          overflow: auto;
        }
      }
    }
  }

  &_vehicleRow {
    height: $row-height;
    font-size: 12px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    position: relative;
    overflow: hidden;
    // opacity: 0;

    &.filtered {
      // opacity: 1;
      display: flex;
    }

    .new-vehicle-planning_site {
      width: 100%;
    }

    &_hourField {
      flex: 1;
      position: relative;
      background: $hour-background;

      &:first-child {
        &::after {
          content: '';
          background-color: white;
          width: 1px;
          position: absolute;
        }
      }

      &:after {
        content: '';
        background-color: $background;
        width: 1px;
        height: 100%;
        position: absolute;
        // right: 0;
      }
    }

    &_fuelLevel {
      position: absolute;
      right: 2px;
      top: 40px;
    }

    &_firstField {
      @include vehicleTile();

      width: 270px;
      height: 100%;
      margin-top: 5px;
      margin-right: 22px;
      position: relative;
      z-index: 26;
      background-color: $background;

      .vehicle-tile {
        height: $row-height !important;

        .pic {
          height: calc(#{$row-height} - 10px) !important;

          .vehicle-usage-in-pic {
            max-height: 24px;
            font-size: 12px !important;
            transition: max-height 1s;
            overflow: hidden;
            padding: 3px;

            &:hover {
              max-height: 60px;
            }
          }
        }

        .content {
          .vehicleInfo {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            max-width: 98%;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &_field {
      flex: 1;
      z-index: 22;
      display: flex;
      background: $hour-background;
      height: $row-height;
      margin-top: 5px;

      &.mutlidays {
        margin-left: 22px;

        &:not(:first-child):not(.vehicle-tile) {
          margin-left: 1px;
        }
      }
    }

    img {
      height: 100%;
    }
  }

  .collapsible {
    .panel-handle {
      color: #424b55;
    }

    .panel-content {
      border-left: 3px solid $background;

      .parkings {
        .show-filtered-actions {
          margin: 10px 15px;

          .flatButton {
            background-color: white;
          }
        }
      }
    }
  }
}

.edit-booking-loading {
  background: $color-white !important;
}

.new-vehicle-planning-edit-booking {
  .replacement-vehicle-confirm {
    margin-top: 10px;

    .hr {
      margin-top: 10px;
      border-bottom: 1px solid $color-primary3-alt;
    }
  }

  .customFieldsBookingWrapper {
    .formFieldWrapper {
      padding: 0;
    }

    .ekRadio_title {
      font-size: $vp-uppercase-font-size;
    }
  }

  .ekRadio_wrapper_disabled {
    .ekRadio_label {
      cursor: not-allowed;
      color: $color-black;
    }
  }

  .createBooking_textarea {
    height: 80px;
    resize: vertical;
    outline: none;
  }

  .boxedSelect:disabled {
    cursor: not-allowed !important;
    background-color: inherit !important;
  }

  &_container {
    display: flex;
    margin: 0 0 0 24px;
  }

  &_col1 {
    width: 50%;
    background-color: $color-white;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d9d9d9;
    height: 60vh;

    .title {
      color: $color-white;
      padding-left: 25px;
      font-size: larger;
      margin-top: 10px;
    }

    &.wide {
      .title {
        display: none;
      }

      .user-detail {
        background: $color-white;

        &.read {
          &:after {
            content: '';
          }
        }

        &:after {
          content: ' ';
          position: absolute;
          top: 47px;
          height: calc(100% - 47px);
          width: 600px;
          background-color: $color-grey-transparent;
          z-index: 25;
          padding: 0;
          margin: 0 -20px;
        }
      }
    }

    .align-svg {
      margin: 0 10px;

      &.edit-svg {
        width: 20px;
        height: 20px;
        stroke: #d9d9d9;
        fill: #d9d9d9;
        display: inline-flex;
      }
    }

    .member {
      display: flex;
      align-items: center;
    }

    .user-detail {
      height: 90px;
      color: $color-black;
      background: $color-white;

      .detail {
        display: inline-block;

        span {
          display: block;

          &.user-mail {
            font-size: 13px;
          }
        }
      }
    }
  }

  &_changeVehicle {
    position: absolute !important;
    left: 7px;
    bottom: 15px;
    fill: $color-vp-booking2 !important;

    .new-vehicle-planning_site {
      width: 100%;
    }
  }

  &_col2 {
    width: 50%;
    padding-left: 24px;
    color: rgba(0, 0, 0, 0.75);
    // position: relative;

    .box {
      @include vehicleTile();

      height: 65vh;
      overflow: auto;
      padding: 5px 24px 0 0;

      // .formRow.stick {
      //   height: 60px !important;
      // }

      .vehicle-tile {
        // display: block;
        width: 100%;
        height: 120px;
        margin-left: 0;

        .pic {
          width: 35%;
          height: 100%;
          margin-left: 0;
        }

        .content {
          height: 100%;
          width: 65%;

          .vehicleInfo {
            margin-bottom: 0;

            &.cost {
              margin-top: auto;
            }
          }
        }
      }

      .addBookingConfirmationForm_buttonRow {
        // .formRow.stick
        position: sticky;
        bottom: 0;
        height: 80px;
        z-index: 6;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 50%);
      }

      .customFieldsBookingWrapper {
        display: flex;
        justify-content: space-between;
        flex-basis: 30%;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;

        .formFieldWrapper {
          display: flex;
          flex-basis: 30%;
          flex-wrap: wrap;
          min-height: 100px;
          align-content: center;
          align-items: center;

          .boxedInput_labelText {
            text-transform: none;
          }
        }
      }
    }

    .ekButton {
      text-transform: uppercase;
      margin-top: 5px;

      &.voucher_button {
        margin-top: 25px;
      }

      &.spacer {
        margin-right: 5px;
      }
    }

    .addBookingConfirmationFormWrapper {
      padding-top: 15px;
    }

    .ekTextarea_labelText {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      padding-bottom: 2px;
      text-transform: none;
    }

    // .addBookingConfirmationForm_row {
    //   margin: 2px;
    // }

    // .addBookingConfirmationForm_textarea {
    //   height: 60px;
    // }

    &CarWrap {
      display: flex;

      a {
        width: 100%;
      }
    }

    img {
      width: 140px;
      height: 85px;
      object-fit: contain;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
    }

    h4 {
      display: none;
    }

    @keyframes blink {
      // At the start of the animation the dot has an opacity of .2
      0% {
        opacity: 0.2;
      }

      // At 20% the dot is fully visible and then fades out slowly
      20% {
        opacity: 1;
      }

      // Until it reaches an opacity of .2 and the animation can start again
      100% {
        opacity: 0.2;
      }
    }

    .price {
      // @include remIt(font-size, 50);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      // span {font-size: 16px;}
      .line-through {
        position: relative;
        display: inline-block;
        margin-left: 10px;

        &:after {
          content: ' ';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 50%;
          background-color: $color-black;
        }
      }

      .apply-voucher {
        color: orange;
        padding-left: 20px;
      }

      // span:nth-child(2) {
      //   width: 100%;
      // }
    }

    .loading-price span {
      @include remIt(font-size, 50);
      position: relative;
      bottom: 14px;
      animation-name: blink;
      color: black;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }

    .loading-price span:nth-child(2) {
      animation-delay: 0.2s;
    }

    .loading-price span:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  .frow {
    padding: 0 24px 0 0;
    margin: 10px 0;
    color: black;

    &-label {
      font-weight: 700;
      margin-bottom: 10px;
    }

    .trip-type {
      margin: 10px 0;
    }

    ul.trip-info {
      list-style-type: none;
      padding-left: 24px;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 20px;
        bottom: 0;
        left: 3px;
        height: 100%;
        width: 2px;
        background: black;
      }

      li.item {
        &:not(:first-of-type) {
          margin-top: 10px;
        }

        position: relative;
        display: flex;
        align-items: center;

        &-left {
          display: inline-block;
          margin-right: 46px;
        }

        &:last-child {
          &::before {
            border-radius: 50%;
          }
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 3;
          top: 50%;
          margin-top: -5px;
          left: -24px;
          height: 8px;
          width: 8px;
          margin-right: 16px;
          border: 2px solid #000;
          background: black;
        }

        &:last-of-type::after {
          content: '';
          position: absolute;
          top: 23px;
          z-index: 3;
          bottom: 0;
          left: -21px;
          height: 100%;
          width: 2px;
          background: white;
        }

        span {
          width: 100%;
        }
      }
    }
  }

  &_col1Row1 {
    height: 30%;
    background: $color-white;
    color: black;
    padding: 5px 45px;
    position: relative;
    min-height: 150px;
    max-height: 150px;

    &Icons svg {
      fill: white !important;
      height: 20px !important;
      width: 20px !important;
    }

    &Icons {
      position: absolute;
      height: 70px;
      left: 10px;
      top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    &Icons_bigDot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 2px solid white;
    }

    &Icons_smallDot {
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background: rgba(255, 255, 255, 0.8);
    }

    .ekRadio_label {
      color: white;
    }

    .ekRadio_container {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
    }

    .ekRadio_wrapper {
      margin-right: 10px;
    }

    .geosuggest {
      margin: 5px 0;
    }

    .geosuggest__input {
      border: 0;
      color: black;
      box-sizing: border-box;
      font-size: 14px;
    }

    .geosuggest__input:disabled {
      color: $color-black;
      border: 1px solid #e8e9e9;
      background-color: #f2f2f2;
      cursor: not-allowed;
    }

    .geosuggest__input:focus {
      outline: none;
    }

    .geosuggest__suggests {
      color: rgba(0, 0, 0, 0.7);
      border: 0;
    }

    .searchVehicle_tripSelection {
      margin-top: -8px;
    }
  }

  $widhtSelect: 15px;

  &_col1Row2 {
    background: white;
    display: flex;

    &_pickupDate {
      width: 50%;

      div:first-child {
        width: 100% !important;
      }
    }

    svg {
      fill: #d9d9d9 !important;
      height: 20px !important;
      width: 20px !important;
    }

    .boxedInput--date {
      background-image: url('#{$img-path}datepick.svg') !important;
      background-repeat: no-repeat;
      background-position: 95% center;

      input {
        border-radius: 4px;
        border: 1px solid #d9d9d9;
      }
    }

    .boxedInput_labelText {
      display: none;
    }

    .boxedInput {
      color: black;
    }

    .boxedInput input {
      color: black !important;
      width: auto !important;
    }

    .searchVehicle_boxedSelectWrapper {
      background: transparent;
      width: $widhtSelect;
      border: 0;
    }

    .boxedSelectWrapper:after {
      display: none;
    }

    &_dateWrap {
      display: flex;
      height: 40px;
      margin-left: 10px;
      border: 1px solid #d9d9d9;
      width: 50%;
      padding-left: 25px;
      margin-bottom: 10px;
      align-items: center;
    }

    &_dateWrap svg {
      height: 17px !important;
      width: 17px !important;
      margin-left: auto;
      margin-right: 10px;
    }

    &_dvojtecka {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      color: black;
      font-size: 12px;
      line-height: 20px;
      width: 3px;
      margin-right: 1px;
    }

    .boxedSelect {
      color: black;
      padding: 0 !important;
      border: 0;
      outline: none;
      height: 38px;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      width: $widhtSelect;

      option {
        width: $widhtSelect;
        color: #333 !important;
        background: white !important;
      }
    }

    .boxedSelect:focus::-ms-value {
      background-color: rgba($color-vp-booking2, 0.6);
    }

    .paddingTop0 {
      padding-top: 0;
    }
  }
}

.new-vehicle-planning-change-vehicle {
  width: 50%;
  color: rgba(0, 0, 0, 0.75);
  position: relative;
  overflow: auto;

  .new-vehicle-planning_filterWrap {
    display: flex;

    svg {
      fill: rgba(0, 0, 0, 0.7) !important;
      cursor: pointer;
    }

    .filter {
      display: inline-flex;
      padding: 5px;
      width: 90%;

      svg {
        margin-top: 7px;
      }
    }
  }

  &-available-list {
    height: 30vh;
    overflow-y: scroll;
  }

  &_loaderWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 40px;
  }

  &_row {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-left: 30px;

    .vehicle-tile {
      .content {
        span,
        div {
          font-size: 12px !important;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.vehiclePlanningCreateStatus {
  &_buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -10px;
    margin-top: 10px;
  }

  .new-vehicle-planning-edit-booking_col1Row2 {
    background: white;
    padding: 10px 0;
    margin: 0 5px;
    position: relative;

    &_dateWrap {
      display: flex;
      height: 40px;
      margin-left: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
      width: 100px;
      padding-left: 25px;
    }

    &_dateWrap svg {
      height: 17px !important;
      width: 17px !important;
      left: 125px;
      margin-top: 18px;
      fill: rgba(0, 0, 0, 0.7) !important;
    }
  }

  .boxedInput_labelText {
    display: none;
  }

  .boxedInput {
    // background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    color: rgba(0, 0, 0, 0.7);

    &--textarea {
      resize: vertical;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.7);
    }
  }

  .boxedInput input {
    padding: 14px 0 0 35px !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }

  &_dateWrap {
    display: flex;
    height: 40px;
    margin-left: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    width: 90px;
    padding-left: 25px;
  }

  &_dateWrap svg {
    height: 17px !important;
    width: 17px !important;
    left: 170px;
    margin-top: 18px;
  }

  .new-vehicle-planning-edit-booking_col1Row2_dvojtecka {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
    margin-right: 7px;
    font-size: 12px;
    line-height: 20px;
  }

  .boxedSelect {
    min-width: 20px;
    padding: 0 !important;
    margin-top: 18px;
    background-color: white;
    border: 0;
    outline: none;
    height: 20px;
    font-size: 12px;
    color: $color-black;
  }

  .boxedInput--date > div:first-child {
    width: 115px !important;
  }

  .vehiclePlanningCreateStatus_calendarIcon {
    fill: rgba(0, 0, 0, 0.7) !important;
    height: 20px !important;
    width: 20px !important;
    position: absolute;
    left: 5px;
    margin-top: 15px;
  }
}

.addBookingConfirmationForm_buttonRow {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-end;

  .flatButton {
    margin: 5px 5px 0 !important;
  }
}

.tippy-popper {
  &[x-out-of-boundaries] {
    display: none;
  }

  .tippy-tooltip-content {
    padding: 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

    .status-detail {
      span {
        display: block;
        padding: 3px 10px;
        text-align: center;

        &.type {
          background: $color-white;
          color: $color-grey-light4;
          border: 1px solid $color-grey-light4;
        }

        &.dots {
          display: none;
        }
      }
    }

    .detail-tooltip-closedHours {
      background: white;
      box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
      color: black;
      padding: 10px;
      border-left: 1px dashed $background;
      border-right: 1px dashed $background;
      display: flex;
      flex-direction: column;

      &.enter {
        opacity: 0;
        transition: all ease 0.3s;
      }

      span {
        &:first-child {
          flex-direction: column !important;
        }

        &:hover {
          &.enter {
            opacity: 1;
          }
        }
      }
    }
  }
}

//booking tooltip
.new-card {
  text-align: left;
  // margin: 0 -10px;

  .show {
    display: block;

    a {
      font-size: 14px;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);

      &:hover {
        text-decoration: underline;
      }

      &.actions {
        color: #3972de;
        text-transform: uppercase;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    span.vp-vt {
      a {
        text-decoration: none;
        color: black;
        cursor: pointer;

        &:hover .vehicleInfo {
          text-decoration: underline;

          &.fuel {
            text-decoration: none;
          }
        }
      }
      .vehicle-tile {
        div.p,
        div.content {
          width: 50% !important;
          span.pic {
            img {
              object-fit: contain;
            }
          }
        }
      }
    }

    .vehicle-tile {
      display: flex;

      .pic {
        display: flex;
        height: 90px;
        width: auto;
        margin-left: 6px;
        background-color: white;
        overflow: hidden;
        border-radius: 4px;
      }

      .content {
        display: flex;
        margin-left: 15px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .fuel {
          display: flex;
          align-items: center;
          align-content: center;

          span:nth-child(1):not(.vehicleInfo) {
            margin-right: 4px;
            padding-bottom: 3px;
          }
        }
      }
    }

    .details {
      padding: 4px;

      span {
        padding-right: 5px;
        display: inline-block;

        &:first-child {
          font-weight: bold;
        }

        &.member-status {
          @include remIt(line-height, 20);

          display: inline-block;
          border-radius: 3px;
          padding: 0 8px;
          color: $color-white;
        }
      }
    }

    .hint-workflow {
      padding: 2px 5px;
      background-color: $color-primary-light;
      border: 1px solid $color-grey-light;
      border-radius: 3px;
      margin: 10px 0;
    }

    .card-header {
      > div {
        padding-right: 0 !important;
      }

      svg {
        color: white !important;
      }

      .prebooked-icon {
        display: inline-block;

        .svg-icon {
          fill: $color-black;
        }
      }

      .delayed {
        background-color: $color-error;
        padding: 4px;
        color: $color-white;
        border-radius: 4px;
        margin-left: 25px;
      }

      .svg-icon {
        fill: $color-black;

        &.tik-tok {
          width: 40px;
          height: 40px;
          fill: #fff;

          .iconic-clock-hour-hand,
          .iconic-clock-minute-hand,
          .iconic-clock-second-hand-arm {
            stroke: $color-white;
          }
        }
      }
    }

    .actions {
      .two-items {
        display: flex;
        justify-content: space-around;
        column-gap: 10px;
        margin: 0 0 10px !important;

        .flatButton,
        button {
          width: 100%;

          &--reverse {
            width: 100%;
            border: 1px solid black;
          }
        }
      }

      .items {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        column-gap: 10px;
        margin: 0 !important;

        .flatButton--reverse:not(:last-child) {
          border: 1px solid black;
          margin-bottom: 10px;
        }
      }

      .flatButton {
        border: 1px solid black;
        color: black;

        &--reverse button {
          border: 1px solid black;
          background-color: black !important;
          color: white !important;
        }
      }

      // & > div {
      //   margin: 2px !important;
      // }
    }
  }

  .hide {
    display: none;
  }
}

.tabs-rci {
  .member-exist {
    color: $color-error;
  }

  .tab {
    display: inline-block;
  }

  .tabHide {
    display: none !important;
    width: 0 !important;
  }

  .tabPre {
    display: inline-block;
    width: 100% !important;
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: ' ';
      width: 100%;
      height: 2px;
      background-color: $color-main;
    }
  }
}

.addMemberForm {
  .fieldErrorMsg {
    margin-top: 5px;
  }

  .boxedInput_labelText,
  .boxedSelect_label {
    text-transform: none;
  }

  .container-toggle {
    display: flex;
    flex-direction: column;

    .label {
      width: 100%;
    }

    .inline-field-toggled {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .boxedInputWrapper {
      &.addMember_boxedInputFlex {
        width: 150px;
        padding-left: 10px;
      }
    }
  }

  .custom-fields-wrapper {
    > * {
      margin-bottom: 15px;
    }
  }
}

//member dialog select

.member-select-container {
  border-radius: 3px;

  .search-member-header {
    display: flex;
    align-content: center;
    align-items: center;
    height: 40px;
    font-weight: bold !important;

    .vehiclePlanning_closeIconAbosuluteWrap {
      right: 10px;
    }

    span.unbold {
      font-weight: normal;
    }
  }

  .search-member {
    margin-top: 20px;

    .flex {
      display: flex;

      div.presub-btn {
        min-width: fit-content !important;
        height: 32px;
        margin-left: 2px;

        button {
          height: 100% !important;
          line-height: 32px !important;
        }
      }
    }

    span {
      font-size: 12px;
    }

    .ac-input-wrapper {
      input {
        background-image: url('/css/img/search.svg');
        background-repeat: no-repeat;
        background-position: 5px center;
        padding: 15px 5px 15px 30px !important;
        border: 1px solid grey !important;
        border-radius: 3px;
        height: 20px !important;
      }
    }
  }
}

// booking creation

.new-vehicle-planning-booking-create {
  color: #d9d9d9;

  form {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-title {
    display: flex;
    align-items: center;

    & span {
      font-weight: 500;

      &.member-name {
        padding-left: 5px;
        font-weight: 400;
      }
    }
  }

  .label {
    color: black;
    width: 100%;
    margin: 20px 0 10px;
    font-weight: 700;

    span {
      font-size: 16px;
    }
  }

  .specificities {
    .fields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      &.business {
        justify-content: flex-start;
      }

      .boxedSelect_label {
        padding-bottom: 0;
        text-transform: none;
      }

      .new-vehicle-planning_usageSelector {
        flex-basis: auto;
        width: 33%;

        &:nth-child(2) {
          margin-right: 20px;
          margin-left: 20px;
        }

        .switch-component {
          min-width: 42px;
          margin: 8px 0;
        }

        .private-realm {
          display: flex;
        }

        .realm-label {
          font-size: 14px;
          color: inherit;
        }
      }
    }
  }
}

.voucher-dialog {
  div:first-child {
    border-radius: 3px;
  }

  .header-dialog {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;

    button {
      padding: 12px 0 12px 12px !important;

      div {
        text-align: right;
      }
    }
  }

  .content-dialog {
    display: flex;
    align-items: flex-end;

    .boxedInputWrapper {
      width: 100%;

      .boxedInput_labelText {
        text-transform: none;
      }
    }
  }
}

.vehicles-filters {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 20px;

  > .sc-block {
    > .sc-title {
      margin-bottom: 15px;
    }

    &.active {
      order: -1;

      > .sc-title:after {
        content: '📎';
        margin-left: 5px;
      }
    }

    > .sc-items {
      display: flex;
      flex-wrap: wrap;
      column-gap: 15px;
      row-gap: 20px;

      > * {
        flex-grow: 1;
        flex-basis: 0;
        min-width: 200px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .boxedInput--date > div > div:first-child {
    left: 12px !important;
    color: #333 !important;
  }

  .boxedSelectWrapper {
    width: 100%;
  }
}

.vehicles-filters {
  --ss-primary-color: #{$color-list-active-item};
  --ss-bg-color: #{$color-white};

  .ss-main .ss-values .ss-value {
    background-color: $color-list-active-item;
  }
}

.vp-filters {
  &-wrap {
    .sc-suggestions {
      max-height: 600px;
      height: fit-content;
    }
  }
  .ss-list {
    .ss-option:not(.ss-disabled) {
      &:hover {
        color: $color-white !important;
        background-color: $color-list-active-item !important;
      }

      &.ss-selected {
        // &.vp {
        color: $color-white !important;
        background-color: $color-list-active-item !important;

        &:hover {
          color: $color-white !important;
          background-color: $color-list-active-item !important;
        }

        // }
      }
    }
  }
}
