.resetPassword {
  display: flex;
  flex-direction: column;

  &_title {
    margin-bottom: 30px;
  }

  .boxedInputWrapper {
    width: 300px;
    margin-bottom: 25px;
  }
}

.resetPasswordPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  h1 {
    margin-bottom: 50px;
  }
}
