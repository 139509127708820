.cancellation-fees-form {
  text-align: right;

  &_addRule_button {
    min-width: 218px;
    float: right;
  }

  &-submit_button {
    margin-top: 10px;
  }

  .rules {
    @include table-display();
    padding: 10px;
    margin: 5px 0;
    background: #ededed;
    border-radius: 5px;
    transition: border 0.3s ease;
    text-align: left;

    &._has_error {
      border: 1px solid $color-error;
    }

    .overlap-errors {
      @include remIt(font-size, 14);
      color: $color-error;
      padding-bottom: 5px;
      display: block;
    }

    .unit {
      @include remIt(font-size, 11);
      color: $color-primary-darkest;
      padding-right: 25px;
      padding-left: 5px;
      line-height: 50px;
    }

    .rule-content {
      display: table-cell;
      width: 90%;
      vertical-align: top;

      .item {
        display: inline-block;
        vertical-align: top;

        &.pipe {
          position: relative;
          width: 120px;
        }
      }

      .fieldErrorMsg--cancellationFees {
        width: 60%;
      }
    }

    .delete {
      display: table-cell;
      width: 10%;
      vertical-align: middle;

      div {
        float: right;
      }
    }
  }
}

.cancellation-rules {
  .cancellation-rule-label {
    @include remIt(font-size, 11);
    color: $color-primary-darkest;
    display: flex;
    text-transform: uppercase;
  }

  li.no-rule {
    clear: both;
    width: 100%;
    text-align: left;
  }
}

.cancellation-rules_group {
  display: inline-block;
}

$cancellation-rules_item_removeButtonHeight: 20;

.cancellation-rules_item_actionButton {
  @include table-cell();
  @include remIt(width, $cancellation-rules_item_removeButtonHeight);
  padding: 0 10px;

  svg {
    cursor: pointer;

    &:hover {
      color: $color-secondary-light !important;
    }

    &[disabled] {
      color: $color-primary2 !important;
    }

    &.delete {
      color: rgba($color-error-light, 0.7) !important;

      &:hover {
        color: $color-error-light !important;
      }
    }
  }
}

.cancellation-rulesForm_errors {
  @include remIt(font-size, 14);
  text-align: left;
  margin: 10px 20px;
  color: $color-error;

  &_item + &_item {
    margin-top: 10px;
  }
}

.rule_fromLabel {
  @include remIt(font-size, 12);
  text-transform: uppercase;
}

.boxedInput {
  &._has_error {
    border: solid 1px $color-error;
  }
}

.rule_boxedInputWrapper {
  display: inline-block;
  vertical-align: top;
  margin-top: 6px;
}

.rule_boxedInputLabel {
  margin-left: 0;
}

.rule_boxedInputLabel,
.rule_boxedInputLabel {
  .boxedInput_inputText {
    display: inline-block;
  }
}

.rule_boxedInputLabelText {
  display: inline-block;
  margin-right: 5px;
}

.rule_boxedInput {
  width: 60px;
}

.rate-rule {
  text-align: center;
}

.rule_unit {
  @include remIt(font-size, 11);
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 5px;
  margin-right: 15px;
  padding-top: 12px;
  font-style: normal;
  color: $color-primary-darkest;
}
