@import '_VehicleReports';
@import '_vehicleTasks';

$statusColor: (
  color-reparation: #ffa000,
  color-ready: #24b282,
  color-inactive: #929292,
  color-cleaning: #17b7c4,
  color-intervention: #fe5001,
  color-maintenance: #781a04,
  color-rented-in-rrs: $color-error
);

.vehicleDetailPage {
  &_content {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: $main-font;
    padding: 10px 25px 20px;

    .company-tab {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &_title {
    @include pageTitle();
    font-weight: bold;
    display: inline-block;
  }

  &_actions {
    margin-right: 30px;

    > * {
      margin-top: 20px;
    }
  }

  &_buttonWrap {
    display: flex;
    justify-content: flex-end;
  }

  &_actionButton {
    margin-left: 10px;
  }

  &_actionButton--header {
    float: right;
  }
}

.vehicleDetail {
  @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  background-color: $color-white;
  padding: 30px;
  margin-top: 20px;

  &_statuses {
    .tableView {
      @include remIt(font-size, 13);
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &_title {
    @include remIt(font-size, 13);
    text-transform: uppercase;
    font-weight: 200;
    margin: 0 0 30px;
  }

  &_line {
    width: 100%;
    font-size: 0;
    margin: 30px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &_status_remove_buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    > *:first-child {
      margin-right: 20px;
    }
  }

  &_item {
    @include remIt(font-size, 13);
    display: inline-block;
    width: 20%;
    padding-left: 10px;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &_label {
      display: block;
      font-weight: 600;
      text-transform: uppercase;

      i {
        font-weight: 100;
        font-style: normal;
        text-transform: none;
      }
    }

    &_result {
      margin-top: 15px;
      display: block;
      font-weight: 100;

      .document-link {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        padding: 0 10%;
        width: 100%;
        z-index: 4;

        a {
          display: flex;
          cursor: pointer;
          left: 0;
          right: 0;
          .svg {
            width: 80px !important;
          }
          .new-window {
            display: none !important;
          }

          &:hover {
            .new-window {
              display: inline-flex !important;
            }

            .pdf {
              display: none !important;
            }
          }
        }
      }
    }

    &_link {
      color: $color-primary-darkest;
      text-decoration: underline;
    }
  }

  &_line--triple &_item {
    width: 33.333333%;
  }

  &_bloc {
    width: 70%;
    vertical-align: top;
    display: inline-block;
  }

  &_picture {
    margin: 0;
    width: 30%;
    height: 250px;
    display: inline-block;
    text-align: right;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.vehicleDetail_statusTypeCell {
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
  }

  &--REPARATION {
    color: map-get($statusColor, color-reparation);

    &:before {
      background-color: map-get($statusColor, color-reparation);
    }
  }

  &--READY {
    color: map-get($statusColor, color-ready);

    &:before {
      background-color: map-get($statusColor, color-ready);
    }
  }

  &--INACTIVE {
    color: map-get($statusColor, color-inactive);

    &:before {
      background-color: map-get($statusColor, color-inactive);
    }
  }

  &--CLEANING {
    color: map-get($statusColor, color-cleaning);

    &:before {
      background-color: map-get($statusColor, color-cleaning);
    }
  }

  &--INTERVENTION {
    color: map-get($statusColor, color-intervention);

    &:before {
      background-color: map-get($statusColor, color-intervention);
    }
  }

  &--MAINTENANCE {
    color: map-get($statusColor, color-maintenance);

    &:before {
      background-color: map-get($statusColor, color-maintenance);
    }
  }

  &-RENTED_IN_RRS {
    color: map-get($statusColor, color-rented-in-rrs);

    &:before {
      background-color: map-get($statusColor, color-rented-in-rrs);
    }
  }
}

.vehicleDetailPage_tabs {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.vehicleDetailPage_tabItem {
  @include remIt(font-size, 14);
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 400;

  &.__is_selected {
    background-color: $color-white;
    font-weight: 600;
  }

  span {
    line-height: 50px;
  }
}

.vehicleDetail_form {
  padding: 30px;
  background-color: $color-white;
  margin-bottom: 30px;
}

.vehicleDetail_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .vehicleDetailPage {
    &_actions {
      margin-bottom: 10px;
      display: flex;
    }

    &_buttonWrap {
      .ekButton {
        margin-bottom: 20px;
        height: auto;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .vehicleDetail {
      &_item {
        padding-left: 0;
        padding-bottom: 30px;
        width: 100%;
        display: block;

        &:last-child {
          padding-bottom: 0;
        }
      }

      &_line:last-child {
        margin-bottom: 0;
      }
    }

    &_actionButton--header {
      float: none;
      margin-bottom: 20px;
    }

    .formRow {
      display: block;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .formRow_item {
      margin-left: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .newVehicleForm_field {
      width: 100%;
    }
  }
}
