.list-details {
  .sc-details-form {
    .fieldErrorMsg {
      margin-top: 5px;
      position: absolute;
      width: 100%;
    }
  }

  .sc-actions {
    display: flex;
    justify-content: flex-end;
  }
}

.list-wrap {
  .sc-list-item {
    display: flex;
    padding: 15px 12px;
  }
}

.column-list-flat-details {
  .list-details {
    background: inherit;
    box-shadow: inherit;
    padding: 0;

    > .sc-title-wrap {
      font-size: 16px;
      padding: 0 0 5px;
      margin-bottom: 10px;

      .close-button {
        display: flex !important;
        height: 30px !important;
        line-height: 30px !important;
        background: white !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0 0 5px 2px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        transition: none;

        > * {
          display: inline-flex;
        }

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px 2px;
        }
      }
    }

    > .sc-main-wrap {
      height: 100%;
      padding: 0;
    }
  }
}
