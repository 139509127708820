.bookingMoreInfo {
  .booking_status {
    &_in_progress {
      color: $color-progress;
    }

    &_scheduled {
      color: $color-scheduled;
    }

    &_completed {
      color: $color-completed;
    }

    &_canceled {
      color: $color-canceled;
    }
  }
}
