.file-upload {
  width: 100%;
  height: 100%;

  &:not(.no-padding) {
    padding-bottom: 20px;
  }

  label {
    @include remIt(font-size, 11);
    margin: 1rem 0;
    text-transform: uppercase;

    .fieldErrorMsg {
      display: inherit;
      text-transform: initial;
      padding-left: 10px;
    }
  }

  &-box {
    background-color: $color-white;

    &.inner > * {
      vertical-align: middle;
    }

    &.inner {
      white-space: nowrap;

      &:not(.sb-error) {
        margin-bottom: 33px;
      }

      .one-line-wrap {
        max-width: calc(85% - 24px);
        display: inline-flex;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .file-upload-button {
        z-index: 3;
      }
    }

    .new-import {
      display: flex;
      justify-content: center;
      width: 100%;
      white-space: nowrap;
      background: white;
      border-radius: 6px;
      padding: 4px 0;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);

      .one-line-wrap {
        max-width: calc(85% - 24px);
        display: inline-flex;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .members-rejected-box {
      margin-top: 20px;

      span {
        display: block;
        margin-bottom: 10px;
      }
    }

    .import-retry {
      position: relative;
      padding-top: 15px;

      button {
        z-index: 4;
      }
    }
  }

  &-add-input {
    display: none;
  }

  &-dropzone {
    min-height: 125px;
    height: 125px;
    border: 2px dashed $color-border-shadow3;
    border-radius: 10px;
    outline: none;
    position: relative;
    cursor: pointer;
    z-index: 2;
    transition: border 0.3s ease;

    &:hover {
      border: 2px dashed $color-black;
    }

    &-inner {
      text-align: center;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 125px;

      .preview {
        opacity: 0;
        display: block;
        height: 121px;
        width: 100%;
        transition: all 0.3s ease;
        position: absolute;
        z-index: 1;
        overflow: hidden;
        border-radius: 10px;

        img {
          opacity: 1;
          width: 100%;
          transition: all 0.3s ease;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          opacity: 0;

          .instruction-text {
            opacity: 0;
            position: relative;
            transition: all 0.3s ease;
          }
        }
      }

      .document-link {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        padding: 0 10%;
        width: 100%;
        z-index: 4;

        a,
        .no-url {
          display: flex;
          left: 0;
          right: 0;

          .new-window {
            display: none !important;
          }

          &:hover {
            .new-window {
              display: inline-flex !important;
            }

            .pdf {
              display: none !important;
            }
          }
        }

        .no-url {
          font-weight: 300;
        }
      }

      &.create-file {
        .instruction-text {
          opacity: 1;
        }

        .import-resume {
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }

      &.update-file {
        .preview {
          opacity: 1;

          .instruction-text {
            &:hover {
              opacity: 1;
            }
          }

          &:hover {
            img {
              opacity: 0;
            }

            .instruction-text {
              opacity: 1;
            }
          }
        }

        .import-resume {
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
    }

    .actions {
      position: relative;
      z-index: 3;
      bottom: 22px;
      display: flex;
      justify-content: center;
    }

    &-active {
      border-color: $color-text-dark;
      background-color: $color-bg-super-light;
    }
  }
}
