// Tabs Component
// ==========================================================================

$tabs: (
  tabs-base-color: $color-primary-darkest,
  tabs-current-color: $color-secondary-light,
  tabs-text-size: $baseFontSize,
  tabs-number-color: $color-white
);

// Constructor
// ==========================================================================

.tabsItems {
  @include table-display();
}

.tabsItem {
  @include table-cell();
  width: 12.5%;
  text-align: center;
  border-bottom: 1px solid rgba(map-get($tabs, tabs-base-color), 0.5);
  padding-bottom: 10px;
}

.tabsItem_button {
  @include basicClickable();
  text-decoration: none;
  padding: 0 10px;
  text-align: center;
  display: block;
  width: 100%;

  &:hover .tabsItem_label,
  &:hover .tabsItem_number {
    @include box-opacity(1);
    color: map-get($tabs, tabs-current-color);
  }

  &:hover .tabsItem_number {
    border-color: map-get($tabs, tabs-current-color);
  }
}

.tabsItem_label {
  @include remIt(font-size, map-get($tabs, tabs-text-size));
  @include box-opacity(0.5);
  color: map-get($tabs, tabs-base-color);
  display: inline-block;
}

.tabsItem_number {
  @include pellet();
  @include box-opacity(0.5);
  margin: 0 5px;
}

.tabsItem--current {
  border-bottom-width: 3px;
  border-color: rgba(map-get($tabs, tabs-current-color), 1);

  .tabsItem_label {
    @include box-opacity(1);
    color: map-get($tabs, tabs-current-color);
  }

  .tabsItem_number {
    @include box-opacity(1);
    color: map-get($tabs, tabs-current-color);
  }

  .tabsItem_button .tabsItem_number,
  &:hover .tabsItem_button .tabsItem_number {
    @include box-opacity(1);
    color: map-get($tabs, tabs-number-color);
  }
}
