.bookingInfo {
  &_headline {
    @include flex-valign-center;

    &_icon_container {
      @include remIt(margin-left, 15);
    }
  }

  .error-line {
    color: $color-canceled;
    display: flex;
    align-items: center;
  }
}
