.scheduled-export {
  .list-wrap {
    padding-top: 20px;
    padding-right: 20px;

    @media (max-width: 1200px) {
      padding: 20px 20px 0;
    }

    > section:first-child {
      margin-right: 20px;
    }
  }

  .list-details > .sc-title-wrap {
    margin-bottom: 20px;
    padding: 0;
  }
}

// TODO: add cursor text for selects
// TODO: (select can't be selected when disable prop present, need to fix this first)
// TODO: allow selection of text for slimSelect that is disabled

.scheduled-export-filters,
.scheduled-export {
  [disabled]:not(select):not(button),
  .ekRadio_wrapper_disabled .ekRadio_label {
    cursor: text;

    &:before {
      cursor: not-allowed;
    }
  }
}
