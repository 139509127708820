.insuranceContractForm {
  @include clearfix;
}

.insuranceContractForm_title {
  @include remIt(font-size, 13);
  text-transform: uppercase;
  font-weight: 200;
  margin: 0 0 30px;
}

.insuranceContractForm_row {
  margin-bottom: 25px;
}

.insuranceContractForm_definitionList_term {
  font-size: 12px !important;
  display: block;
  text-transform: uppercase;
}

.insuranceContractForm_definitionList_definition {
  @include remIt(font-size, 12);
  padding: 0;
  margin: 10px 0 0;
}

.insuranceContractForm_option {
  float: left;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }
}

.insuranceContractForm_ekRadioTitle {
  @include remIt(font-size, 12);
  display: inline-block;
  min-width: 120px;
  padding-right: 10px;
}

.ekButton.insuranceContractForm_actionsButton {
  min-width: 218px;
  float: right;
  margin-top: 20px;
}

.insuranceContractForm_checkbox {
  font-size: 12px !important;
}

.insuranceContractForm_checkbox:before {
  height: 16px !important;
  width: 16px !important;
  margin-bottom: -1px;
}

.fieldErrorMsg--insuranceContractForm {
  margin-top: 10px;
}

.insuranceContractForm_Picture {
  margin: 0;
  width: 30%;
  height: 200px;
  display: inline-block;
  text-align: right;
  float: right;
}

.insuranceContractForm_Picture img {
  max-height: 100%;
  max-width: 100%;
}
