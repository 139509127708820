.profileForm {
  &_container {
    margin: 35px 0 10px;
  }

  &_actionsButton {
    min-width: 340px;
    float: right;
  }

  &_row {
    margin-bottom: 25px;
  }

  &_boxedSelectWrapper {
    margin-right: 10px;
  }
}

.fieldErrorMsg--profileForm {
  margin-top: 10px;
  width: 100%;
}
