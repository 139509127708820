/* The switch - the box around the slider */
.switch-component {
  $circleSize: 18px;
  $circleMargin: round($circleSize / 6.5);
  $switchHeight: ($circleMargin * 2) + $circleSize;
  $switchWidth: ($circleMargin + $circleSize) * 2;

  position: relative;
  display: inline-block;
  width: $switchWidth;
  height: $switchHeight;

  /* Hide default HTML checkbox */
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: $circleSize;
    width: $circleSize;
    left: $circleMargin;
    bottom: $circleMargin;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  $toggleColor: #4cd964;

  input[disabled] + .slider {
    cursor: not-allowed;
  }

  input:checked + .slider {
    background-color: $toggleColor;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $toggleColor;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX($circleSize);
    -ms-transform: translateX($circleSize);
    transform: translateX($circleSize);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: $switchHeight;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
