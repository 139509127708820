.banner-message {
  background-color: #db6e00;
  min-height: $page-container-padding;
  position: fixed;
  width: 100%;
  top: $page-container-padding;
  left: 0;
  z-index: $header-z-index + 20;
  display: flex;
  align-items: center;
  color: white;
  padding: 5px;

  .sc-msg {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  &.sc-hide {
    display: none;
  }
}

._login {
  .banner-message {
    top: 0;

    &.sc-hide {
      display: flex;
    }

    .sc-icon {
      visibility: hidden;
    }
  }
}
