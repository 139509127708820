.bankoutView {
  height: 100%;
  width: 100%;
  padding: 25px 25px 30px;
}

.bankout-status-PROCESSING,
.bankout-status-PENDING,
.bankout-status-undefined {
  color: $color-inactive;
}

.bankout-status-EXECUTED {
  color: $color-warn;
}

.bankout-status-SUCCESS {
  color: $color-completed;
}

.bankout-status-ERROR {
  color: $color-error;
}
