.bookingBilling {
  &_headline {
    @include flex-valign-center;

    &_icon_container {
      @include remIt(margin-left, 15);
    }
  }

  [class^='payment_status'] {
    color: $color-scheduled;
  }

  .payment_status {
    &_paid {
      color: $color-completed;
    }

    &_unpaid {
      color: $color-canceled;
    }
  }

  ul.bookingBilling_invoiceList {
    a {
      display: flex;
    }

    li {
      margin-top: 1em;
    }

    $icon-size: 22px;

    a:before {
      content: '';
      display: block;
      height: $icon-size;
      width: $icon-size;
      background-size: $icon-size $icon-size;
      // noinspection CssUnknownTarget
      background-image: url('/img/pdf.svg');
      background-repeat: no-repeat;
      margin-right: 5px;
    }

    a:hover {
      color: #000000;
    }
  }
}
