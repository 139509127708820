div.memberView_profileHistory {
  width: 100%;
  .memberView_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 0 20px;

    h4 {
      display: flex;

      svg.closeView {
        margin-left: auto;
        height: 30px !important;
        width: 30px !important;
        cursor: pointer;
      }
    }
  }

  .memberView_body {
    position: fixed;
    height: calc(100% - 60px);
    top: 60px;
    padding: 0 20px;
    width: 100%;
    left: 0;
    overflow: scroll;
  }

  .events-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;

    .table-shadow {
      overflow-x: auto;
      position: relative;
    }

    table {
      width: 100%;
    }

    thead {
      border-bottom: $advanced-list-header-border;
    }

    tbody {
      tr {
        text-align: left;
        border-bottom: 1px solid $advanced-list-header-background;
        position: relative;

        &:hover {
          background: $color-primary-lightest;
        }
      }
    }

    $cell-padding: 10px;
    $child-padding: $cell-padding * 2;

    .icon-container {
      display: flex;
      margin-left: 5px;
      width: 24px;
      height: 24px;
    }

    svg {
      color: $color-text-dark2 !important;
    }

    th {
      color: $color-text-dark;
      background: $advanced-list-header-background;
      text-align: left;
      padding: $cell-padding;

      &:not(:hover) {
        .sort-icon {
          display: none !important;
        }
      }

      &:first-child {
        padding-left: $child-padding;
      }

      &:last-child {
        padding-right: $child-padding;
      }
    }

    td {
      font-size: 0.95em;
      color: $color-text-dark;
      padding: $cell-padding;
      max-width: 500px;

      span.expand-handler {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      &:first-child {
        padding-left: $child-padding;
      }

      &:last-child {
        padding-right: $child-padding;
      }

      &.event-status-error {
        color: $color-error-mid;
      }

      &.event-status-good {
        color: $color-good;
      }

      &.event-status-other {
        color: $color-to-review;
      }

      &:empty {
        padding: 0;
      }
    }
  }
}
