// tableView constructor
// ==========================================================================

.tableView {
  @include remIt(font-size, 12);
  @include remIt(line-height, 15);
  width: 100%;
  background-color: $color-white;
  color: $color-primary-darkest;
}

.tableView_row {
  height: 45px;
  border-top: 1px solid $color-primary3-alt;

  &:first-child {
    border-top: 0;
  }
  &.unused {
    background-color: #f8f8f8;
  }
}

/*.tableView_headRow {
  border-bottom: 1px solid $color-primary3-alt;
}*/

.tableView_cell,
.tableView_headCell {
  text-align: left;
  padding: 10px;
  color: $color-primary-darkest;
}

.tableView_cell_center {
  text-align: center;
}

.tableView_headCell {
  padding-bottom: 0;
}

.tableView_headCell_content {
  @include remIt(letter-spacing, 1);
  text-transform: uppercase;
  position: relative;
  padding: 0 0 7px;
  font-weight: 600;
  display: inline-block;

  &--sortable .tableView_headCell_action:after {
    @include remIt(font-size, 6);
    @include transform(translateY(-50%));
    content: $icon-sort;
    font-family: $icons-font;
    color: $color-black;
    position: absolute;
    top: 32%;
    left: 100%;
    margin-left: -15px;
    @include transform(rotate(180deg) translateY(50%));
  }

  &._is_sorting {
    color: $color-secondary-light;
    margin-top: 2px;
    border-bottom: 3px solid $color-secondary-light;

    .tableView_headCell_action:after {
      color: $color-secondary-light;
    }
  }

  &._is_sortReversed .tableView_headCell_action:after {
    @include transform(rotate(0deg) translateY(-50%));
  }
}

.tableView_headCell_action {
  @include basicClickable;
  text-transform: uppercase;
  margin-right: 20px;
  outline: none;
}

.tableView_headCell_action_centered {
  margin-right: 0;
}
