.subCompaniesPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  &_managementHeader {
    padding-bottom: 25px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }
}

.subCompanies_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
