.pdf-modal {
  @mixin max() {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  @include max;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2500;
  position: fixed;

  .button-container {
    display: flex;
  }

  .pdf {
    &-overlay {
      @include max;
      position: absolute;
      background-color: $color-dark-transparent;
    }

    &-title {
      @include remIt(font-size, 22);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      padding: 24px;
    }

    &-button {
      padding: 5px;

      &:hover {
        background-color: $color-hover-bg-light;
        cursor: pointer;
      }
    }

    &-container {
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      max-height: 850px;
      max-width: 750px;
      width: 100%;
      height: 100%;
      position: relative;
    }

    &-iframe-container {
      display: flex;
      flex-grow: 1;
      padding: 0 24px 24px;
      width: 100%;
      height: calc(100% - 80px);

      iframe {
        height: 100%;
        flex-grow: 1;
        border: 0;
      }
    }
  }
}
