.backUserView {
  .status_active {
    color: $color-approved;
  }

  .status_suspended {
    color: $color-not-approved;
  }

  &_abort_button {
    width: 70%;
  }

  &_confirm_button {
    width: 70%;
  }

  &_modal_label {
    @include remIt(margin-bottom, 20);
    font-size: 1.2em;
    color: $color-text-dark;
  }

  &_modal_left_container,
  &_modal_right_container {
    display: inline-flex;
    width: 50%;
    justify-content: center;
    font-size: 1em;
  }

  &_abort_button,
  &_confirm_button {
    font-size: 1em !important; // scss-lint:disable ImportantRule
  }
}
