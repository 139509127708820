.list-modal {
  .addParkingPage {
    .pageContainer {
      padding: 0;

      .addParkingPage_content {
        padding: 30px 30px 0;

        .parkingForm_container {
          margin-top: 0;
        }
      }
    }
  }
}
