.fileImage {
  .saving_placeholder {
    background: $color-under-png;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  $icon-size: 22px;
  $icon-padding: 6px;
  $icon-hover: 6px;

  .icon_class {
    height: $icon-size;
    width: $icon-size;
    transition: width 0.5s, height 0.5s;
    color: $color-text-dark4;
  }

  .icon_wrap {
    box-shadow: 0 9px 20px -6px $color-border-shadow6;
    border-radius: 50%;
    padding: $icon-padding;
    display: flex;
    background: $color-white;

    &:hover {
      .icon_class {
        height: $icon-size + $icon-hover !important; // scss-lint:disable ImportantRule
        width: $icon-size + $icon-hover !important; // scss-lint:disable ImportantRule
      }
    }
  }
}

.imageWrap {
  &_closed {
    .svg_wrap {
      display: none;
    }

    img {
      @include remIt(border-radius, 3);
      background: $color-under-png;
      cursor: pointer;
    }
  }
}

.pdfWrap {
  &_placeholder {
    @include newTabIconIcon;
    position: relative;
    height: 120px;
    width: 200px;
    background: $color-under-png;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }

  &_viewer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    background: $color-dark-transparent;

    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }

    $icon-top: 60px;
    $icon-right: 30px;

    .icon_download {
      position: fixed;
      right: $icon-right * 3;
      top: $icon-top;
      cursor: pointer;
      z-index: 1000;

      &_hidden {
        display: none;
      }
    }

    .icon_pdf {
      position: fixed;
      right: $icon-right;
      top: $icon-top;
      cursor: pointer;
      z-index: 1000;
    }
  }
}

@keyframes blink {
  // At the start of the animation the dot has an opacity of .2
  0% {
    opacity: 0.2;
  }
  // At 20% the dot is fully visible and then fades out slowly
  20% {
    opacity: 1;
  }
  // Until it reaches an opacity of .2 and the animation can start again
  100% {
    opacity: 0.2;
  }
}

.saving span {
  // Font-size
  @include remIt(font-size, 50);
  // Lone-height
  @include remIt(line-height, 0);
  // Use the blink animation, which is defined above
  animation-name: blink;
  // The animation should take 1.4 seconds
  animation-duration: 1.4s;
  // It will repeat itself forever
  animation-iteration-count: infinite;
  /* This makes sure that the starting style (opacity: .2)
  of the animation is applied before the animation starts. Otherwise we would see a short flash or
  would have to set the default styling of the dots to the same as the animation.
  Same applies for the ending styles. */
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  // Starts the animation of the third dot with a delay of .2s, otherwise all dots would animate at the same time
  animation-delay: 0.2s;
}

.saving span:nth-child(3) {
  // Starts the animation of the third dot with a delay of .4s, otherwise all dots would animate at the same time
  animation-delay: 0.4s;
}
