.backLink {
  @include basicClickable();
  @include remIt(font-size, 16);
  text-decoration: none;
  background: white;
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  align-items: center;
  height: 60px;
  width: 240px;
  margin-left: 12px;

  &-text {
    margin-left: 10px;

    u {
      @include remIt(font-size, 14);
    }
  }
}
