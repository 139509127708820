.bookings-v2 {
  .auto-complete {
    display: flex;
    flex-grow: 1;

    input {
      width: 100%;
    }

    > ul > li[aria-selected='true'] {
      background: rgba(123, 123, 123, 0.15);
    }
  }

  .list-wrap > section:not(:last-of-type) {
    margin-right: 15px;
  }

  .column-list {
    width: 400px;

    .sc-list-header {
      margin-bottom: 10px;

      > .sc-item-wrap {
        height: 35px;
        display: grid;
        font-weight: bold;
        font-size: 1.1em;
        background: #f9f9f9;
        color: #333;

        &:hover {
          background: #f9f9f9;
        }
      }
    }

    .sc-list-header > .sc-item-wrap,
    .sc-list-items {
      gap: 10px;
      padding: 0 10px;
    }

    .sc-list-items {
      display: grid;
      min-height: 50px;

      .sc-icon {
        display: inline-flex !important;
        width: 20px !important;
        height: auto !important;
        margin: 0 5px;
        flex-shrink: 0;
      }
    }

    .sc-list-item {
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      flex-grow: 1;
      flex-basis: 0;

      &:not(.booking-id) {
        white-space: nowrap;
        overflow: hidden;

        span {
          display: block;
        }

        * {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.sc-wide {
    .list-wrap {
      min-width: auto;
      width: 100%;
    }

    .column-list {
      width: 100%;

      .dropdown-search-wrap {
        max-width: 300px;
      }

      .sc-list-header > .sc-item-wrap,
      .sc-list-items {
        grid-template-columns: minmax(115px, 1fr) repeat(3, 1fr) 2fr;
      }

      .sc-multi-item {
        display: flex;
        flex-direction: column;

        .sc-dates {
          display: flex;
          align-items: center;
        }

        > *:first-child + * {
          color: #333;
        }

        svg {
          color: rgba(0, 0, 0, 0.6) !important;
        }
      }
    }
  }

  &:not(.sc-wide) {
    .column-list {
      width: 400px;

      .sc-list-header > .sc-item-wrap,
      .sc-list-items {
        grid-template-columns: minmax(115px, 1fr) 1fr;

        > *:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }

  .list-details {
    > .sc-main-wrap {
      position: relative;

      > * {
        position: absolute;
      }

      .pageContainer {
        padding: 0;
      }
    }
  }
}

@media (max-width: 1000px) {
  .bookings-v2:not(.sc-wide) .column-list {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .bookings-v2.sc-wide .column-list {
    .sc-list-header > .sc-item-wrap,
    .sc-list-items {
      grid-template-columns: minmax(115px, 1fr) repeat(2, 1fr) 2fr;

      > *:nth-child(2) {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .bookings-v2.sc-wide .column-list {
    .dropdown-search-wrap {
      max-width: 100%;
    }

    .sc-list-header > .sc-item-wrap,
    .sc-list-items {
      grid-template-columns: minmax(115px, 1fr) 1fr;

      > *:nth-child(n + 3) {
        display: none;
      }
    }
  }
}
