.list-modal {
  display: flex;
  flex-direction: column;

  .sc-title-wrap {
    display: flex;
    font-size: larger;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 12px 24px;
  }

  .sc-child-wrap {
    height: 100%;
    overflow-x: hidden;
  }

  .sc-edit {
    outline: none;
    background: none;
    border: 0;
    padding: 0;
    display: inline-flex;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  svg {
    width: 18px;
  }
}

@media (max-width: 767px) {
  .list-modal {
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
