.list-menu-item > div > div {
  padding-left: 55px !important;
}

.column-list {
  @include listWrapProps;

  padding: 25px 10px;
  flex-direction: column;
  word-break: break-all;
  display: inline-flex;
  width: 310px;

  .sc-back {
    display: inline-flex;
    align-items: center;
    background: none;
    margin-left: -10px;
    padding: 5px;
    border: 0;
  }

  > .sc-head,
  > .sc-body > * {
    padding: 0 15px;
  }

  > .sc-head {
    display: flex;
    margin-bottom: 20px;

    .sc-chips-wrap {
      display: flex;
      position: relative;
      margin-left: 10px;
      flex-grow: 1;

      .advancedList_chipsWrap {
        width: auto;
        max-width: none;
        flex-shrink: 1;
        overflow: auto;
        position: absolute;
        max-height: 80px;
        margin: 0;
      }
    }

    .sc-filters-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      width: 35px;

      > span {
        display: inline-flex;
      }

      svg {
        cursor: pointer;
        color: darken($color-list-icon-button, 20%) !important;
        height: auto !important;
        width: 100% !important;
        border: 2px solid $color-list-selected-item;
        padding: 3px;
        border-radius: 4px;

        &:hover {
          color: darken($color-list-icon-button, 40%) !important;
          border-color: $color-list-selected-hover;
        }
      }

      &.sc-active {
        svg {
          color: white !important;
          border-color: $color-list-active-item;
          background: $color-list-active-item;
        }
      }
    }

    .sc-menu-wrap {
      display: flex;

      > div {
        display: inline-flex !important;
        margin-right: -10px;
      }

      button {
        height: auto !important;
        width: auto !important;
        padding: 5px !important;

        svg {
          color: darken($color-list-icon-button, 20%) !important;
        }

        &:hover svg {
          color: darken($color-list-icon-button, 40%) !important;
        }
      }
    }

    .sc-search {
      background-color: $color-list-input-bg;
      color: $color-list-input;
      font-size: 1.1em;
      border-radius: 4px;
      padding: 0 10px 0 35px;
      height: 40px;
      outline: none;
      flex-grow: 1;
      border: 0;

      &::placeholder {
        color: $color-list-input-placeholder;
      }
    }
  }

  .sc-items {
    display: flex;
    overflow: auto;
    flex-direction: column;

    &.no-result {
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .sc-item-wrap {
    display: inline-flex;
    border: 1.5px solid $color-list-selected-item;
    border-radius: 4px;

    .sc-edit,
    .sc-item-inner {
      border-radius: 2px;
    }

    &:not(.active):not(.selected):hover {
      background: $color-list-selected-item;

      .sc-edit,
      .sc-item-inner {
        &:not(:hover) {
          background: $color-white;
        }
      }
    }

    .sc-item-inner {
      width: 100%;

      &.pointer {
        cursor: pointer;
      }
    }

    .sc-edit {
      border: 0;
      outline: none;
      display: inline-flex;
      background: none;
      cursor: pointer;
      align-items: center;
      margin-left: auto;
      padding: 0 15px;

      svg {
        color: $color-list-icon-button;
        height: 20px;
        width: 20px;
      }
    }

    @mixin chosenItem($color) {
      background: $color;
      border-color: $color;
    }

    &.selected:not(.active) {
      @include chosenItem($color-list-selected-item);

      &:hover {
        @include chosenItem($color-list-selected-hover);

        .sc-edit,
        .sc-item-inner {
          &:not(:hover) {
            @include chosenItem($color-list-selected-item);
          }
        }
      }
    }

    &.active {
      &:hover {
        filter: brightness(1.1);

        .sc-edit,
        .sc-item-inner {
          &:hover {
            background: inherit;
            filter: brightness(1.1);
          }
        }
      }

      .sc-edit svg,
      .sc-item-inner {
        color: $color-white;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  > .sc-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  > .sc-body > .sc-title {
    margin-bottom: 15px;

    .sc-main {
      font-weight: 500;
      text-transform: uppercase;
    }

    .sc-count {
      margin-left: 5px;
      color: #333;
    }
  }
}
