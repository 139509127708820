.passwordForm {
  &_container {
    margin: 35px 0 10px;
  }

  &_actionsButton {
    min-width: 340px;
    float: right;
  }

  &_row {
    margin-bottom: 25px;
  }

  &_fieldErrorMsg {
    position: absolute;
    right: 0;
    top: 0;
  }
}
