/* _____ VARIABLES _____ */

// Generic
// ==========================================================================

$img-path: 'img/';
$fonts-path: '../fonts/';

// Base Colors
// ==========================================================================

$color-main: var(--ss-primary-color);

$color-bg-dark: #151515;
$color-bg-dark-clear: rgba(62, 62, 62, 0.9);
$color-bg-grey-clear: rgba(97, 97, 97, 0.9);
$color-bg-light-clear: rgba(0, 0, 0, 0.04);
$color-bg-super-light: rgba(0, 0, 0, 0.01);
$color-white: #fff;
$color-black: #000;
$color-dark-transparent: rgba(0, 0, 0, 0.5);
$color-dark-transparent_8: rgba(0, 0, 0, 0.8);
$color-grey-transparent: rgba(128, 128, 128, 0.5);
$color-white-alt: #f5f5f5;
$color-link-light-blue: rgb(0, 144, 255);
$color-text-grey: rgba(0, 0, 0, 0.76);
$color-text-clear-dark: rgba(0, 0, 0, 0.6);
$color-text-clear-dark-2: rgba(0, 0, 0, 0.54);
$color-dark-clear: rgba(0, 0, 0, 0.64);
$color-under-png: rgba(148, 148, 148, 0.3);
$color-text-dark: #333;
$color-text-dark2: #3e3e3e;
$color-text-dark3: #505050;
$color-text-dark4: #616161;
$color-transparent: rgba(255, 255, 255, 0);

$color-hover-bg-light: rgba(128, 128, 128, 0.15);

$color-grey-light: #7f7f7f;
$color-grey-light2: #5d5d5d;
$color-grey-light3: #757575;
$color-grey-light4: #777777;
$color-grey-dark: #525252;

$color-error: #f00;
$color-error-mid: #dd3c3c;
$color-error-light: #ff6f74;
$color-warn: #ffa000;

$color-good: #007a00;
$color-good-secondary: #62c34a;
$color-good-tertiary: #4ac098;

$color-primary-darkest: #414546;
$color-primary-dark: #666666;
$color-primary-middle: #979797;
$color-primary-middle2: #aaaaaa;
$color-primary: #bbbbbb;
$color-primary2: #cccccc;
$color-primary2-alt: #c0c4c5;
$color-primary2-alt2: #cfcfcf;
$color-primary3: #dddddd;
$color-primary3-alt: #d9d9d9;
$color-primary3-alt2: #dedede;
$color-primary-light: #ebebeb;
$color-primary-light2: #efefef;
$color-primary-light3: #e5e5e5;
$color-primary-light4: #dbdada;
$color-primary-lighter: #f4f4f4;
$color-primary-lighter2: #e7e7e7;
$color-primary-lightest: #f8f8f8;
$color-most-light: #fdfdfd;

$color-border-shadow1: rgba(0, 0, 0, 0.2);
$color-border-shadow2: rgba(0, 0, 0, 0.12);
$color-border-shadow3: rgba(119, 119, 119, 0.6);
$color-border-shadow4: rgb(179, 179, 179);
$color-border-shadow5: rgba(0, 0, 0, 0.14);
$color-border-shadow6: rgba(0, 0, 0, 0.37);
$color-border-shadow7: $color-primary3;
$color-border-shadow8: rgba(0, 0, 0, 0.1);
$color-border-shadow9: rgba(0, 0, 0, 0.62);
$color-border-shadow10: rgba(30, 30, 30, 0.2);

$color-menu-text: #333;
$color-menu-after: #9e9e9e;

$color-secondary: $color-main;
$color-secondary-light: $color-main;
$color-secondary-dark: #414546;

$color-tertiary: #b54b1f;
$color-text: rgba(0, 0, 0, 0.8);
$color-text2: $color-menu-text;

$color-light-backgroud: $color-primary-lightest;
$color-table-bg: rgba(0, 0, 0, 0.05);

$color-inactive: #929292;

$color-published: #24b282;
$color-unpublished: $color-inactive;
$color-applied: $color-inactive;
$color-to-complete: $color-inactive;
$color-to-review: $color-warn;
$color-approved: #24b282;
$color-not-approved: $color-error;
$color-completed: #54ad59;
$color-canceled: #000;
$color-scheduled: #f78c35;
$color-progress: #28acbf;
$color-prebooked: #ffcc32;
$color-delayed: #eb5354;

// Component Colors
// ==========================================================================

$color-toolbar-background: $color-main;
$color-vp-booking2: $color-main;
$color-vp-disabled: rgba(255, 255, 255, 0.71);
$color-toolbar-text: #fff;
$quickSight-info-icon-bg: #0771b9;

// List input
// ==========================================================================

$color-list-input: #5d5d5d;
$color-list-input-placeholder: #757575;
$color-list-input-bg: #efeff1;
$color-list-icon-button: #9e9e9e;
$color-list-active-item: #4da1ff;
$color-list-active-hover: #3895fd;
$color-list-selected-item: #e5e5e5;
$color-list-selected-hover: #dbdbdb;

// Component Variables
// ==========================================================================

$column-list-border-radius: 6px;

$page-container-padding: 60px;
$side-menu-width: 235px;

$advanced-list-shadow-top: 0 1px 3px 0 $color-border-shadow1;
$advanced-list-shadow-sides: 0 1px 1px 0 $color-border-shadow5;
$advanced-list-shadow-bottom: 0 2px 1px -1px $color-border-shadow2;

$advanced-list-header-background: $color-white-alt;
$advanced-list-header-height: 50px;
$advanced-list-header-border: 1px solid $color-border-shadow2;
$advanced-list-container-shadow: $advanced-list-shadow-top, $advanced-list-shadow-sides, $advanced-list-shadow-bottom;
$advanced-list-background: $color-white;
$advanced-list-max-width: 1400px;
$advanced-list-row-border: 1px solid rgba(0, 0, 0, 0.08);

// Z-INDEX
// ==========================================================================

$flash_message_z_index: 1999;
$header-z-index: 888;

// Fonts
// ==========================================================================

$main-font: 'MainFont', 'roboto', 'Arial', sans-serif;
$icons-font: 'glyph-icons';
$light-weight: 300;
$medium-weight: 500;

// SVG Icons
// ==========================================================================

$icon-download: '\ea0a';
$icon-electric: '\ea0b';
$icon-email: '\ea0c';
$icon-star: '\ea0d';
$icon-fuel: '\ea0e';
$icon-home: '\ea0f';
$icon-backpack: '\ea1a';
$icon-brand: '\ea1b';
$icon-document: '\ea1c';
$icon-list: '\ea1d';
$icon-marker: '\ea1e';
$icon-usermanager: '\ea1f';
$icon-info: '\ea01';
$icon-time: '\ea02';
$icon-automatic: '\ea03';
$icon-carSeat: '\ea04';
$icon-check: '\ea05';
$icon-clock: '\ea06';
$icon-close: '\ea07';
$icon-datepicker: '\ea08';
$icon-double-chevron: '\ea09';
$icon-notification: '\ea10';
$icon-office: '\ea11';
$icon-password: '\ea12';
$icon-search: '\ea13';
$icon-sort: '\ea14';
$icon-view: '\ea18';
$icon-viewClose: '\ea19';
$icon-signOut: '\ea32';
$icon-logoLetter: '\ea2f';
$icon-chevron: '\ea31';
$icon-todolist: '\ea28';
$icon-worker: '\ea29';
$icon-maintenance: '\ea2a';
$icon-user: '\ea2b';
$icon-reparation: '\ea2c';
$icon-usermanagement: '\ea2d';
$icon-backusermanager: $icon-usermanagement;
$icon-vehicle: '\ea2e';
$icon-landscape: '\ea23';
$icon-booking: '\ea16';
$icon-cleaning: '\ea25';
$icon-dashboard: '\ea26';
$icon-fines: '\ea27';
$icon-burger: '\ea30';
$icon-mixing: '\ea20';
$icon-networks: '\ea21';
$icon-paintBucket: '\ea22';
$icon-price: '\ea24';
$icon-starFull: '\ea15';
$icon-damage: '\ea36';
$icon-euro: '\ea37';
$icon-voucher: '\ea38';
$icon-vehiclePlanning: '\ea39';
$icon-smartcard: '\ea40';
$icon-companies: '\ea41';
$icon-subcompanies: '\ea42';
$icon-headset: '\ea43';
$icon-cog: '\ea23';
$icon-categories: '\ea44';
$icon-smartcardEvents: '\ea45';
$icon-checkCircleOutline: '\ea46';
$icon-horse: '\ea47';
$icon-memberTypes: '\ea48';
$icon-reports: '\ea49';
$icon-vehicle-statuses: '\ea50';
$icon-bankouts: '\ea51';
$icon-atv: '\ea52';
$icon-date-check: '\ea53';
$icon-color-fill: '\ea54';
$icon-sliders: '\ea55';
$icon-blocks: '\ea56';
$icon-wrench: '\ea57';
$icon-upload: '\ea58';
$icon-organization: '\ea59';
$icon-coupon: '\ea60';
$icon-graph: '\ea61';
$icon-calendar: '\ea62';
$icon-invoice: '\ea63';
$icon-businessman: '\ea64';
$icon-briefcase: '\ea65';
$icon-chart-curve: '\ea66';
$icon-scheduled-export: '\ea67';
$icon-partial-radio: '\ea68';
$icon-highlight-off: '\ea69';
