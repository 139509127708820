.newVehicleForm {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  .buttonUpload_name {
    display: none;
  }

  &_noCompany {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.8);
  }

  &_rightColumn {
    margin-right: 15px;
    padding-left: 15px;
  }

  &_container {
    margin: 15px 0;
    width: 100%;
    padding: 20px;
    background: white;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
  }

  &_row {
    display: flex;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
  }

  &_carImg {
    position: relative;
    margin: 20px 0px;
    width: 235px;
    height: 150px;
  }

  &_carImg:hover {
    .newVehicleForm_carImgHover {
      display: flex;
    }
  }

  &_carImgHover {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  &_carImg img {
    object-fit: cover;
    width: 235px;
    height: 150px;
  }

  &_fieldWrap {
    display: flex;
    flex-wrap: wrap;
  }

  &_field {
    margin: 0 15px;
    width: 235px;

    &_remove_device {
      position: relative;

      .clear-icon {
        color: #e0e0e0 !important;
        position: absolute;
        bottom: 14px;
        right: 14px;
        width: 20px !important;
        cursor: pointer;

        &:hover {
          color: #a7a7a7 !important;
        }

        &.disabled {
          display: none !important;
        }
      }
    }

    &_text {
      width: 100%;
      flex-wrap: nowrap;

      .ekTextareaWrapper {
        margin: 0 15px;
      }

      textarea {
        resize: vertical;
      }
    }

    .boxedSelect_label {
      &.error {
        color: red;
      }
    }
  }

  &_buttonWrap {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 30px;
  }

  &_button {
    text-transform: uppercase;
  }

  &_subHeader {
    width: 100%;
    margin-left: 15px;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    margin-top: 5px;
  }
}

$vehicleForm: (
  vehicleForm-bg-color: $color-white,
  vehicleForm-text-color: $color-primary-darkest,
  vehicleForm-text-size: 11,
  vehicleForm-button-color: $color-primary-darkest
);

.vehicleFromWrapper {
  margin-top: 30px;
}

.vehicleForm {
  &_container {
    margin-top: 30px;
  }

  &_containerInner {
    @include table-display();
  }

  &_section {
    @include table-cell(top);
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &--full {
      display: block;
    }

    &_buttonUpload {
      min-width: 218px;
      margin-left: 25px;
    }
  }

  &_block {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    padding: 30px;
    background-color: map_get($vehicleForm, vehicleForm-bg-color);
  }

  &_aside {
    @include table-cell(top);
    width: 272px;
    padding-left: 25px;
    box-sizing: content-box;
  }

  &_sectionTitle {
    @include remIt(font-size, map_get($vehicleForm, vehicleForm-text-size));
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &_row {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_upload {
    margin-bottom: 20px;
  }

  &_uploadInput {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(stretch);
    @include justify-content(flex-start);
  }

  &_uploadMedia {
    position: relative;
    border: 1px dashed $color-primary2-alt;
    background: $color-white url('#{$img-path}upload-media.svg') no-repeat center center;
    display: block;
    width: 272px;
    height: 179px;
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_uploadMedia_img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  &_viewPictureButton {
    @include flex-basis(45px);
  }

  &_ekRadioTitle {
    padding-bottom: 3px;
  }

  &_ekRadioContainer {
    @include box-columns(2, 20px);
  }

  &_ekRadioWrapper {
    margin-top: 5px;
  }

  &_boxedInputWrapperUpload {
    @include flex(1);
  }

  &_boxedInputLabelTextPicture {
    display: none;
  }

  &_labeledCheckbox {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  &_labeledCheckboxLabel {
    @include remIt(font-size, 12);

    &:before {
      vertical-align: bottom;
    }
  }

  &_btnReveal {
    @include box-opacity(1);
    background-color: $color-primary-darkest;
    color: $color-white;
  }

  &_extraField {
    @include transition(all 0.2s ease-in);
    visibility: hidden;
    opacity: 0;

    &--show {
      visibility: visible;
      opacity: 1;
    }
  }

  &_actions {
    text-align: right;
    margin-top: 45px;
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
  }

  &_actionsLink {
    margin-right: 20px;
  }

  &_formRowsVehicleIds {
    .vehicleForm_item {
      max-width: 230px;
    }
  }

  &_formRowsOptions {
    .vehicleForm_item {
      max-width: 280px;
    }
  }

  &_formRowsVehicleConfigs {
    .vehicleForm_item {
      max-width: 230px;
    }
  }
}

.fieldErrorMsg--vehicleForm {
  margin-top: 10px;
}

.vehicleForm_errorsRecap {
  color: $color-error;
}

.vehicleForm_errorsRecap_errors {
  @include remIt(font-size, 12);
}

.vehicleForm_Picture {
  margin: 0;
  width: 80%;
  height: 200px;
  display: inline-block;
  text-align: right;
  float: right;
}

.vehicleForm_Picture img {
  max-height: 100%;
  max-width: 100%;
}
