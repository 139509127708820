.vehicleDamagesFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: $color-white;
  margin-bottom: 15px;
  padding: 25px 10px;
}

.searchVehicleDamagesWrapper {
  .boxedSelectBlock {
    display: inline-block;
    margin-right: 14px;

    select {
      width: 200px;
    }
  }

  .vehicleDamagesPage_search {
    width: 200px;
    display: inline-block;
    height: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-right: 15px;

    .boxedInput_label:before {
      left: inherit;
      right: 15px;
    }
  }

  &_btn {
    font-weight: bold;
    width: 100px;
    height: 40px;
  }

  &_actions {
    text-align: right;
    margin-top: 15px;

    button {
      margin-right: 30px;
    }
  }
}
