.parkingsPage {
  &_content {
    @include advanced-list-page-padding;
    height: 100%;
    font-family: $main-font;
  }

  &_title {
    @include pageTitle();
  }

  &_management {
    display: flex;
    flex-direction: column;
    height: 100%;

    .advancedList {
      &_detailIcon {
        margin-top: 15px;
      }
    }
  }

  &_managementHeader {
    margin-bottom: -35px;
  }

  &_pagination {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);
    overflow: hidden;
    margin-bottom: 10px;
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.parkings_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}
