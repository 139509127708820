.list-modal {
  .addSubCompanyPage {
    height: auto;

    .pageContainer {
      padding: 0;

      .addSubCompanyPage_content {
        padding: 0 30px 30px;
      }
    }
  }
}
