.search {
  &-resultsWrap {
    border-radius: 2px;
    background: white;
    box-shadow: 0 2px 2px 0 $color-border-shadow5, 0 1px 5px 0 $color-border-shadow2, 0 3px 1px -2px $color-border-shadow1;
  }

  &-resultRow {
    border-bottom: 1px solid #e0e0e0;
    padding: 0 20px;
    display: flex;
    height: 50px;
    align-items: center;
    cursor: pointer;

    &.member-row {
      cursor: default;

      > * {
        cursor: pointer;
      }
    }

    .icon-button {
      &:hover {
        background: white;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &-resultRow:hover {
    background: #f5f5f5;

    .search-resultActions {
      display: block;
    }
  }

  &-resultIcon {
    min-width: 35px;
    height: 35px;
    background: #ec417a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
    color: white;

    &.uncolor {
      background-color: unset;
    }
  }

  &-resultCol {
    width: 30%;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    padding: auto 0;
  }

  &-resultActions {
    position: absolute;
    right: 20px;
    display: none;
    top: 3px;
  }

  &-resultTitle {
    display: flex;
    justify-content: space-between;
    padding: 25px 60px 10px 20px;
    color: #616161;
    font-size: 14px;
  }

  &-spacer {
    width: 4px;
  }

  &-bookingIcon {
    width: 44px;
  }

  &-resultOverlayClickable {
    position: absolute;
    width: 80%;
    height: 100%;
  }

  &-noResults {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
  }

  &-errors {
    color: red;
    display: flex;
    margin-top: 20px;

    & > * {
      margin-right: 5px;
    }
  }
}
