/* _____ MIXINS _____ */

// Fonts
// ==========================================================================

@mixin importFont($family, $path, $type: 'woff', $bold: false, $light: false, $medium: false) {
  $src: $path + '.' + $type;

  @font-face {
    font-family: $family;
    src: url($src) format($type);

    @if $bold {
      font-weight: bold;
    } @else if $light {
      font-weight: $light-weight;
    } @else if $medium {
      font-weight: $medium-weight;
    } @else {
      font-weight: normal;
    }
  }
}

// Units
// ==========================================================================

@function pxToRem($pxValue) {
  @return $pxValue / $baseFontSize;
}

@mixin remIt($attr, $values, $before: '', $between1: ' ', $between2: ' ', $between3: ' ', $after: '', $useModernizr: true) {
  $cssremunit: getPrefix(cssremunit, $useModernizr);

  // it's good to keep the fallback at all times for debug purpose

  @if length($values) == 1 {
    #{$attr}: #{$before}#{nth($values, 1)}px#{$after};
  }
  @if length($values) == 2 {
    #{$attr}: #{$before}#{nth($values, 1)}px#{$between1}#{nth($values, 2)}px#{$after};
  }
  @if length($values) == 3 {
    #{$attr}: #{$before}#{nth($values, 1)}px#{$between1}#{nth($values, 2)}px#{$between1}#{nth($values, 3)}px#{$after};
  }
  @if length($values) == 4 {
    #{$attr}: #{$before}#{nth($values, 1)}px#{$between1}#{nth($values, 2)}px#{$between1}#{nth($values, 3)}px#{$between1}#{nth(
        $values,
        4
      )}px#{$after};
  }

  #{map-get($cssremunit, yes)} & {
    @if length($values) == 1 {
      #{$attr}: #{$before}#{pxToRem(nth($values, 1))}rem#{$after};
    }
    @if length($values) == 2 {
      #{$attr}: #{$before}#{pxToRem(nth($values, 1))}rem#{$between1}#{pxToRem(nth($values, 2))}rem#{$after};
    }
    @if length($values) == 3 {
      #{$attr}: #{$before}#{pxToRem(nth($values, 1))}rem#{$between1}#{pxToRem(nth($values, 2))}rem#{$between1}#{pxToRem(
          nth($values, 3)
        )}rem#{$after};
    }
    @if length($values) == 4 {
      #{$attr}: #{$before}#{pxToRem(nth($values, 1))}rem#{$between1}#{pxToRem(nth($values, 2))}rem#{$between1}#{pxToRem(
          nth($values, 3)
        )}rem#{$between1}#{pxToRem(nth($values, 4))}rem#{$after};
    }
  }
}

// Helpers
// ==========================================================================

@mixin box-opacity($value) {
  opacity: $value; // Chrome 4+, FF2+, Saf3.1+, Opera 9+, IE9, iOS 3.2+, Android 2.1+
  filter: alpha(opacity = $value * 100); // IE6-IE8
}

@mixin border-radius($value, $useModernizr: true) {
  $borderradius: getPrefix(borderradius, $useModernizr);

  #{map-get($borderradius, yes)} & {
    // scss-lint:disable PropertySortOrder
    -webkit-border-radius: #{$value}; // Saf3-4, iOS 1-3.2, Android ≤1.6
    border-radius: #{$value}; // Opera 10.5, IE9, Saf5, Chrome, FF4+, iOS 4, Android 2.1+

    // useful if you don't want a bg color from leaking outside the border:
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
}

@mixin box-shadow($value, $fallback: '', $useModernizr: true) {
  $boxshadow: getPrefix(boxshadow, $useModernizr);

  #{map-get($boxshadow, yes)} & {
    -webkit-box-shadow: #{$value}; // Saf3-4, iOS 4.0.2 - 4.2, Android 2.3+
    box-shadow: #{$value}; // Opera 10.5, IE9, FF4+, Chrome 6+, iOS 5
  }

  @if $fallback != '' {
    #{map-get($boxshadow, no)} & {
      border-color: #{$fallback};
    }
  }
}

@mixin text-shadow($value, $useModernizr: true) {
  $textshadow: getPrefix(textshadow, $useModernizr);

  #{map-get($textshadow, yes)} & {
    text-shadow: #{$value}; // Chrome, Firefox 3.5+, IE 10+, Opera 9+, Safari 1+
  }
}

@mixin box-columns($nb: 3, $gap: 10px, $useModernizr: true) {
  $csscolumns: getPrefix(csscolumns, $useModernizr);

  #{map-get($csscolumns, yes)} & {
    // scss-lint:disable PropertySortOrder
    -webkit-column-count: $nb;
    -webkit-column-gap: $gap; // Safari 3, Chrome
    -moz-column-count: $nb;
    -moz-column-gap: $gap; // Firefox 3.5+
    column-count: $nb;
    column-gap: $gap; // Opera 11+
  }
}

@mixin background-size($value, $useModernizr: true) {
  $backgroundsize: getPrefix(backgroundsize, $useModernizr);

  #{map-get($backgroundsize, yes)} & {
    -webkit-background-size: $value; // Saf3-4
    background-size: $value; // Opera, IE9, Saf5, Chrome, FF4+
  }
}

@mixin linear-gradient($from, $to, $fallback: '', $useModernizr: true) {
  $cssgradients: getPrefix(cssgradients, $useModernizr);

  #{map-get($cssgradients, no)} & {
    @if $fallback != '' {
      background-color: $fallback;
    } @else {
      background-color: mix($from, $to);
    }
  }

  #{map-get($cssgradients, yes)} & {
    background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to)); // Saf4+, Chrome
    background-image: -webkit-linear-gradient(top, $from, $to); // Chrome 10+, Saf5.1+, iOS 5+
    background-image: -moz-linear-gradient(top, $from, $to); // FF3.6+
    background-image: -o-linear-gradient(top, $from, $to); // Opera 11.10+
    background-image: linear-gradient(to bottom, $from, $to);
  }
}

// TOIMPROVE: rewrite with lists and make generic
@mixin linear-gradient-double($from1, $to1, $from2, $to2, $type: 'vertical', $fallback: '', $useModernizr: true) {
  $cssgradients: getPrefix(cssgradients, $useModernizr);

  #{map-get($cssgradients, no)} & {
    @if $fallback != '' {
      background-color: $fallback;
    } @else {
      background-color: mix($from1, $to1);
    }
  }

  $endPointWebkit: 'left bottom';
  $endPointVendor: 'top';
  $endPoint: 'bottom';
  @if $type == 'horizontal' {
    $endPointWebkit: 'right top';
    $endPointVendor: 'left';
    $endPoint: 'right';
  }

  #{map-get($cssgradients, yes)} & {
    background-image: -webkit-gradient(
      linear,
      left top,
      #{$endPointWebkit},
      color-stop(0, $from1),
      color-stop(0.5, $to1),
      color-stop(0.51, $from2),
      to($to2)
    ); // Saf4+, Chrome
    background-image: -webkit-linear-gradient(#{$endPointVendor}, $from1, $to1 50%, $from2 51%, $to2); // Chrome 10+, Saf5.1+, iOS 5+
    background-image: -moz-linear-gradient(#{$endPointVendor}, $from1, $to1 50%, $from2 51%, $to2); // FF3.6+
    background-image: -o-linear-gradient(#{$endPointVendor}, $from1, $to1 50%, $from2 51%, $to2); // Opera 11.10+
    background-image: linear-gradient(to #{$endPoint}, $from1, $to1 50%, $from2 51%, $to2);
  }
}

// Animations
// ==========================================================================

@mixin animation($value, $useModernizr: true) {
  $cssanimations: getPrefix(cssanimations, $useModernizr);

  #{map-get($cssanimations, yes)} & {
    -moz-animation: #{$value}; // Firefox 5-15
    -o-animation: #{$value}; // Opera 12+
    -webkit-animation: #{$value}; // Chrome, Safari 5
    animation: #{$value}; // Chrome, Firefox 16+, IE 10+, Safari 5
  }
}

@mixin transition($value, $useModernizr: true) {
  $csstransitions: getPrefix(csstransitions, $useModernizr);

  #{map-get($csstransitions, yes)} & {
    -moz-transition: #{$value}; // FF4+
    -o-transition: #{$value}; // Opera 10.5+
    -webkit-transition: #{$value}; // Saf3.2+, Chrome
    transition: #{$value};
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Transforms
// ==========================================================================

@mixin transform($value, $useModernizr: true) {
  $csstransforms: getPrefix(csstransforms, $useModernizr);

  #{map-get($csstransforms, yes)} & {
    -moz-transform: #{$value}; // FF3.5+
    -ms-transform: #{$value}; // IE9
    -o-transform: #{$value}; // Opera 10.5
    -webkit-transform: #{$value}; // Saf3.1+, Chrome
    transform: #{$value};
  }
}

@mixin box-rotate($degrees, $useModernizr: true) {
  $csstransforms: getPrefix(csstransforms, $useModernizr);

  #{map-get($csstransforms, yes)} & {
    -moz-transform: rotate(#{$degrees}deg); // FF3.5+
    -ms-transform: rotate(#{$degrees}deg); // IE9
    -o-transform: rotate(#{$degrees}deg); // Opera 10.5
    -webkit-transform: rotate(#{$degrees}deg); // Saf3.1+, Chrome
    transform: rotate(#{$degrees}deg);
  }
}

@mixin box-transform($val, $useModernizr: true) {
  $csstransforms: getPrefix(csstransforms, $useModernizr);

  #{map-get($csstransforms, yes)} & {
    -moz-transform: #{$val};
    -ms-transform: #{$val};
    -o-transform: #{$val};
    -webkit-transform: #{$val};
    transform: #{$val};
  }
}

@mixin box-skew($degrees1, $degrees2, $useModernizr: true) {
  $csstransforms: getPrefix(csstransforms, $useModernizr);

  #{map-get($csstransforms, yes)} & {
    -ms-transform: skew(#{$degrees1}deg, #{$degrees2}deg); // IE 9
    -webkit-transform: skew(#{$degrees1}deg, #{$degrees2}deg); // Safari and Chrome
    transform: skew(#{$degrees1}deg, #{$degrees2}deg);
  }
}

@mixin box-scale($ratio, $useModernizr: true) {
  $csstransforms: getPrefix(csstransforms, $useModernizr);

  #{map-get($csstransforms, yes)} & {
    -moz-transform: scale($ratio); // Firefox 3.5+
    -ms-transform: scale($ratio); // IE 9
    -o-transform: scale($ratio); // Opera 10.50-12.00
    -webkit-transform: scale($ratio); // Chrome, Safari 3.1+
    transform: scale($ratio); // Firefox 16+, IE 10+, Opera 12.10+
  }
}

@mixin transform-matrix($value, $useModernizr: true) {
  $csstransforms: getPrefix(csstransforms, $useModernizr);

  #{map-get($csstransforms, yes)} & {
    -moz-transform: matrix(#{$value});
    -ms-transform: matrix(#{$value});
    -o-transform: matrix(#{$value});
    -webkit-transform: matrix(#{$value});
    transform: matrix(#{$value});
  }
}

@mixin cssTriangle($triangleSize, $triangleColor, $triangleDirection) {
  // scss-lint:disable ColorVariable
  content: '';
  display: block;
  width: auto;
  height: auto;
  border: solid $triangleSize;
  @if ($triangleDirection == top) {
    border-color: $triangleColor transparent transparent;
  }
  @if ($triangleDirection == bottom) {
    border-color: transparent transparent $triangleColor;
  }
  @if ($triangleDirection == left) {
    border-color: transparent transparent transparent $triangleColor;
  }
  @if ($triangleDirection == right) {
    border-color: transparent $triangleColor transparent transparent;
  }
  // .example { @include cssTriangle(5px, #fff, bottom); }
}

// Flexbox
// ==========================================================================
// Flexbox Mixins
// http://philipwalton.github.io/solved-by-flexbox/
// https://github.com/philipwalton/solved-by-flexbox
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 0; //Firefox35 fix
}

//----------------------------------
@mixin inline_flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

// Shorter version:
@mixin flex-dir($args...) {
  @include flex-direction($args...);
}

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex-flow(
  $values: (
    row nowrap
  )
) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;
  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }
  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

// Shorter version:
@mixin flex-just($args...) {
  @include justify-content($args...);
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

// Basic clickable (reset button and link styles)
// ==========================================================================
@mixin basicClickable {
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

// Text truncate
// ==========================================================================
@mixin textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* _____ UNIVERSAL HELPERS _____ */

// From H5BP
// ==========================================================================

// Image replacement
@mixin ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;

  &:before {
    display: block;
    width: 0;
    height: 100%;
    content: '';
  }
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  //
  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p
  //
  &.focusable:active,
  &.focusable:focus {
    position: static;
    margin: 0;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout
@mixin invisible {
  visibility: hidden;
}

// Contain floats: h5bp.com/q
@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: ' ';
  }

  &:after {
    clear: both;
  }
}

// from Ekino  ***** */

@mixin verticalAlign {
  &:before {
    @include inlineBlock;
    margin: 0;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  vertical-align: middle;
}

@mixin verticalAlign2 {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin nonSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Placeholder
// ==========================================================================
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// Text overflow
// ==========================================================================
@mixin text-overflow($value) {
  -o-text-overflow: $value;
  text-overflow: $value;
}

// Appearance
// ==========================================================================
@mixin appearance($value: none) {
  -webkit-appearance: $value;
  -ms-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

// from GLIDE

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-valign-center() {
  display: flex;
  align-items: center;
}

@mixin flex-halign-center() {
  display: flex;
  justify-content: center;
}

// add iconFont icon

$iconDefaultSize: 20px;
$containerDefaultMargin: 4px;

@mixin addIcon(
  $icon,
  $size: $iconDefaultSize,
  $color: $color-grey-light,
  $parentSize: null,
  $parentHeight: null,
  $parentWidth: null,
  $centerIcon: true
) {
  @if ($parentHeight == null) {
    @if ($parentSize == null) {
      $parentHeight: $size + $containerDefaultMargin;
    } @else {
      $parentHeight: $parentSize;
    }
  }

  @if ($parentWidth == null) {
    @if ($parentSize == null) {
      $parentWidth: $size + $containerDefaultMargin;
    } @else {
      $parentWidth: $parentSize;
    }
  }

  @if $centerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  height: $parentHeight;
  width: $parentWidth;

  &:before {
    content: $icon;
    font-family: $icons-font;
    color: $color;
    font-size: $size;
    height: $size;
    display: inline-flex;
    align-items: center;
  }
}

// vehicle-tile shared css

@mixin vehicleTile($widthConstraint: '') {
  $tile-width: 270px;
  $pic-width: 115px;
  $content-width: 140px;
  $marge-left-tile: 17px;
  $marge-left-pic: 6px;
  $marge-right-pic: 0;
  $marge-left-content: 15px;
  $height: 100px;
  $textDeco: normal;

  @if ($widthConstraint == 'no') {
    $tile-width: 100%;
    $pic-width: calc(50% - 16px);
    $content-width: 50%;
    $marge-left-tile: 0;
    $marge-left-pic: 0;
    $marge-right-pic: 16px;
    $marge-left-content: 5px;
    $height: 160px;
    $textDeco: underline;
  }

  span.vp-vt,
  span.vp-vt a {
    text-decoration: none;
    color: black;
    width: 100%;
    cursor: pointer;
  }

  .vehicle-tile {
    background-color: $color-white;
    margin-left: $marge-left-tile;
    width: $tile-width;
    height: $height;
    border-radius: 4px 0 0 4px;
    display: flex;
    align-items: center;

    a {
      display: contents;

      .pic {
        display: flex;
        justify-content: center;
        height: $height - 10px;
        width: $pic-width;
        margin-left: $marge-left-pic;
        margin-right: $marge-right-pic;
        overflow: hidden;
        border-radius: 4px;
        position: relative;

        .vehicle-usage-in-pic {
          position: absolute;
          bottom: 0;
          right: 5px;
          font-size: 14px;
        }
      }
    }

    .pic {
      display: flex;
      justify-content: center;
      height: $height - 10px;
      margin-left: $marge-left-pic;
      margin-right: $marge-right-pic;
      overflow: hidden;
      border-radius: 4px;
      position: relative;

      .vehicle-usage-in-pic {
        position: absolute;
        bottom: 0;
        right: 5px;
        font-size: 14px;
      }
    }

    .p {
      width: 50%;
      height: 100%;
    }

    .content {
      display: flex;
      width: $content-width;
      margin-left: $marge-left-content;
      flex-direction: column;
      align-items: flex-start;

      @if ($widthConstraint == 'no') {
        .fuel {
          display: none;
        }
      } @else {
        .fuel {
          display: flex;
          align-items: center;
          align-content: center;

          > span:nth-child(1):not(.vehicleInfo) {
            margin-right: 4px;
            padding-bottom: 3px;
          }
        }
      }

      a {
        color: inherit;
      }

      .vehicleInfo {
        text-decoration: $textDeco;
      }

      .vehicleInfo,
      .fuel {
        margin-bottom: 3px;

        &.b {
          font-weight: bold;
        }
      }
    }

    .vehicle-usage,
    .vehicle-usage-in-pic {
      border-radius: 10px;
      text-align: center;
      font-size: 10px;
      color: $color-white;
      padding: 1px 5px;
    }
  }
}
