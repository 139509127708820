.booking-member {
  &_headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    margin: 0;
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;

    & .row-info {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 50px;

      .label {
        color: grey;
        font-size: 12px;
      }

      .value {
        color: black;
        font-weight: 500;
        word-break: break-all;

        a {
          color: black;
        }

        .link {
          cursor: pointer;
        }

        .member-status {
          padding: 3px 0;
          font-weight: normal;
          font-size: 12px;
          display: inline-block;
          min-width: 80px;
          text-align: center;

          &_applied {
            color: #474747;
            background-color: #cacaca;
          }

          &_to_complete {
            color: #fff;
            background-color: #858585;
          }

          &_to_review {
            color: #fff;
            background-color: #f49b01;
          }

          &_approved {
            color: #fff;
            background-color: $color-completed;
          }

          &_not_approved {
            color: #fff;
            background-color: #ff3a2f;
          }
        }

        .svg-inline {
          display: inline-flex;
        }

        .svg-icon {
          &.member-files {
            width: 50px;
            height: 30px;
          }
        }
      }
    }
  }

  &_actions {
    display: flex;
    justify-content: center;
    height: 70px;
    align-items: flex-end;
    padding-bottom: 10px;

    & > div {
      margin: 5px;
    }

    span {
      text-transform: none;
    }

    &.air {
      height: 120px;
    }
  }

  .error-line {
    color: $color-canceled;
    display: flex;
    align-items: center;
  }
}

.driving-files-modal {
  .modal-container {
    background-color: transparent !important;
    display: flex;
    border: 0;

    > div {
      background-color: white;
      border-radius: 3px;
    }
    .info {
      width: 20%;
      padding: 20px 10px;
      margin-right: 4px;

      .title {
        font-weight: 700;
        margin-bottom: 10px;
      }

      .label {
        color: grey;
      }

      .value {
        color: black;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }

    .files {
      width: 80%;
      padding: 10px;

      .close-btn {
        position: absolute;
        right: 0;
        top: 0;
      }

      .wide-pic {
        display: flex;
        justify-content: center;

        .wrap-img,
        .wrap-pdf {
          height: calc(100vh - 200px) !important;
          width: calc(100% - 80px);

          iframe {
            height: calc(100vh - 200px);
          }
        }
      }
      .img-actions {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        .component-icon-container {
          display: flex;
          align-content: center;
          align-items: center;
          cursor: pointer;

          button {
            margin: 0 3px;
            border-radius: 40px;
            background-color: black;
            display: flex;
            align-content: center;
            align-items: center;
            height: 40px;
            justify-content: center;

            &.big {
              height: 50px;
              width: 50px;
            }
            svg path {
              stroke: #cacaca;
              color: #cacaca;
            }
          }
        }
      }

      .wrapper-thumbs {
        display: flex;
        justify-content: center;

        .wrap-img {
          width: 120px;
          max-height: 60px;
          padding: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
