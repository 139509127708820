.timeSlotsForm {
  @include clearfix;
  text-align: right;
}

.timeSlotsForm_addTimeSlot_button {
  min-width: 218px;
  float: right;
}

.timeSlotsForm_timeSlots {
  clear: both;
  text-align: left;

  .fieldErrorMsg {
    margin: 0 5px;
  }
}

.timeSlotsForm_timeSlots_item {
  @include table-display();
  padding: 10px;
  margin: 5px 0;
  background: #ededed;
  border-radius: 5px;
  transition: border 0.3s ease;

  &._has_error {
    border: 1px solid $color-error;
  }

  .overlap-errors {
    @include remIt(font-size, 14);
    color: $color-error;
    padding-bottom: 5px;
    display: block;
  }
}

.timeSlotsForm_timeSlots_item_content {
  @include table-cell();
}

.timeSlotsForm_timeSlots_group {
  display: inline-block;
}

$timeSlotsForm_timeSlots_item_removeButtonHeight: 20;

.timeSlotsForm_timeSlots_item_actionButton {
  @include table-cell();
  @include remIt(width, $timeSlotsForm_timeSlots_item_removeButtonHeight);
  padding: 0 10px;

  svg {
    cursor: pointer;

    &:hover {
      color: $color-secondary-light !important;
    }

    &[disabled] {
      display: none !important;
    }

    &.delete {
      color: rgba($color-error-light, 0.7) !important;

      &:hover {
        color: $color-error-light !important;
      }
    }
  }
}

.timeSlotsForm_submitButton {
  &.timeSlots_price_per_hour,
  &.timeSlots_package_between_dates,
  &.timeSlots_package_for_duration {
    display: none;
  }
}

.timeSlotsForm_errors {
  @include remIt(font-size, 14);
  text-align: left;
  margin: 10px 20px;
  color: $color-error;

  &_item + &_item {
    margin-top: 10px;
  }
}

.timeSlot_fromLabel {
  @include remIt(font-size, 12);
  text-transform: uppercase;
}

.boxedSelect,
.boxedInput {
  &._has_error {
    border: solid 1px $color-error;
  }
}

.timeSlot_boxedSelectBlock {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  margin-right: 10px;

  .fieldErrorMsg--timeSlotsForm {
    margin-top: 5px;
    height: 30px;
    color: $color-error;
  }
}

.timeSlot_boxedSelectBlock--fromHour,
.timeSlot_boxedSelectBlock--fromMinutes,
.timeSlot_boxedSelectBlock--toHour,
.timeSlot_boxedSelectBlock--toMinutes {
  margin-right: 5px;
}

.timeSlot_boxedSelectBlock--fromMinutes {
  margin-right: 30px;
}

.timeSlot_boxedSelectBlock--fromHour,
.timeSlot_boxedSelectBlock--toHour {
  margin-right: 10px;
}

.timeSlot_boxedSelectWrapper {
  display: inline-block;
  width: 150px;

  &._has_error {
    border: solid 1px $color-error;
  }
}

.timeSlot_from_label,
.timeSlot_to_label,
.timeSlot_duration_hour_label {
  display: inline-block;
}

.timeSlotsForm_submitButton {
  min-width: 218px;
  margin-top: 10px;
}

.timeSlot_price_boxedInputWrapper {
  display: inline-block;
  vertical-align: top;
  margin-top: 6px;
}

.timeSlot_price_boxedInputLabel,
.timeSlot_duration_boxedInputLabel {
  display: inline-block;
  vertical-align: top;
}

.timeSlot_price_boxedInputLabel {
  margin-left: 0;
}

.timeSlot_price_boxedInputLabel,
.timeSlot_duration_boxedInputLabel {
  .boxedInput_inputText {
    display: inline-block;
  }
}

.timeSlot_price_boxedInputLabelText {
  display: inline-block;
  margin-right: 5px;
}

.timeSlot_price_boxedInputInput {
  width: 60px;
}

.timeSlot_price_unit,
.timeSlot_duration_unit {
  @include remIt(font-size, 11);
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 5px;
  margin-right: 15px;
  padding-top: 12px;
  font-style: normal;
  color: $color-primary-darkest;
}
