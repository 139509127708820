.translations {
  &.area {
    .translation-entry {
      align-items: flex-start;
    }
  }
  .labels {
    display: block;
  }
  .translation-entry,
  .translation-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div,
    button {
      &.lang {
        flex-basis: 20%;
      }
      &.text {
        flex-basis: 60%;

        textarea {
          height: 120px;
        }
      }
    }
    .fieldErrorMsg--companyForm {
      margin-top: 3px;
      margin-bottom: 7px;
    }
  }
  .add-translarion {
    display: flex;
  }
}
