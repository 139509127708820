.addMemberForm {
  margin: 0 25px;

  .boxedSelectWrapper {
    display: block;
  }

  &.modal {
    margin: 0;
  }
}

.formRow_item {
  margin-bottom: 25px;
}

.addMemberForm_actions {
  margin-top: 25px;
  font-size: 0.875rem;
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 15px;
    color: #000000;
  }
}

.addMemberForm_actions input {
  color: #ffffff;
}
