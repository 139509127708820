.bankoutList {
  @include advanced-list-page-padding;
  display: flex;
  flex-direction: column;
  height: 100%;

  .advancedList_actions {
    width: 100%;
  }

  &_header {
    width: 100%;
    display: flex;
  }

  &_actionsButtons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
