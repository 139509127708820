.memberView_moreInfo {
  .suspended_true {
    color: $color-not-approved;
  }

  .imageWrap_closed {
    @include remIt(padding-right, 30);

    img {
      @include remIt(max-height, 100);
      max-width: 100%;
    }
  }

  .pdfWrap,
  .saving_wrap {
    @include remIt(padding-right, 30);
  }

  .pdfWrap_placeholder,
  .saving_placeholder {
    @include remIt(width, 200);
    @include remIt(height, 100);
    width: 100%;
  }
}
