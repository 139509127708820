.memberView_ProfileInfo {
  .detailView_row.red span {
    color: $color-error;
  }

  .detailView_table:first-child {
    margin-right: 50px;
  }

  .detailView_table:last-child {
    margin-right: auto;
  }

  &_body {
    &:empty {
      display: none;
    }
  }

  .memberView_reviewButtonsContainer {
    @include remIt(margin-left, -17);
  }
}
