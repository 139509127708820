.dashboardItem {
  width: calc(100% / 3);
  max-width: 320px;
  min-width: 256px;
  padding: 0 20px 40px;

  &_container {
    box-shadow: 0px 0px 15px 0px rgba($color-primary3, 0.5);
    background-color: $color-white;
  }

  &_head {
    @include remIt(font-size, 12);
    @include remIt(line-height, 15);
    width: 100%;
    background-color: $color-primary3-alt2;
    color: $color-primary-darkest;
    text-transform: uppercase;
    padding: 20px 10px;
  }

  &_link {
    color: $color-primary-darkest;
    text-decoration: none;
  }

  &_content {
    width: 100%;
    padding: 30px 0 50px;
    position: relative;
    height: 180px;
  }

  &_status {
    font-size: 0;
    margin: 0;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &_statusIcon {
    vertical-align: middle;
    width: 100%;
    height: 100%;

    .stroke {
      stroke: $color-main;
    }

    .fill {
      fill: $color-main;
    }

    &_container {
      max-height: 60%;
      max-width: 20%;
      margin-right: 10px;
    }
  }

  &_statusValue {
    @include remIt(font-size, 70);
    @include remIt(line-height, 83);
    display: inline-block;
    color: $color-secondary-light;
  }

  &_label {
    @include remIt(font-size, 17);
    @include remIt(line-height, 21);
    display: block;
    width: 100%;
    color: $color-primary-darkest;
    text-align: center;
    padding: 0 10px;
  }

  @media screen and (max-width: 760px) {
    &_img {
      width: 25%;
    }

    &_value {
      @include remIt(font-size, 62);
    }

    &_text {
      @include remIt(font-size, 13);
      @include remIt(line-height, 17);
    }
  }
}
