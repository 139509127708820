.bookingTransactionInfo {
  .detailView_component_headline_status,
  td {
    .status-green {
      color: $color-completed;
    }

    .status-red {
      color: $color-error;
    }

    .status-orange {
      color: $color-scheduled;
    }
  }

  .refusal-tooltip {
    width: 14px;
    display: inline-flex;
    height: 14px;
    border-radius: 16px;
    line-height: 16px;
    color: $color-white;
    margin-left: 5px;
    padding: 10px;
    background-color: $color-black;
    justify-content: center;
    align-items: center;
  }
}
