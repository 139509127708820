.brandsPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  td:first-child {
    word-break: break-all;
    width: 100%;
  }
}

.brandsPage_managementHeader {
  padding-bottom: 25px;
}

.brands_tableView_action {
  @include basicClickable();
  text-decoration: underline;
}
