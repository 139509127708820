@mixin main-tooltip-content {
  @include remIt(border-radius, 3);
  user-select: none;
  padding: 5px 10px;
  font-size: 0.8rem;
}

@include importOnce('project-globals') {
  /* _____ GLOBAL DEFAULTS _____ */

  html {
    width: 100%;
    height: 100%;
    font-size: 100%;
  }
  // http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    @include remIt(font-size, $baseFontSize);
    background: $color-primary-lighter;
    width: 100%;
    height: 100%;
    line-height: 1.4;
    font-family: $main-font;
    color: $color-black;
  }

  input::-ms-clear {
    width: 0;
    height: 0;
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button:focus {
    outline: none;
  }

  /* _____ LAYOUT _____ */

  .labelMandatory {
    margin-left: 3px;
    color: red;
  }

  .mainContainer {
    width: 100%;
    height: 100%;
    font-family: $main-font;
    margin: 0 auto;

    > div {
      height: 100%;
      width: 100%;

      > div {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
  }

  .pageWrap {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .mainContainer_content {
      flex: 1;
      transition: 0.3s;
    }

    &-menuClosed {
      width: 100%;

      .mainContainer_content {
        width: 100%;
      }
    }
  }

  .openMobile {
    left: 0 !important;
  }

  @media (max-width: 1200px) {
    .pageWrap {
      width: 100%;
    }

    .sideMenu {
      min-width: 0 !important;
      z-index: 999 !important;
    }

    .sideMenu_sections {
      left: -$side-menu-width;
    }

    .new-vehicle-planning_loadingOverlay.menu-opened {
      width: 100% !important;
    }

    .sideMenu_mobileMenuCloseOverlay {
      display: block !important;
    }

    .sideMenu._is_close .sideMenu_sections {
      display: block !important;
    }
  }

  .mainContainer--login {
    background-color: $color-primary-darkest;
  }

  .mainContainer_content {
    flex: 1;
    height: 100%;
    width: 100%;
  }

  .pageContainer {
    width: 100%;
    height: 100%;
    padding-top: $page-container-padding;
  }

  .pageContainerCentered {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 60px;
    height: 100%;
  }

  .link {
    color: #3572b0;
    cursor: pointer;
  }
  .linkh {
    color: #3572b0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .backLink {
    @include basicClickable();
    @include remIt(font-size, 18);
    text-decoration: none;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    height: 60px;
    width: 200px;
    margin-left: 12px;

    &-text {
      margin-left: 10px;
    }
  }

  .clearfix {
    &:before,
    &:after {
      content: ' ';
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .marginTop15 {
    margin-top: 15px;
  }

  .ReactModal__Overlay {
    z-index: 6;
  }

  /* _____ hint.css overrides _____ */
  [class*='hint--']:after {
    box-shadow: none;
    text-shadow: none;
    font-size: inherit;
    font-family: inherit;
  }

  // Tooltips themes

  .tooltip-black {
    @include main-tooltip-content;
    color: $color-white;
    background-color: #000;
    font-weight: normal;
  }

  .tooltip-dark {
    @include main-tooltip-content;
    color: $color-white;
    background-color: $color-bg-dark-clear;
    font-weight: normal;
  }

  .tooltip-grey {
    @include main-tooltip-content;
    color: $color-white;
    background-color: $color-bg-grey-clear;
    font-weight: normal;
  }

  .tooltip-grey-wide {
    @include main-tooltip-content;
    color: $color-white;
    background-color: $color-bg-grey-clear;
    font-weight: normal;
    width: calc(100% - 50px);
  }

  // Select company window class

  .page-info-block {
    margin: 20px;
    background: $color-white;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text-clear-dark;
    font-size: 25px;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
  }

  .svg-icon {
    vertical-align: middle;
    margin: 2px;
    width: 20px;
    height: 20px;
    fill: $color-grey-light;
  }

  .align-svg {
    display: inline-flex;

    > div {
      display: inline-flex;
    }
  }

  .sideMenuMobile_logo {
    max-width: 130px;
  }

  .raisedButton {
    color: white !important;
  }

  .flatButton {
    color: $color-main;

    svg {
      fill: currentColor !important;
      color: inherit !important;
    }
  }

  .dialog-actions {
    padding: 10px 15px 15px !important;

    > *:not(:last-child) {
      margin-right: 15px !important;
    }
  }

  .siteForm,
  .parkingForm {
    .sc-company-info {
      margin-left: 10px;

      .sc-dash {
        margin: 5px;
      }

      a {
        text-transform: none;
        color: #555;
      }
    }
  }

  .column-list-tooltip {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
  }

  .column-list-icon {
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.merged-cell {
      margin-right: 15px;
    }

    svg {
      fill: white !important;
    }

    .sc-status-icon {
      position: absolute;
      right: -5px;
      bottom: -5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #bfbfbf;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
  .dropdown-search-wrap {
    align-items: center;
    position: relative;
    display: flex;
    flex-grow: 1;

    .sc-search-icon {
      pointer-events: none;
      display: inline-flex;
      position: absolute;
      left: 5px;

      svg {
        color: $color-list-icon-button !important;
      }

      &.close {
        pointer-events: auto;
        cursor: pointer;

        &:hover svg {
          color: darken($color-list-icon-button, 20%) !important;
        }
      }
    }
  }
}
