.addVehiclesColorPage {
  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  .siteFormWrapper {
    max-width: 100%;
  }
}
