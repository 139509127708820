.tripMoreInfo {
  h4 {
    @include remIt(margin-bottom, 0);
    @include remIt(margin-top, 20);
  }

  h4:not(.single-column) {
    @include flex-halign-center;
  }

  h5 {
    @include remIt(margin-top, 17);
    @include remIt(margin-bottom, 20);
    color: $color-text-dark3;
  }

  &_comment {
    margin-left: 0.2em;
    max-width: 400px;
  }

  &_headline {
    @include flex-valign-center;
  }

  &_sub_block {
    display: inline-block;
    vertical-align: top;

    &:first-child {
      @include remIt(margin-right, 40);
    }

    &:last-child {
      @include remIt(margin-right, 0);
    }
  }
}
