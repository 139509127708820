.memberView_techInfo {
  &_main {
    display: flex;
    align-items: center;
  }

  &_companies {
    width: 100%;
  }

  &_arrows {
    @include remIt(margin-right, 20);
    @include remIt(margin-left, 20);
  }
}
