.pricingPage_content {
  font-family: $main-font;
  padding: 10px 25px 0;
}

.pricingPage_title {
  @include pageTitle();
}

.pricingPage_management {
  @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  padding: 20px;
  background: $color-white;
}

.pricingPage_management_section {
  margin-top: 20px;
  background-color: $color-white;

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: -40px;
  }
}

.pricingPage_noCategories {
  .pricingPage_management_sectionTitle {
    display: none;
  }

  section,
  form {
    &.surrounded-section {
      padding: 20px;
      border-spacing: 1px;
      border-color: var(--ss-primary-color);
      border-style: solid;
    }
  }

  .pricingPage_management_section > .actions {
    margin-top: -20px;
  }

  .vehicleCategoryPricingWidget {
    background: none;
    box-shadow: none;
  }

  .vehicleCategoryPricingWidget_content {
    border: 0;
    padding: 0;
  }

  .vehicleCategoryPricingWidget_header {
    visibility: hidden;
    position: absolute;
  }
}

.pricingPage_management_sectionTitle {
  @include remIt(font-size, 14);
  text-transform: uppercase;
  color: $color-primary-dark;
  background-color: $color-white;
}

.pricingPage_tabs {
  margin: 20px 0;
}

.pricingPage_tabItem {
  @include remIt(font-size, 14);
  display: inline-block;
  padding: 10px 20px 10px 15px;
  margin: 5px 0 0 10px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 10px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba($color-primary-dark, 0.1);
  }

  &.__is_selected {
    background-color: $color-white;
    color: $color-text-dark;
    box-shadow: 0 0 3px 0 $color-text-clear-dark;
    position: relative;

    &:after {
      content: '✓';
      position: absolute;
      text-indent: 0;
      top: -10px;
      left: auto;
      bottom: auto;
      right: -10px;
      font-size: 15px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 20px;
      height: 25px;
      width: 25px;
      text-align: center;
      line-height: 25px;
    }
  }

  span {
    line-height: 50px;
  }
}

.highlight {
  color: $color-tertiary;
}

.warning {
  color: $color-error-light;
}

.surrounded-section {
  border-radius: 5px;
  @include box-shadow(0px 0px 3px 2px rgba($color-primary3-alt2, 0.5));
  padding: 10px 20px 10px;
}
