.subCompanyDetailPage {
  .subCompanyForm {
    padding-bottom: 30px;

    .subCompanyForm_actions {
      margin-bottom: 0;
    }

    .formRow_item {
      padding-bottom: 25px;
    }

    .subCompanyForm_container {
      margin-top: 0;
    }

    &_actionsButton {
      min-width: 200px !important;
    }
  }

  &_head {
    display: flex;
    margin-bottom: 15px;
    justify-content: flex-end;
  }

  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
    font-weight: bold;
    display: inline-block;
  }

  &_actionButton {
    float: right;
    margin: 10px 0 20px 20px;
    width: 200px;
  }
}

.subCompanyDetail {
  @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  @include remIt(margin-bottom, 30);
  background-color: $color-white;
  padding: 30px;

  &_tabs {
    margin-top: 20px;
  }

  &_tabItem {
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;

    &.__is_selected {
      background-color: $color-white;
    }

    span {
      line-height: 50px;
    }
  }

  &_title {
    @include remIt(font-size, 13);
    text-transform: uppercase;
    font-weight: 200;
    margin-top: 0;
    vertical-align: middle;
  }

  &_line {
    width: 100%;
    font-size: 0;
    margin: 30px 0;
  }

  &_item {
    @include remIt(font-size, 13);
    display: inline-block;
    width: 100%;

    &_label {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
    }

    &_result {
      margin-top: 15px;
      display: block;
      font-weight: 100;
    }
  }

  &_line--double &_item {
    width: 50%;
  }

  &_line--quad &_item {
    width: 45%;

    &:nth-child(2) {
      width: 10%;
    }

    &:last-child {
      width: 100%;
      text-align: right;
    }
  }

  &_boxedSelectMultipleWrapper {
    min-width: 232px;
    height: 160px;
    margin-right: 10px;
  }

  &_boxedSelect {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  &_assignButton {
    @include basicClickable();
    @include transform(translateX(-50%));
    display: block;
    font-size: 0;
    position: relative;
    left: 50%;
    padding: 5px 0;

    &:disabled {
      cursor: not-allowed;
    }

    &:before {
      @include remIt(font-size, 23);
      content: $icon-chevron;
      font-family: $icons-font;
      display: inline-block;
    }

    &--left:before {
      @include box-rotate(180);
    }
  }

  &_text {
    @include remIt(margin-left, 10);
    @include remIt(font-size, 15);
    @include remIt(padding-bottom, 10);
    display: flex;
    align-items: center;
    color: $color-text-dark;

    .payment-icon {
      @include remIt(margin-right, 10);

      .circle-outline {
        @include addIcon($icon-checkCircleOutline, $size: 23px, $parentSize: 24px);
      }
    }
  }

  .subCompanyForm_actionsButton {
    margin-top: 50px;
  }

  &--payment {
    .submit-container {
      margin-top: 0.8em;
    }
  }
}
