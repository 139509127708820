$pie-padding: 10px;

.vehicle-statuses {
  $split-margin: 1rem;

  &-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-pie-container {
    padding: $pie-padding;
  }

  // fix pie overflow
  &-pie-inner > .VictoryContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-pie-legend {
    margin: $pie-padding;

    > .legend-item {
      color: $color-text-dark;

      .legend {
        &-circle {
          height: 1em;
          width: 1em;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin-right: 1em;
        }

        &-data {
          display: inline-block;
          vertical-align: middle;
        }

        &-time {
          color: $color-black;
        }
      }
    }
  }

  &-page {
    @include remIt(padding, 30 30 50);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 500px;

    .action-button-svg {
      color: $color-main !important;

      svg {
        fill: currentColor !important;
        color: inherit !important;
      }
    }

    .advancedList_chipsWrap {
      max-width: none;
      margin: 0 0 1.2rem;
    }
  }

  &-export-button {
    margin: 10px 0 !important;
  }

  &-report-form {
    flex-wrap: wrap;
    display: flex;
    background: $color-white;
    box-shadow: 0 1px 3px 0 $color-border-shadow1, 0 1px 1px 0 $color-border-shadow5, 0 2px 1px -1px $color-border-shadow2;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    padding: 0 10px;
  }

  &-dates-range {
    display: flex;
    flex-direction: row;
    margin-left: 10px;

    .dates-range div,
    .dates-range button {
      width: 100% !important;
    }
  }

  &-report-title {
    @include remIt(margin-top, 10);
    @include remIt(margin-bottom, 10);
    align-self: center;
    font-size: 1.2em;
    color: $color-text;
  }

  &-actions {
    flex-direction: column;
    display: flex;

    .advancedList_filters {
      width: auto;
      flex-grow: 1;
    }
  }

  $pie-side-margin: 80;

  &-pie-component {
    margin-left: $split-margin;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    .advancedList_container {
      height: 100%;
      width: 100%;
    }
  }

  &-body {
    display: flex;
    height: 100%;
    margin-top: 20px;

    .advancedList {
      &_error,
      &_data,
      &_loader,
      &_noResults {
        height: 100%;
      }

      &_container {
        min-height: 300px;
      }

      &_wrap {
        width: 50%;
        margin: 0;
      }

      $row-min-height: 3.25rem;

      &_row {
        @include ieFlexMinHeight($row-min-height);
        min-height: $row-min-height;
      }
    }
  }

  &-action-buttons {
    padding-left: $split-margin / 2;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    align-items: center;

    button > div {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 900px) {
  .vehicle-statuses {
    &-pie-legend {
      margin: $pie-padding / 2;
    }

    &-pie-container {
      padding: $pie-padding / 2;
    }

    &-action-buttons {
      width: 100%;
    }

    &-arrow {
      transform: rotate(90deg);
    }

    &-page {
      @include remIt(padding-bottom, 0);
    }

    &-body {
      flex-direction: column;

      .advancedList {
        &_wrap {
          width: 100%;
          min-height: 300px;
          margin: 0;
        }
      }
    }

    &-pie-component {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      min-height: 400px;
      margin-bottom: 30px;
    }
  }
}
