$flash-white: #fff;
$flash-black: #000;
$flash-dark: #2c3433;
$flash-grey: #9e9e9e;
$flash-disabled: #aaa;
$flash-success: #4caf50;
$flash-warning: #ff851b;
$flash-error: #ff4136;
$flash-info: #0074d9;
$flash-purple: #8a2be2;

.flash-container {
  position: fixed;
  bottom: 20px;
  left: 25px;
  z-index: $flash_message_z_index;
  max-width: 25%;

  .flash-message {
    display: flex;
    align-items: center;
    position: relative;
    opacity: 0;
    min-height: 28px;
    transform: translateX(-20px);
    transition: all 0.5s;
    background-color: $flash-white;
    color: $flash-dark;
    border-radius: 0 4px 4px 0;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 2px 2px 4px 2px rgba($flash-black, 0.1);
    line-height: 1.4;
    cursor: pointer;
    word-break: break-word;

    .flash-progress {
      position: absolute;
      right: 0;
      top: auto;
      bottom: 0;
      left: 0;
      width: 0;
      height: 4px;
      opacity: 1;
      filter: opacity(0.5);
      background-color: $flash-black;
      transition: opacity 0.1s;

      &.is-hidden {
        opacity: 0;
      }

      &.flash-progress-top {
        top: 0;
        bottom: auto;
      }
    }

    &:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: -7px;
      background-color: transparent;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:after {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      border-radius: 50%;
      font-size: 20px;
      color: $flash-grey;
      font-family: 'Font Awesome 5 Free' !important;
      font-weight: 900;
      filter: opacity(0.9);
    }

    &.is-visible {
      opacity: 1;
      transform: translateX(0);
    }

    &.flash-success {
      .flash-progress {
        background-color: $flash-success;
      }

      &:before {
        background-color: $flash-success;
      }

      &:after {
        color: $flash-success;
        content: '\f058';
      }
    }

    &.flash-warning {
      .flash-progress {
        background-color: $flash-warning;
      }

      &:before {
        background-color: $flash-warning;
      }

      &:after {
        color: $flash-warning;
        content: '\f071';
      }
    }

    &.flash-error {
      .flash-progress {
        background-color: $flash-error;
      }

      &:before {
        background-color: $flash-error;
      }

      &:after {
        color: $flash-error;
        content: '\f06a';
      }
    }

    &.flash-info {
      .flash-progress {
        background-color: $flash-info;
      }

      &:before {
        background-color: $flash-info;
      }

      &:after {
        color: $flash-info;
        content: '\f05a';
      }
    }

    &.flash-bug {
      .flash-progress {
        background-color: $flash-purple;
      }

      &:before {
        background-color: $flash-purple;
      }

      &:after {
        color: $flash-purple;
        content: '\f188';
      }
    }

    &.flash-disabled {
      .flash-progress {
        background-color: $flash-disabled;
      }

      &:before {
        background-color: $flash-disabled;
      }

      &:after {
        color: $flash-disabled;
        content: '\f05e';
      }
    }

    &.flash-default {
      padding-right: 20px;
    }
  }
}

@media all and (max-width: 1280px) {
  .flash-container {
    max-width: 33.334%;
  }
}

@media all and (max-width: 768px) {
  .flash-container {
    max-width: 50%;
  }
}

@media all and (max-width: 480px) {
  .flash-container {
    right: 10px;
    left: 10px;
    max-width: 100%;
  }
}

// --- Themes ---

.flash-container .flash-message.dark-theme {
  background-color: $flash-dark;
  color: $flash-white;

  .flash-progress {
    filter: opacity(0.9);
  }
}
