.scheduled-export {
  .column-list {
    .dropdown-search-wrap.auto-complete {
      display: flex;
    }

    > .sc-items {
      .sc-item-wrap {
        &.selected {
          transition: all ease 0.3s;

          .svg-icon > svg {
            color: white !important;
          }
        }

        .sc-list-item {
          padding: 15px 12px 15px 0;

          .svg-icon {
            width: 40px;
            height: 40px;

            svg {
              width: 40px !important;
              height: 40px !important;
            }
          }

          .item-content {
            margin-left: 5px;

            .item-title {
              font-weight: 700;
              min-width: 200px;
            }

            .item-title,
            .item-desc {
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 210px;
            }

            .item-desc span:nth-child(2) {
              max-width: 180px;

              &::after {
                content: ')';
              }
            }
          }
        }
      }
    }
  }
}
