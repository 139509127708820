.list-select-component {
  display: flex;
  align-items: center;

  .list-select-container {
    min-width: 200px;
  }

  .list-select {
    height: 160px;
  }

  $button-side-margin: 25px;

  .button-container {
    margin-left: $button-side-margin;
    margin-right: $button-side-margin;
  }

  .select-button {
    @include basicClickable();
    @include transform(translateX(-50%));
    left: 50%;
    display: block;
    font-size: 0;
    position: relative;

    &:before {
      @include remIt(font-size, 23);
      content: $icon-chevron;
      font-family: $icons-font;
      display: inline-block;
    }
  }

  $button-v-margin: 20px;

  .left-button {
    margin-bottom: $button-v-margin;

    &:before {
      @include box-rotate(180);
    }
  }

  .right-button {
    margin-top: $button-v-margin;
  }
}
