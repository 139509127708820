// Sidemenu constructor
// ==========================================================================

$sideMenu: (
  sideMenu-items: (
    'dashboard': $icon-dashboard,
    'dashboardV2': $icon-graph,
    'organization': $icon-organization,
    'settings': $icon-mixing,
    'settingsV2': $icon-wrench,
    'colors': $icon-paintBucket,
    'vehicleColors': $icon-color-fill,
    'brands': $icon-brand,
    'vehicleBrands': $icon-atv,
    'accountManagement': $icon-backpack,
    'accountV2': $icon-briefcase,
    'contract': $icon-user,
    'contractV2': $icon-businessman,
    'companies': $icon-companies,
    'sub-companies': $icon-subcompanies,
    'groups': $icon-usermanager,
    'shuttles': $icon-maintenance,
    'sites': $icon-marker,
    'pricing': $icon-price,
    'fleet': $icon-networks,
    'vehicle': $icon-vehicle,
    'fines': $icon-fines,
    'usages': $icon-maintenance,
    'feedbacks': $icon-document,
    'vehicleDamages': $icon-damage,
    'membersManagement': $icon-usermanagement,
    'members': $icon-usermanager,
    'backUsers': $icon-backusermanager,
    'booking': $icon-booking,
    'bookingV2': $icon-calendar,
    'invoice': $icon-euro,
    'invoiceV2': $icon-invoice,
    'voucher': $icon-voucher,
    'voucherV2': $icon-coupon,
    'vehiclePlanning': $icon-vehiclePlanning,
    'vehiclePlanningV2': $icon-date-check,
    'smartcard': $icon-smartcard,
    'smartcardsEvents': $icon-smartcardEvents,
    'hotlines': $icon-headset,
    'configurations': $icon-cog,
    'categories': $icon-categories,
    'categoriesV2': $icon-blocks,
    'memberTypes': $icon-memberTypes,
    'reports': $icon-reports,
    'vehicleStatuses': $icon-vehicle-statuses,
    'scheduledExport': $icon-scheduled-export,
    'quickSight': $icon-chart-curve,
    'bankout': $icon-bankouts
  )
);

@media (min-width: 1200px) {
  .new-ui {
    .sideMenu_sections {
      background: #f5f5f5;
    }
  }
}

@media (max-width: 1200px) {
  .new-ui {
    .sideMenu_section._is_open {
      background: white;
    }
  }
}

.new-ui {
  .sideMenu_mobileTop {
    margin-bottom: 20px;
  }

  .sideMenu_sections {
    box-shadow: none;
    padding-bottom: 15px;

    &--subnav {
      width: 100%;
    }

    .sideMenu_section {
      display: flex;
      flex-wrap: wrap;
      position: static;
      line-height: normal;
      align-items: center;
      margin: 15px 0 0 20px;

      // Icon adjustments
      &--membersManagement,
      &--backUsers,
      &--members {
        &:before {
          font-size: 13px;
        }
      }

      .sideMenu_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 15px;
        flex-basis: 0;
        flex-grow: 1;

        &:after {
          display: inline-flex;
          position: static;
          margin: 0 15px 0 10px;
        }

        &--subnav:after {
          background: white;
          width: 25px;
          height: 25px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }

      &:before {
        border-radius: 4px;
        flex-basis: 30px;
        height: 30px;
        width: auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: white;
        position: static;
      }
    }
  }
}

.sideMenu {
  min-width: $side-menu-width;
  background-color: #fff;
  vertical-align: top;
  transition: 300ms;
  z-index: 58;
  font-size: 14px;
  height: 60px;

  &_mobileMenuCloseOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $color-text-clear-dark;
    display: none;
  }

  &_mobileTop {
    color: #4a4a4a;
    height: 60px;
    padding: 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;

    svg {
      fill: currentColor !important;
      color: inherit !important;
    }
  }

  &._is_close {
    width: 0;
    min-width: 0;
    overflow: hidden;

    .sideMenu_sections {
      display: none;
      transition: 200ms;
    }
  }

  &_logo {
    max-width: 95px;

    path {
      fill: $color-secondary-light;
    }
  }

  &_sections {
    z-index: 10;
    height: 100vh;
    position: fixed;
    background: white;
    width: $side-menu-width;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.15);
    overflow-x: scroll;
    transition: 70ms;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_section {
    position: relative;
    cursor: pointer;
    line-height: 18px;

    &--companies:before,
    &--vehiclePlanning:before {
      font-size: 22px !important;
      top: calc((100% - 22px) / 2) !important;
    }

    &:before {
      @include remIt(font-size, 16);
      @include transition(color 0.2s ease-in);
      display: inline-block;
      font-family: $icons-font;
      color: $color-primary-middle;
      font-weight: 400;
      position: absolute;
      top: calc((100% - 18px) / 2);
      left: 20px;
      width: 30px;
      text-align: center;
    }

    &._is_active:before,
    &._is_active:after,
    &:hover:before,
    &:hover button,
    &:hover button:after,
    &:hover > a {
      color: $color-secondary-light;
    }

    @each $item, $icon in map-get($sideMenu, sideMenu-items) {
      &--#{$item}:before {
        content: $icon;
      }

      &--vehicleColors::before {
        top: 7px;
      }
    }

    &._is_open {
      background-color: $color-white-alt;

      .sideMenu_item:after {
        @include transition(0.2s ease-in);
        @include transform(rotate(0deg));
      }

      .sideMenu_sections--subnav {
        display: block;
      }

      &:before {
        top: 15px;
      }
    }
  }

  &_item {
    @include basicClickable;
    @include transition(color 0.2s ease-in);
    display: block;
    width: 100%;
    text-decoration: none;
    color: $color-menu-text;
    text-align: left;
    padding: 15px 18px 15px 70px;

    &:focus {
      outline: none;
    }

    &--subnav:after {
      @include remIt(font-size, 6);
      @include transform(rotate(-90deg));
      @include transition(0.2s ease-in);

      content: url('/img/triangle.svg');
      position: absolute;
      left: 205px;
      top: 16px;
    }

    &:hover,
    &:hover:before,
    &:hover:after,
    .sideMenu_section._is_active > &,
    .sideMenu_section._is_active > &:after {
      color: $color-secondary-light;
    }
  }

  &_sections--subnav {
    display: none;

    li {
      &:before {
        left: 30px;
      }

      &:hover a {
        color: $color-secondary-light;
      }
    }

    .sideMenu_section:last-child {
      padding-bottom: 0;
    }

    .sideMenu_item {
      padding: 15px 18px 15px 75px;
    }

    .sideMenu_item:after {
      content: '';
    }
  }
}

.marginLeft12 {
  margin-left: 12px;
}
