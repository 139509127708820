.transversalCategoryPricingForm {
  @include clearfix;

  .no-float {
    float: none !important;
  }
}

.transversalCategoryPricingForm_InputBlock {
  @include clearfix;
}

.transversalCategoryPricingForm_InputBlock_item {
  float: left;
  margin-right: 20px;
}

.transversalCategoryPricingForm_InputBlock_unit,
.transversalCategoryPricingForm_InputBlock_label {
  @include remIt(font-size, 11);
  font-weight: bold;
  text-transform: uppercase;
}

.transversalCategoryPricingForm_InputBlock_unit {
  margin-left: 5px;
  margin-top: 10px;
  float: right;
}

.transversalCategoryPricingForm_submitButton {
  min-width: 218px;
  margin-top: 20px;
  float: right;
}

.transversalCategoryPricingForm_InputBlock_inputWrapper {
  float: left;
}

.transversalCategoryPricingForm_InputBlock_input {
  transition: all 0.3s ease;

  &._has_error {
    border: solid 1px $color-error;
  }
}
