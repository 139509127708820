.app-error {
  .sc-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 300px;
    }
  }

  .sc-title {
    font-size: 2em;
    margin-top: 20px;
  }
}

.app-loader,
.app-error {
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.app-loader span {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 150px;
  height: 150px;
  border: 12px solid;
  border-radius: 50%;
  animation: app-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #777 transparent transparent;
}

.app-loader span:nth-child(1) {
  animation-delay: -0.45s;
}

.app-loader span:nth-child(2) {
  animation-delay: -0.3s;
}

.app-loader span:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes app-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
