._v2._booking-detail {
  .bookingViewV2_body {
    padding: 10px;
  }
}

.bookingViewV2 {
  background-color: transparent !important;
  height: 100%;

  &_body {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > *:empty {
      background: transparent;
    }

    > *:not(:empty) {
      background-color: white;
      border-radius: 4px;
      padding: 0 15px;
      flex-basis: 400px;
      min-width: calc(50% - 5px);
      flex-grow: 1;
    }
  }
}

.driving-license-detail {
  .detailView_table {
    margin-right: 3rem;
  }

  .image-preview-component {
    img {
      max-width: 120px;
      padding: 10px;

      &.component-img-open {
        max-width: unset;
        padding: unset;
      }
    }
  }
}
