.invoices-filters {
  display: flex;
  flex-direction: column;
  gap: 20px;

  > .sc-block {
    > .sc-title {
      margin-bottom: 15px;
    }

    > .sc-items {
      display: flex;
      flex-wrap: wrap;
      column-gap: 15px;
      row-gap: 20px;

      > * {
        flex-grow: 1;
        flex-basis: 0;
        min-width: 200px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .boxedInput--date > div > div:first-child {
    left: 12px !important;
    color: #333 !important;
  }

  .boxedSelectWrapper {
    width: 100%;
  }
}
