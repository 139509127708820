// Forms Constructor
// ==========================================================================

.formRow {
  @include flexbox();
  @include flex-direction(row);
  @include justify-content(flex-start);
  @include align-items(flex-start);
  @include flex-wrap(wrap);

  &_concern {
    @include remIt(font-size, 14);
    color: $color-primary-darkest;
    display: block;
    font-weight: 600;

    &--margin {
      margin-top: 35px;
    }
  }

  &--submitWrapper {
    text-align: center;
    margin-top: 30px;
  }

  &--halfWidth {
    // TO DO : Create variable about 25px margin-left formRow_item and put in here / 2
    max-width: calc(50% - 12.5px);
  }
}

.formRow_item {
  @include flex(1);
  margin-left: 25px;

  &:first-child {
    margin-left: 0;
  }

  &_label {
    display: block;
    overflow: hidden;

    &--noTitle {
      padding-top: 24px;
    }
  }

  &_label_double {
    @include flexbox();

    .ekInput--schedule {
      @include flex(3);
      position: relative;
      margin-right: 20px;
    }

    .ekInput:last-child {
      @include flex(1);
    }
  }
}
