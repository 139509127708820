$pending-color: #ffa000;
$active-color: #24b282;
$inactive-color: #929292;
$ended-color: #17b7c4;
$ready-color: #fe5001;
$closed-color: #000000;

$tableView: (
  tableView-state: (
    'INACTIVE': $inactive-color,
    'PENDING': $pending-color,
    'READY': $ready-color,
    'ACTIVE': $active-color,
    'ENDED': $ended-color,
    'CLOSED': $closed-color
  )
);

.vehicleDamagesPage {
  &_actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
  }

  &_tableViewWrapper {
    @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  }

  &_filters {
    margin-bottom: 15px;
  }

  &_filtersButton {
    &:after {
      vertical-align: sub;
    }
  }
}

.vehicleDamages_tableView_action {
  @include basicClickable;
  text-decoration: underline;
}

.vehicleDamagesFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: $color-white;
  margin-bottom: 15px;
  padding: 25px 10px;
}

.vehicleDamage_stateTypeCell {
  padding-left: 20px;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

@each $state, $stateColor in map-get($tableView, tableView-state) {
  .vehicleDamage_stateTypeCell--#{$state} {
    color: $stateColor;

    &:before {
      background-color: $stateColor;
    }
  }
}
