.smartcardsDetail {
  &_tabs {
    margin-top: 20px !important;
  }

  &_closeIconAbosuluteWrap {
    position: absolute;
    right: 5px;
    top: 5px;

    svg {
      fill: rgba(0, 0, 0, 0.7) !important;
      cursor: pointer;
    }
  }

  &_searchSelect {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &_content {
    padding: 25px;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &_smartcardCard_wrap {
    border-radius: 2px;
    box-shadow: 0 1px 6px $color-border-shadow2, 0 1px 4px $color-border-shadow2;
    width: 350px;
    margin-right: 25px;
  }

  &_smartcardCard {
    background: #77b3d4;
    height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_smartcardCard_bottom {
    background: white;
    padding: 10px;

    &_row {
      padding: 5px;
      display: flex;
      align-items: center;
      color: $color-text;
    }

    &_row > * {
      margin-right: 5px;
    }
  }

  &_smartcardImage {
    position: relative;
    width: 300px;
    height: 170px;
    background: #ffffff;
    border-radius: 20px;
    color: $color-text;

    &_status {
      position: absolute;
      right: 12px;
      top: 8px;
      font-weight: bold;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &_status_active {
      color: green;
      margin-left: 10px;
    }

    &_status_inactive {
      color: red;
      margin-left: 10px;
    }

    &_status svg {
      margin-left: 9px;
    }

    &_name {
      padding: 10px;
      border-bottom: 40px solid #4f5d73;
    }

    &_id {
      margin-top: 25px;
      margin-left: 8px;
      font-size: 10px;
    }

    &_id_number {
      font-size: 20px;
    }
  }

  &_user {
    position: relative;
    border-radius: 2px;
    box-shadow: 0 1px 6px $color-border-shadow2, 0 1px 4px $color-border-shadow2;
    width: 415px;
    background: white;
    display: flex;
    padding: 15px;
    height: 160px;
    align-items: center;

    &_info {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
    }

    &_info_company {
      display: flex;
      margin-left: 15px;
    }

    &_info > * {
      margin-bottom: 5px;
    }

    &_actions {
      position: absolute;
      right: 5px;
      top: 5px;
      display: flex;
    }

    &_add_wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_add {
      margin-left: 10px;
    }
  }

  &_link {
    margin-left: 10px;
    cursor: pointer;
  }
}
