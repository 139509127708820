.membersFiltersFormWrapper {
  @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
  background-color: $color-white;
  margin-bottom: 15px;
  padding: 25px 10px;
}

.searchMembersForm {
  &_inputWrapper {
    width: 200px;
    display: inline-block;
    height: 30px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-right: 15px;

    .boxedInput_label:before {
      left: inherit;
      right: 15px;
    }
  }

  &_boxedSelectBlock {
    display: inline-block;
    margin-right: 14px;

    select {
      width: 200px;
    }
  }

  &_actions {
    text-align: right;
    padding-right: 30px;
    margin-top: 15px;
  }

  &_actionsButton {
    vertical-align: middle;
    min-width: 218px;
    margin-left: 20px;
  }
}
