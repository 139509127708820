.companyDetailPage,
.subCompanyDetailPage {
  .formRow:last-child {
    .formRow_item {
      margin-bottom: 5px;
    }
  }

  .fieldErrorMsg--bankDetails {
    font-size: 0.9em;
  }

  .paybackSettings {
    padding-bottom: 1px; // fix no extra bottom margin

    .boxedSelectWrapper {
      display: block;
    }

    .payment-status {
      @include remIt(margin-left, 10);
      @include remIt(font-size, 15);
      display: flex;
      align-items: center;
      color: $color-text-dark;

      .payment-icon {
        @include remIt(margin-right, 10);

        .circle-outline {
          @include addIcon($icon-checkCircleOutline, $size: 23px, $parentSize: 24px);
          &.completed::before {
            color: $color-good !important;
          }
        }
        .info {
          @include addIcon($icon-highlight-off, $size: 23px, $parentSize: 24px);
          &.disabled::before {
            font-size: 30px;
            color: $color-grey-light !important;
          }
        }
        .pending {
          @include addIcon($icon-partial-radio, $size: 23px, $parentSize: 24px);
          &.incomplete::before {
            font-size: 30px;
            color: $color-warn !important;
          }
        }
      }

      .ready-for-upload {
        color: $color-good;
      }
    }

    .submit-form {
      margin-top: 1em;
      margin-bottom: 2em;
      text-align: end;

      button {
        min-width: 200px;
      }
    }

    .bank-details-form {
      &-fields {
        margin-top: 2em;
      }

      .submit-container {
        margin-top: 2em;

        .button {
          min-width: 150px;
          &.pending {
            button {
              background-color: $color-warn !important;
            }
          }
          &.completed {
            button {
              color: $color-main !important;
              border: 2px dashed black !important;
            }
          }
        }
      }
    }

    section.stripe-migrate-block {
      .panel-handle {
        button.btn.flat-button {
          height: 50px !important;
        }
        > button.btn.flat-button div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > span:nth-child(2),
          > span {
            width: 90%;
            display: block;
          }
          > svg {
            width: 10%;
            display: block;
          }
        }
      }

      .panel-content {
        background-color: white;
        padding: 30px;
      }
    }
  }
}

.migrate-payment-action {
  color: black !important;
  position: relative;
  transition: padding-right 0.3s ease-out !important;
  padding: auto 16px !important;
  margin-left: 15px;

  &.pending {
    background-color: $color-main !important;
    padding-right: 40px !important;
    &::after {
      content: '';
      position: absolute;
      border-radius: 100%;
      right: 6px;
      top: 50%;
      width: 0px;
      height: 0px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      border-left-color: #fff;
      border-top-color: #fff;
      animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
    }
    @keyframes spin {
      to {
        transform: rotate(359deg);
      }
    }
    @keyframes grow {
      to {
        width: 14px;
        height: 14px;
        margin-top: -8px;
        right: 13px;
      }
    }
  }
  &.ismigrated {
    background-color: $color-main !important;
    padding-right: 40px !important;

    &::after {
      content: '✔';
      position: absolute;
      color: white;
      right: 15px;
      top: 50%;
      @include transform(translateY(-50%));
      width: 15px;
      height: auto;
    }
  }

  &.error {
    background-color: $color-warn !important;
    border: 2px $color-warn solid;
    padding-right: 40px !important;

    button {
      background-color: $color-warn !important;
    }
    &::after {
      content: '✘';
      position: absolute;
      color: white;
      background-color: $color-warn !important;
      @include transform(translateY(-50%));
      right: 15px;
      top: 50%;
      width: 15px;
      height: auto;
    }
  }
}
