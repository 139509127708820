.paginationControls {
  @include remIt(font-size, 12);
  @include remIt(line-height, 15);
  margin-left: 30px;
  color: $color-text-clear-dark;
}

.paginationControls_button {
  display: inline-block;
  position: relative;
  width: 26px;
  height: 30px;
  border-radius: 1px;
  border: 0;
  vertical-align: middle;
  margin-left: 8px;
  text-indent: -9000px;
  overflow: hidden;
  background: #f5f5f5;

  &:first-child {
    margin-left: 0;
  }

  &:before {
    @include remIt(font-size, 14);
    @include transform(translateY(-50%));
    text-indent: 0;
    content: $icon-chevron;
    font-family: $icons-font;
    color: rgba(0, 0, 0, 0.26);
    position: absolute;
    top: 50%;
  }

  &--last:before {
    @include remIt(font-size, 14);
    @include transform(translateY(-50%));
    text-indent: 0;
    content: $icon-double-chevron;
    font-family: $icons-font;
    color: rgba(0, 0, 0, 0.26);
    position: absolute;
    top: 50%;
  }

  &--first:before {
    left: 6px;
  }

  &--prev:before {
    left: 8px;
  }

  &--next:before {
    left: 9px;
  }

  &--last:before {
    left: 7px;
  }
}

.paginationControls_button.paginationControls_button--prev {
  &:before {
    @include transform(rotate(180deg) translateY(50%));
  }
}

.paginationControls_button.paginationControls_button--first {
  &:before {
    @include transform(rotate(180deg) translateY(50%));
    content: $icon-double-chevron;
  }
}
