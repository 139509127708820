.backUserRoleForm {
  &_container {
    @include box-shadow(4px 5px 6px rgba($color-primary3-alt2, 0.5));
    background-color: $color-white;
    padding: 30px;
    margin-top: 30px;
  }

  &_row {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_rowItem {
    &:nth-child(2) {
      max-width: 60px;
      align-self: center;
    }
  }

  &_boxedSelectMultipleWrapper {
    min-width: 232px;
    height: 160px;
    margin-right: 10px;
  }

  &_boxedSelect {
    height: 100% !important;
    width: 100%;
  }

  &_actionsAdd {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;

    > * {
      margin-left: 10px;
    }
  }

  &_addButton {
    display: block;
    min-width: 218px;
    margin-bottom: 10px;
  }

  &_assignButton {
    @include basicClickable();
    @include transform(translateX(-50%));
    display: block;
    font-size: 0;
    position: relative;
    left: 50%;
    padding: 5px 0;

    &:before {
      @include remIt(font-size, 23);
      content: $icon-chevron;
      font-family: $icons-font;
      display: inline-block;
    }

    &--left:before {
      @include box-rotate(180);
    }
  }

  &_actions {
    text-align: right;
  }

  &_actionsButton {
    min-width: 218px;
  }
}

.fieldErrorMsg--backUserForm {
  margin-top: 10px;
}
