// EkButton
// ==========================================================================

$button: (
  button-base-color: $color-secondary-light,
  button-variant1-color: $color-primary-darkest,
  button-variant2-color: $color-tertiary,
  button-variant3-color: $color-secondary,
  button-variant4-color: $color-good-tertiary
);

// Constructor
// ==========================================================================

.ekButton[class*='_filtersButton'] {
  @include toggle($color-white);
  min-width: 80px;
}

.ekButton {
  @include buttonColor(map_get($button, button-base-color), $color-white);
  @include remIt(font-size, 14);
  @include remIt(line-height, 14);
  @include transition(all 0.2s ease-in);
  position: relative;
  padding: 12px 18px;
  text-align: center;
  text-decoration: none;
  height: 41px;
  min-width: 88px !important;
  border-radius: 3px;
  box-shadow: $color-border-shadow2 0 1px 6px, $color-border-shadow2 0 1px 4px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  &--fullWidth {
    display: block;
    width: 100%;
  }

  &--download:before {
    @include remIt(font-size, 16);
    content: $icon-download;
    font-family: $icons-font;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
  }

  &--reverse {
    @include buttonColor(map_get($button, button-base-color), $color-white, reverse);
    box-shadow: none;
  }

  &--variant1 {
    @include buttonColor(map_get($button, button-variant1-color), $color-white);
  }

  &--variant1Reverse {
    @include buttonColor(map_get($button, button-variant1-color), $color-white, reverse);
  }

  &--variant2 {
    @include buttonColor(map_get($button, button-variant2-color), $color-white);
  }

  &--variant2Reverse {
    @include buttonColor(map_get($button, button-variant2-color), $color-white, reverse);

    &.ekButtonToggle:after {
      border-color: map_get($button, button-variant2-color) transparent transparent;
    }

    &.ekButtonToggle--opened:after {
      border-color: transparent transparent map_get($button, button-variant2-color);
    }
  }

  &--variant3 {
    @include buttonColor(map_get($button, button-variant3-color), $color-white);
  }

  &--variant4 {
    @include buttonColor(map_get($button, button-variant4-color), $color-white);
  }

  &--medium {
    width: 220px;
    margin: 0 auto;
  }

  &.disabled {
    background-color: #e2e2e2;
    color: #b4b4b4;
    border: 1px solid #e2e2e2;
    pointer-events: none;
    cursor: default;
  }
}

.ekButton[disabled],
.ekButton:disabled {
  cursor: not-allowed;
  background-color: #e3e3e3;
  color: #6f6f6f;
  border: 1px solid #e2e2e2;
}

.ekButtonToggle {
  &:after {
    @include cssTriangle(5px, map_get($button, button-base-color), top);
    position: absolute;
    right: 15px;
    top: 50%;
  }

  &--opened:after {
    @include cssTriangle(5px, map_get($button, button-base-color), bottom);
  }
}
