.list-details {
  @include listWrapProps;

  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  min-width: 310px;
  flex-grow: 1;

  > * {
    padding: 0 15px;
  }

  .sc-main-wrap {
    overflow: auto;
  }

  > .sc-title-wrap {
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    display: flex;

    .sc-title {
      font-size: larger;
      font-weight: 500;
      margin-right: 5%;
    }
  }
}
