.companyDetailPage {
  .companyForm {
    padding-bottom: 30px;

    .companyForm_actions {
      margin-bottom: 0;
    }

    .companyForm_actionsButton {
      min-width: 200px !important;
    }

    .formRow_item:last-child {
      margin-bottom: 25px;
    }

    .companyFormWrapper:first-child {
      .companyForm_container {
        margin-top: 0;
      }
    }
  }

  &_content {
    font-family: $main-font;
    padding: 10px 25px 0;
  }

  &_title {
    @include pageTitle();
    font-weight: bold;
    display: inline-block;
  }

  &_actionButton {
    float: right;
    width: 200px;
    margin-top: 20px;
  }

  .customFieldTableTitle {
    @include remIt(margin-bottom, 15);
    @include remIt(margin-top, 15);
  }

  &_sendDrivingAuthorisation {
    display: flex;

    img {
      width: 150px;
      height: 150px;
      margin-right: 15px;
      object-fit: cover;
    }
  }
}

.companyDetail {
  @include box-shadow(4px 5px 6px rgba($color-primary3, 0.5));
  background-color: $color-white;
  padding: 30px;
  margin-bottom: 30px;

  &--migrateStripeBlock {
    margin-bottom: 30px;
    padding: 0;
    background-color: $color-white;
  }

  &_tabs {
    margin-top: 20px;
  }

  &_tabItem {
    display: inline-flex;
    padding: 0 20px;
    cursor: pointer;

    &.__is_selected {
      background-color: $color-white;
    }

    .warn {
      display: inline-flex;
      @include addIcon($icon-partial-radio, $size: 23px, $parentSize: 24px);
      &::before {
        color: $color-warn !important;
      }
    }

    span {
      line-height: 50px;
    }
  }

  &_title {
    @include remIt(font-size, 13);
    text-transform: uppercase;
    font-weight: 600;
    color: $color-black;
    margin-top: 0;
  }

  &_line {
    width: 100%;
    font-size: 0;
    margin: 30px 0;
  }

  &_item {
    @include remIt(font-size, 13);
    display: inline-block;
    width: 100%;
    vertical-align: middle;

    &_label {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
    }

    &_result {
      margin-top: 15px;
      display: block;
      font-weight: 100;
    }

    &_result a {
      width: 92%;
      overflow: hidden;
      display: block;
    }
  }

  &_line--double &_item {
    width: 50%;
  }

  &_line--quad &_item {
    width: 45%;

    &:nth-child(2) {
      width: 10%;
    }

    &:last-child {
      width: 100%;
      text-align: right;
    }
  }

  &_boxedSelectMultipleWrapper {
    min-width: 232px;
    height: 160px;
    margin-right: 10px;
  }

  &_boxedSelect {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  &_assignButton {
    @include basicClickable();
    @include transform(translateX(-50%));
    display: block;
    font-size: 0;
    position: relative;
    left: 50%;
    padding: 5px 0;

    &:disabled {
      cursor: not-allowed;
    }

    &:before {
      @include remIt(font-size, 23);
      content: $icon-chevron;
      font-family: $icons-font;
      display: inline-block;
    }

    &--left:before {
      @include box-rotate(180);
    }
  }

  .companyForm_actionsButton {
    margin-top: 50px;
  }

  .driving-authorisations-settings {
    padding: 0 20px;

    .openPdf {
      cursor: pointer;
    }

    .detailLine .detailItem img {
      width: 200px;
      max-height: 80px;
    }
  }
}
