.list-filters-modal {
  > .sc-title-wrap {
    padding: 15px 20px;
  }
}

.list-filters {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;

  &.sc-read-only {
    > .sc-main-wrap {
      padding: 5px 20px 30px;
    }
  }

  &.vehicles-filters {
    > .sc-actions {
      margin: auto 0 20px;
    }
  }

  > .sc-main-wrap {
    overflow: auto;
    padding: 5px 20px;
    flex-grow: 1;
  }

  > .sc-actions {
    display: flex;
    justify-content: flex-end;
    margin: 30px;

    > *:first-child:not(:last-child) {
      margin-right: 15px !important;
    }

    .sc-flat {
      color: $color-list-active-item !important;
    }

    .sc-raised button {
      background-color: $color-list-active-item !important;
      color: white !important;
    }
  }
}
